// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 课程类型删除 POST /school/safe_course_category/disableSafeCourseCategory */
export async function postSchoolSafeCourseCategoryDisableSafeCourseCategory(
  body: number[],
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/safe_course_category/disableSafeCourseCategory', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 课程类型列表 GET /school/safe_course_category/getList */
export async function getSchoolSafeCourseCategoryGetList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolSafeCourseCategoryGetListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSafeCourseCategoryC2BB>('/school/safe_course_category/getList', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 课程类型新增/修改 POST /school/safe_course_category/saveSafeCourseCategory */
export async function postSchoolSafeCourseCategorySaveSafeCourseCategory(
  body: API.SafeCourseCategory,
  options?: { [key: string]: any },
) {
  return request<number>('/school/safe_course_category/saveSafeCourseCategory', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 章节删除 POST /school/safe_course_node/deleteByIds */
export async function postSchoolSafeCourseNodeDeleteByIds(
  body: number[],
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/safe_course_node/deleteByIds', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 章节列表 GET /school/safe_course_node/getListByCourseIds */
export async function getSchoolSafeCourseNodeGetListByCourseIds(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolSafeCourseNodeGetListByCourseIdsParams,
  options?: { [key: string]: any },
) {
  return request<API.SafeCourseNodeVo[]>('/school/safe_course_node/getListByCourseIds', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 章节新增/修改 POST /school/safe_course_node/saveSafeCourseNode */
export async function postSchoolSafeCourseNodeSaveSafeCourseNode(
  body: API.SafeCourseNodeRequest,
  options?: { [key: string]: any },
) {
  return request<API.SafeCourseNode[]>('/school/safe_course_node/saveSafeCourseNode', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 章节修改 POST /school/safe_course_node/updateSafeCourseNode */
export async function postSchoolSafeCourseNodeUpdateSafeCourseNode(
  body: API.SafeCourseNode,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/school/safe_course_node/updateSafeCourseNode', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 发布记录删除 POST /school/safe_course_record/deleteByIds */
export async function postSchoolSafeCourseRecordDeleteByIds(
  body: number[],
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/safe_course_record/deleteByIds', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 发布记录分页列表 GET /school/safe_course_record/getList */
export async function getSchoolSafeCourseRecordGetList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolSafeCourseRecordGetListParams,
  body: string,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSafeCourseRecordVoC2BB>('/school/safe_course_record/getList', {
    method: 'GET',
    headers: {
      'Content-Type': 'text/plain',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 学习统计-导出 GET /school/safe_course_student/export */
export async function getSchoolSafeCourseStudentOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolSafeCourseStudent_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/school/safe_course_student/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取可导出列 GET /school/safe_course_student/export/columns */
export async function getSchoolSafeCourseStudentOpenApiExportColumns(options?: {
  [key: string]: any;
}) {
  return request<API.Vo[]>('/school/safe_course_student/export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 学习统计-学校课程视频观看时长列表 GET /school/safe_course_student/getSafeCourseSchoolList */
export async function getSchoolSafeCourseStudentGetSafeCourseSchoolList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolSafeCourseStudentGetSafeCourseSchoolListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSafeCourseSchoolVoC2BB>(
    '/school/safe_course_student/getSafeCourseSchoolList',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 学习统计-新增学生观看时长 POST /school/safe_course_student/saveSafeCourseStudent */
export async function postSchoolSafeCourseStudentSaveSafeCourseStudent(
  body: API.SafeCourseStudent,
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/safe_course_student/saveSafeCourseStudent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 课程新增 POST /school/safe_course/addCourse */
export async function postSchoolSafeCourseAddCourse(
  body: API.SafeCourseRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/school/safe_course/addCourse', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 课程删除 POST /school/safe_course/deleteSafeCourseByIds */
export async function postSchoolSafeCourseDeleteSafeCourseByIds(
  body: number[],
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/safe_course/deleteSafeCourseByIds', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 课程数统计 GET /school/safe_course/getCourseTypeStatistics */
export async function getSchoolSafeCourseGetCourseTypeStatistics(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolSafeCourseGetCourseTypeStatisticsParams,
  options?: { [key: string]: any },
) {
  return request<API.PercentVo[]>('/school/safe_course/getCourseTypeStatistics', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 课程观看次数统计 GET /school/safe_course/getCourseViewerStatistics */
export async function getSchoolSafeCourseGetCourseViewerStatistics(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolSafeCourseGetCourseViewerStatisticsParams,
  options?: { [key: string]: any },
) {
  return request<API.SafeCourseStudentStatisticsVo[]>(
    '/school/safe_course/getCourseViewerStatistics',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 课程列表 GET /school/safe_course/getList */
export async function getSchoolSafeCourseGetList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolSafeCourseGetListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSafeCourseVoC2BB>('/school/safe_course/getList', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 课程详情 GET /school/safe_course/getSafeCourseDetail */
export async function getSchoolSafeCourseGetSafeCourseDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolSafeCourseGetSafeCourseDetailParams,
  options?: { [key: string]: any },
) {
  return request<API.SafeCourseVo>('/school/safe_course/getSafeCourseDetail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 课程筛选条件下拉值 GET /school/safe_course/getSafeCourseSelectData */
export async function getSchoolSafeCourseGetSafeCourseSelectData(options?: { [key: string]: any }) {
  return request<API.SafeCourseSelectDataVo>('/school/safe_course/getSafeCourseSelectData', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 课程播放量统计 GET /school/safe_course/playCount */
export async function getSchoolSafeCoursePlayCount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolSafeCoursePlayCountParams,
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/safe_course/playCount', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 课程编辑 POST /school/safe_course/updateCourse */
export async function postSchoolSafeCourseUpdateCourse(
  body: API.SafeCourseUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/safe_course/updateCourse', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 课程状态更新 POST /school/safe_course/updateSafeCourseStatusByIds */
export async function postSchoolSafeCourseUpdateSafeCourseStatusByIds(
  body: API.SafeCourseUpdateStatusRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/safe_course/updateSafeCourseStatusByIds', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
