// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 列表 GET /system/menu/list */
export async function getSystemMenuList(options?: { [key: string]: any }) {
  return request<API.SystemPolicy[]>('/system/menu/list', {
    method: 'GET',
    ...(options || {}),
  });
}
