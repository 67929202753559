// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 流程列表 GET /engine-rest/process-definition */
export async function getEngineRestProcessDefinition(
  body: {
    variables: { amount: { value?: number; type?: string }; item: { value?: string } };
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/engine-rest/process-definition', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 开始流程 POST /engine-rest/process-definition/key/${param0}/start */
export async function postEngineRestProcessDefinitionKeyKeyStart(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postEngineRestProcessDefinitionKeyKeyStartParams,
  body: {
    variables: { amount: { value?: number; type?: string }; item: { value?: string } };
  },
  options?: { [key: string]: any },
) {
  const { key: param0, ...queryParams } = params;
  return request<any>(`/engine-rest/process-definition/key/${param0}/start`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 待办任务 POST /engine-rest/task */
export async function postEngineRestTask(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postEngineRestTaskParams,
  body: {},
  options?: { [key: string]: any },
) {
  return request<
    {
      id?: string;
      name?: string;
      assignee?: any;
      created?: string;
      due?: any;
      followUp?: any;
      delegationState?: any;
      description?: any;
      executionId?: string;
      owner?: any;
      parentTaskId?: any;
      priority?: number;
      processDefinitionId?: string;
      processInstanceId?: string;
      taskDefinitionKey?: string;
      caseExecutionId?: any;
      caseInstanceId?: any;
      caseDefinitionId?: any;
      suspended?: boolean;
      formKey?: any;
      tenantId?: any;
    }[]
  >('/engine-rest/task', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 完成流程 POST /engine-rest/task/${param0}/complete */
export async function postEngineRestTaskIdComplete(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postEngineRestTaskIdCompleteParams,
  body: {
    variables: { amount: { value?: number; type?: string }; item: { value?: string } };
  },
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<any>(`/engine-rest/task/${param0}/complete`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}
