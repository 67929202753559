import { isPromise } from '@hudiemon/utils';
import { history } from '@umijs/max';
import { isEmpty, isString } from 'lodash-es';
import { RequestType } from './index';

const createTask = async (request?: RequestType) => {
  const promise = request?.();
  if (isPromise(promise)) {
    const data: any = await promise;
    if (isEmpty(data?.download_url)) {
      history.push('/download');
    }
    let download_url;
    if (!isEmpty(data?.download_url) && isString(data?.download_url)) {
      download_url = data?.download_url;
    } else {
      const downloads = Object.values(data?.download_url ?? []);
      download_url = downloads[0];
    }
    if (!isString(download_url)) return;
    const dom = document.createElement('a');
    dom.href = download_url;
    dom.download = download_url;
    dom.target = '_blank';
    dom.style.display = 'none';
    document.body.appendChild(dom);
    dom.click();
    dom.parentNode?.removeChild(dom);
  }
};
export default createTask;
