// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加 POST /official_document/archive/dir/add */
export async function postOfficialDocumentArchiveDirAdd(
  body: API.OfficialDocumentArchiveDirRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentArchiveDir>('/official_document/archive/dir/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /official_document/archive/dir/delete */
export async function postOfficialDocumentArchiveDirOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/official_document/archive/dir/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /official_document/archive/dir/edit */
export async function postOfficialDocumentArchiveDirEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postOfficialDocumentArchiveDirEditParams,
  body: API.OfficialDocumentArchiveDirRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentArchiveDir>('/official_document/archive/dir/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 导出 POST /official_document/archive/dir/export */
export async function postOfficialDocumentArchiveDirOpenApiExport(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/archive/dir/export', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /official_document/archive/dir/list */
export async function getOfficialDocumentArchiveDirList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentArchiveDirListParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentArchiveDir[]>('/official_document/archive/dir/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
