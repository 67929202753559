// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 创建下载任务 POST /admin/official_document/export/create_task */
export async function postAdminOfficialDocumentOpenApiExportCreateTask(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<API.DownloadTaskResultVo>('/admin/official_document/export/create_task', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 下载pdf与附件地址 GET /admin/official_document/export/download_url */
export async function getAdminOfficialDocumentOpenApiExportDownloadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminOfficialDocument_openAPI_exportDownloadUrlParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentResource[]>('/admin/official_document/export/download_url', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 创建下载任务 POST /official_document/export/create_task */
export async function postOfficialDocumentOpenApiExportCreateTask(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<API.DownloadTaskResultVo>('/official_document/export/create_task', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 下载pdf与附件 GET /official_document/export/download */
export async function getOfficialDocumentOpenApiExportDownload(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocument_openAPI_exportDownloadParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/official_document/export/download', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 下载pdf与附件地址 GET /official_document/export/download_url */
export async function getOfficialDocumentOpenApiExportDownloadUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocument_openAPI_exportDownloadUrlParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentResource[]>('/official_document/export/download_url', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取可导出列 GET /official_document/export/export/columns */
export async function getOfficialDocumentOpenApiExportOpenApiExportColumns(options?: {
  [key: string]: any;
}) {
  return request<API.Vo[]>('/official_document/export/export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取可导出列[全部] GET /official_document/export/export/columns/all */
export async function getOfficialDocumentOpenApiExportOpenApiExportColumnsAll(options?: {
  [key: string]: any;
}) {
  return request<API.Vo[]>('/official_document/export/export/columns/all', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取可导出列[我的发文] GET /official_document/export/export/columns/relase */
export async function getOfficialDocumentOpenApiExportOpenApiExportColumnsRelase(options?: {
  [key: string]: any;
}) {
  return request<API.Vo[]>('/official_document/export/export/columns/relase', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取可导出列[待办] GET /official_document/export/export/columns/wait_handle */
export async function getOfficialDocumentOpenApiExportOpenApiExportColumnsWaitHandle(options?: {
  [key: string]: any;
}) {
  return request<API.Vo[]>('/official_document/export/export/columns/wait_handle', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 导出pdf GET /official_document/export/pdf */
export async function getOfficialDocumentOpenApiExportPdf(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocument_openAPI_exportPdfParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/official_document/export/pdf', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 预览pdf流 POST /official_document/export/pre_pdf */
export async function postOfficialDocumentOpenApiExportPrePdf(
  body: API.OfficialDocumentPreRequest,
  options?: { [key: string]: any },
) {
  return request<number[]>('/official_document/export/pre_pdf', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取公文资源 GET /official_document/export/resource */
export async function getOfficialDocumentOpenApiExportResource(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocument_openAPI_exportResourceParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentResourceVo>('/official_document/export/resource', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 测试pdf转图片 POST /official_document/export/test_image */
export async function postOfficialDocumentOpenApiExportTestImage(
  body: {},
  file?: File,
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<API.OfficialDocumentExportImageVo>('/official_document/export/test_image', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 测试html转pdf GET /official_document/export/test_pdf */
export async function getOfficialDocumentOpenApiExportTestPdf(
  body: {},
  file?: File,
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<number[]>('/official_document/export/test_pdf', {
    method: 'GET',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 测试html转pdf POST /official_document/export/test_pdf */
export async function postOfficialDocumentOpenApiExportTestPdf(
  body: {},
  file?: File,
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<number[]>('/official_document/export/test_pdf', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}
