// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加部门成员 POST /admin/department_member/add */
export async function postAdminDepartmentMemberAdd(
  body: API.AdminDepartmentMemberRequest,
  options?: { [key: string]: any },
) {
  return request<API.DepartmentMember>('/admin/department_member/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量修改部门 POST /admin/department_member/batch_move */
export async function postAdminDepartmentMemberBatchMove(
  body: API.AdminDepartmentMemberMoveRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/admin/department_member/batch_move', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除部门成员 POST /admin/department_member/delete */
export async function postAdminDepartmentMemberOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/admin/department_member/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 离职 POST /admin/department_member/depart */
export async function postAdminDepartmentMemberDepart(
  body: API.AdminDepartmentMemberDepartRequest,
  options?: { [key: string]: any },
) {
  return request<number[]>('/admin/department_member/depart', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 启用/禁用 POST /admin/department_member/disable */
export async function postAdminDepartmentMemberDisable(
  body: API.AdminDepartmentMemberDisableRequest,
  options?: { [key: string]: any },
) {
  return request<number[]>('/admin/department_member/disable', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改部门成员信息 POST /admin/department_member/edit/${param0} */
export async function postAdminDepartmentMemberEditId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postAdminDepartmentMemberEditIdParams,
  body: API.AdminDepartmentMemberRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.DepartmentMember>(`/admin/department_member/edit/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 部门成员详情 GET /admin/department_member/get */
export async function getAdminDepartmentMemberGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminDepartmentMemberGetParams,
  options?: { [key: string]: any },
) {
  return request<API.AdminDepartmentMemberVo>('/admin/department_member/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 成员导入 POST /admin/department_member/import */
export async function postAdminDepartmentMemberOpenApiImport(
  body: {},
  file?: File,
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<string>('/admin/department_member/import', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 模板下载 GET /admin/department_member/import_template */
export async function getAdminDepartmentMemberImportTemplate(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/admin/department_member/import_template', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 部门成员列表 GET /admin/department_member/list */
export async function getAdminDepartmentMemberList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminDepartmentMemberListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABAdminDepartmentMemberVoC2BB>('/admin/department_member/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 指定部门成员列表 不含下级 GET /admin/department_member/list/by_department */
export async function getAdminDepartmentMemberListByDepartment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminDepartmentMemberListByDepartmentParams,
  options?: { [key: string]: any },
) {
  return request<API.AdminDepartmentMemberVo[]>('/admin/department_member/list/by_department', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 重设密码 POST /admin/department_member/reset_password */
export async function postAdminDepartmentMemberResetPassword(
  body: API.AdminDepartmentMemberResetPasswordRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/admin/department_member/reset_password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改部门成员信息 POST /department_member/${param0} */
export async function postDepartmentMemberId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postDepartmentMemberIdParams,
  body: API.DepartmentMemberRequestEditGroupDefault,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.DepartmentMemberEditGroupDefault>(`/department_member/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 添加部门成员 POST /department_member/add */
export async function postDepartmentMemberAdd(
  body: API.DepartmentMemberRequestAddGroupDefault,
  options?: { [key: string]: any },
) {
  return request<API.DepartmentMemberAddGroupDefault>('/department_member/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量修改部门 POST /department_member/batch_move */
export async function postDepartmentMemberBatchMove(
  body: API.DepartmentMemberMoveRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/department_member/batch_move', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除部门成员 POST /department_member/delete */
export async function postDepartmentMemberOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/department_member/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 离职 POST /department_member/depart */
export async function postDepartmentMemberDepart(
  body: API.DepartmentMemberDepartRequest,
  options?: { [key: string]: any },
) {
  return request<number[]>('/department_member/depart', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 禁用 POST /department_member/disable */
export async function postDepartmentMemberDisable(
  body: API.DepartmentMemberDisableRequest,
  options?: { [key: string]: any },
) {
  return request<number[]>('/department_member/disable', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 导出 GET /department_member/export */
export async function getDepartmentMemberOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDepartmentMember_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/department_member/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取可导出列 GET /department_member/export/columns */
export async function getDepartmentMemberOpenApiExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/department_member/export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 部门成员详情 GET /department_member/get */
export async function getDepartmentMemberGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDepartmentMemberGetParams,
  options?: { [key: string]: any },
) {
  return request<API.DepartmentMemberVo>('/department_member/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 部门负责人查询 GET /department_member/get_leader/${param0} */
export async function getDepartmentMemberGetLeaderDepartmentId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDepartmentMemberGetLeaderDepartmentIdParams,
  options?: { [key: string]: any },
) {
  const { departmentId: param0, ...queryParams } = params;
  return request<API.DepartmentMemberVo[]>(`/department_member/get_leader/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 导入 POST /department_member/import */
export async function postDepartmentMemberOpenApiImport(
  body: {},
  file?: File,
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<string>('/department_member/import', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 导入公文处理人信息 POST /department_member/import_official_member */
export async function postDepartmentMemberImportOfficialMember(
  body: {},
  file?: File,
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<Record<string, any>>('/department_member/import_official_member', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 导入模板下载 consumes = "application/octet-stream" //请求类型 Content-Typeg
produces = MediaType.APPLICATION_OCTET_STREAM_VALUE  // 返回类型 accept
MIME type:
         "application/octet-stream"
         "application/vnd.ms-excel"
         "application/properties+person"
Media type:
         APPLICATION_JSON_UTF8_VALUE
         APPLICATION_OCTET_STREAM_VALUE GET /department_member/import_template */
export async function getDepartmentMemberImportTemplate(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/department_member/import_template', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 部门成员列表 GET /department_member/list */
export async function getDepartmentMemberList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDepartmentMemberListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABDepartmentMemberVoC2BB>('/department_member/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 指定部门成员列表 不含下级 GET /department_member/list/by_department */
export async function getDepartmentMemberListByDepartment(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDepartmentMemberListByDepartmentParams,
  options?: { [key: string]: any },
) {
  return request<API.DepartmentMemberVo[]>('/department_member/list/by_department', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 重设密码 POST /department_member/reset_password */
export async function postDepartmentMemberResetPassword(
  body: API.DepartmentMemberResetPasswordRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/department_member/reset_password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 设置负责人 POST /department_member/set_leader */
export async function postDepartmentMemberSetLeader(
  body: API.DepartmentMemberReaderRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/department_member/set_leader', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 设置标签 POST /department_member/set_tag */
export async function postDepartmentMemberSetTag(
  body: API.DepartmentMemberSetTagRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/department_member/set_tag', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 成员用户类型同步 POST /department_member/sync_user_auth */
export async function postDepartmentMemberSyncUserAuth(options?: { [key: string]: any }) {
  return request<boolean>('/department_member/sync_user_auth', {
    method: 'POST',
    ...(options || {}),
  });
}
