import { useInitialState } from '@/hooks';
import Api from '@/services/api';
import { getDictionary } from '@/utils';
import {
  ProForm,
  ProFormDependency,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components';
import { AliyunOSSUpload, Button, Modal, message } from '@dyrl/web';
import { Col, Tag } from 'antd';
import { useState } from 'react';

const Userinfo = () => {
  const { currentUser, fetchUserInfo, roles } = useInitialState.getState();
  const [action, setAction] = useState<string>('read');
  return (
    <Modal.ProForm
      grid
      disabled={action === 'read'}
      footer={
        action === 'read' ? (
          <>
            <Modal.CloseButton />
            <Button onClick={() => setAction('update')}>编辑</Button>
          </>
        ) : (
          <>
            <Modal.CloseButton />
            <Modal.SubmitButton />
          </>
        )
      }
      initialValues={{
        sex: `${currentUser?.sex}`,
        email: currentUser?.email,
        mobile: currentUser?.mobile,
        username: currentUser?.username,
        nickname: currentUser?.nickname,
      }}
      onFinish={async (formData) => {
        await Api.yonghuguanli.postUserUpdatePersonalInfo(formData);
        await fetchUserInfo();
        message.success('账号信息编辑成功');
        return true;
      }}
    >
      <Col span={24}>
        <div className={'flex'}>
          <ProForm.Item name={'avatar_id'}>
            <AliyunOSSUpload.Image
              defaultFileList={[
                {
                  id: currentUser?.avatarId,
                  url: currentUser?.avatarSrc,
                },
              ]}
            />
          </ProForm.Item>
          <div>
            <ProFormDependency name={['nickname']}>
              {({ nickname }) => (
                <div className={'text-[16px] font-medium'}>{nickname}</div>
              )}
            </ProFormDependency>
            {roles.map((item) => {
              return (
                <Tag key={item.id} color={'purple'}>
                  {item.name}
                </Tag>
              );
            })}
          </div>
        </div>
      </Col>
      <ProFormText
        colProps={{ span: 12 }}
        disabled
        label={'账号'}
        name={'username'}
      />
      <ProFormText
        colProps={{ span: 12 }}
        label={'姓名'}
        name={'nickname'}
        rules={[{ required: true }]}
      />
      <ProFormSelect
        label={'性别'}
        name={'sex'}
        colProps={{ span: 12 }}
        valueEnum={getDictionary('sex', 'valueEnum')}
      />
      <ProFormText
        colProps={{ span: 12 }}
        disabled
        label={'联系方式'}
        name={'mobile'}
      />
      <ProFormText colProps={{ span: 12 }} label={'邮箱'} name={'email'} />
    </Modal.ProForm>
  );
};
Userinfo.open = () => {
  Modal.open({
    title: '账号信息',
    children: <Userinfo />,
  });
};
export default Userinfo;
