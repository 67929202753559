// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 获取流程配置 GET /admin/work_process/config/get */
export async function getAdminWorkProcessConfigGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminWorkProcessConfigGetParams,
  options?: { [key: string]: any },
) {
  return request<API.AdminWorkProcessConfig>('/admin/work_process/config/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取流程列表 GET /admin/work_process/config/list */
export async function getAdminWorkProcessConfigList(options?: { [key: string]: any }) {
  return request<API.AdminWorkProcessConfig[]>('/admin/work_process/config/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 推送至学校 POST /admin/work_process/config/push */
export async function postAdminWorkProcessConfigPush(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/admin/work_process/config/push', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 修改流程配置 POST /admin/work_process/config/update */
export async function postAdminWorkProcessConfigUpdate(
  body: API.AdminWorkProcessConfigRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/admin/work_process/config/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取流程配置 GET /work_process/config/get */
export async function getWorkProcessConfigGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getWorkProcessConfigGetParams,
  options?: { [key: string]: any },
) {
  return request<API.WorkProcessConfig>('/work_process/config/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取流程列表 GET /work_process/config/list */
export async function getWorkProcessConfigList(options?: { [key: string]: any }) {
  return request<API.WorkProcessConfig[]>('/work_process/config/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 修改流程配置 POST /work_process/config/update */
export async function postWorkProcessConfigUpdate(
  body: API.WorkProcessConfigRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/work_process/config/update', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
