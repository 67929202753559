import { ProFormItemProps, ProFormText } from '@ant-design/pro-components';
import { InputProps } from 'antd';
import dayjs from 'dayjs';
import { compact, concat } from 'lodash-es';

type ProFormIDCardProps = ProFormItemProps & {
  fieldProps?: InputProps;
  onAge?: (age: number) => void;
};

const pattern =
  /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/;

const ProFormIDCard = (props: ProFormIDCardProps) => {
  const { rules: propsRules, ...restProps } = props;
  const rules = concat(compact(propsRules), [
    {
      pattern,
      message: '身份证号码格式不正确',
    },
  ]);
  return (
    <ProFormText
      fieldProps={{ maxLength: 18 }}
      {...restProps}
      rules={rules}
      // @ts-ignore
      onChange={(e) => {
        if (pattern.test(e.target.value)) {
          const birthday = e.target.value.substring(6, 14); // 假设出生日期是身份证号码的第7到14位
          const age = dayjs().diff(dayjs(birthday, 'YYYYMMDD'), 'year');
          props.onAge?.(age);
        }
      }}
    />
  );
};

export default ProFormIDCard;
