// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 修改组织信息 POST /admin/org/edit */
export async function postAdminOrgEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postAdminOrgEditParams,
  body: API.AdminOrgRegisterRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/admin/org/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 组织列表 GET /admin/org/list */
export async function getAdminOrgList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminOrgListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABAdminOrgVoC2BB>('/admin/org/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 注册组织信息 POST /admin/org/register */
export async function postAdminOrgRegister(
  body: API.AdminOrgRegisterRequest,
  options?: { [key: string]: any },
) {
  return request<API.Org>('/admin/org/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 检测组织是否完善工商信息 GET /org/check */
export async function getOrgCheck(options?: { [key: string]: any }) {
  return request<API.OrgOfficialResultVo>('/org/check', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 修改组织信息 POST /org/edit */
export async function postOrgEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postOrgEditParams,
  body: API.OrgRegisterRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/org/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 完善工商信息 POST /org/official_info */
export async function postOrgOfficialInfo(
  body: API.OrgSetOfficialInfoRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/org/official_info', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 注册组织信息 POST /org/register */
export async function postOrgRegister(
  body: API.OrgRegisterRequest,
  options?: { [key: string]: any },
) {
  return request<API.Org>('/org/register', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
