// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加 POST /official_document/seal/add */
export async function postOfficialDocumentSealAdd(
  body: API.OfficialDocumentSealRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentSeal>('/official_document/seal/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /official_document/seal/delete */
export async function postOfficialDocumentSealOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/official_document/seal/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /official_document/seal/edit */
export async function postOfficialDocumentSealEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postOfficialDocumentSealEditParams,
  body: API.OfficialDocumentSealRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentSeal>('/official_document/seal/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 导出 POST /official_document/seal/export */
export async function postOfficialDocumentSealOpenApiExport(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/seal/export', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /official_document/seal/list */
export async function getOfficialDocumentSealList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentSealListParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentSealVo[]>('/official_document/seal/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 分页列表 GET /official_document/seal/page_list */
export async function getOfficialDocumentSealPageList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentSealPageListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABOfficialDocumentSealVoC2BB>('/official_document/seal/page_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 测试电子签章 POST /official_document/seal/test */
export async function postOfficialDocumentSealTest(options?: { [key: string]: any }) {
  return request<number[]>('/official_document/seal/test', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 升为电子印章 注：需审核通过后才算升级成功 POST /official_document/seal/upgrade */
export async function postOfficialDocumentSealUpgrade(
  body: API.OfficialDocumentSealUpgradeRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/seal/upgrade', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
