// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 获取access_token GET /third_app/session/get_access_token */
export async function getThirdAppSessionGetAccessToken(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getThirdAppSessionGetAccessTokenParams,
  options?: { [key: string]: any },
) {
  return request<API.ThirdAppSessionVo>('/third_app/session/get_access_token', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** rpc通讯 POST /third_app/session/jsonrpc */
export async function postThirdAppSessionJsonrpc(
  body: API.ThirdAppJsonRpcRequest,
  options?: { [key: string]: any },
) {
  return request<API.Object>('/third_app/session/jsonrpc', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
