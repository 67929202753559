// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 分类添加监控点 POST /hk_category/add_relation */
export async function postHkCategoryAddRelation(
  body: API.HkCategoryRelationRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/hk_category/add_relation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 分类列表（督导室） GET /hk_category/category_list */
export async function getHkCategoryCategoryList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkCategoryCategoryListParams,
  options?: { [key: string]: any },
) {
  return request<API.HkCameraCategory[]>('/hk_category/category_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除 POST /hk_category/delete */
export async function postHkCategoryOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/hk_category/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除-分类监控点 POST /hk_category/delete_relation */
export async function postHkCategoryDeleteRelation(
  body: API.HkCategoryRelationRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/hk_category/delete_relation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 分类列表 GET /hk_category/list */
export async function getHkCategoryList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkCategoryListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHkCameraCategoryVoC2BB>('/hk_category/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 新增/编辑分类 POST /hk_category/save */
export async function postHkCategorySave(
  body: API.HkCategoryAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.HkCameraCategory>('/hk_category/save', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
