// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 我的班级列表 GET /myClass/classList */
export async function getMyClassClassList(options?: { [key: string]: any }) {
  return request<API.MyClassVo[]>('/myClass/classList', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 统计 GET /myClass/count */
export async function getMyClassCount(options?: { [key: string]: any }) {
  return request<API.GradeVo>('/myClass/count', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 成绩 GET /myClass/gradeList */
export async function getMyClassGradeList(options?: { [key: string]: any }) {
  return request<API.GradeVo[]>('/myClass/gradeList', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 学生列表 GET /myClass/studentList */
export async function getMyClassStudentList(options?: { [key: string]: any }) {
  return request<API.SchoolStudentVo[]>('/myClass/studentList', {
    method: 'GET',
    ...(options || {}),
  });
}
