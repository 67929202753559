const toMediaElement = async (
  url?: Blob | MediaSource,
): Promise<HTMLAudioElement> => {
  return new Promise((resolve, reject) => {
    try {
      if (!url) {
        reject('url为空');
        return;
      }
      const fileUrl = URL.createObjectURL(url);
      const audio = new Audio(fileUrl);
      audio.addEventListener('loadedmetadata', function () {
        resolve(audio);
        URL.revokeObjectURL(fileUrl);
      });
    } catch (e) {
      reject(e);
    }
  });
};
export default toMediaElement;
