import Api from '@/services/api';
import { TagFilled } from '@ant-design/icons';
import { useRequest } from 'ahooks';
import { Skeleton, Spin, Tree } from 'antd';
import { map, reduce } from 'lodash-es';
import { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useTreeData } from '../hooks';
import { SetCheckedNodes, TreeDataType } from '../index';
import { titleRender } from '../utils';
import { useCheck } from './useCheck';
import { useLoadData } from './useLoadData';

type TagTreeProps = {
  checkedNodes: TreeDataType[];
  setCheckedNodes: SetCheckedNodes;
};

const TagTree = (props: TagTreeProps) => {
  const { checkedNodes, setCheckedNodes } = props;
  const [loadedKeys, setLoadedKeys] = useState<string[]>([]);
  const {
    data = [],
    loading,
    mutate,
  } = useRequest<TreeDataType[], any>(async () => {
    const data =
      await Api.bumenchengyuanbiaoqianguanli.getDepartmentMemberTagPageList({});
    return data.map((item) => ({
      id: item.id!,
      key: uuidv4(),
      isLeaf: false,
      checkable: true,
      title: item.name,
    }));
  });
  const treeData = useTreeData({ loadedKeys, data });
  const loadData = useLoadData({
    mutate,
    checkedNodes,
    setLoadedKeys,
    setCheckedNodes,
  });
  const { onCheck, loading: checkLoading } = useCheck({
    data,
    mutate,
    loadedKeys,
    setLoadedKeys,
    setCheckedNodes,
  });
  const checkedIds = map(checkedNodes, 'id');
  const checkedKeys = reduce(
    data,
    (prev: string[], curr) => {
      if (checkedIds.includes(curr.id)) {
        prev.push(curr.key);
      }
      return prev;
    },
    [],
  );
  return (
    <Skeleton
      active
      loading={loading}
      className={'p-[15px]'}
      paragraph={{ rows: 7 }}
    >
      <Spin spinning={checkLoading}>
        <Tree
          showIcon
          blockNode
          checkable
          onCheck={onCheck}
          selectable={false}
          treeData={treeData}
          loadData={loadData}
          loadedKeys={loadedKeys}
          titleRender={titleRender}
          checkedKeys={checkedKeys}
          icon={({ isLeaf }: any) => {
            if (!isLeaf) {
              return (
                <div className={'icon-wrap'}>
                  <TagFilled />
                </div>
              );
            }
          }}
        />
      </Spin>
    </Skeleton>
  );
};

export default TagTree;
