import { ProFormItemProps, ProFormText } from '@ant-design/pro-components';
import { InputProps } from 'antd';
import { compact, concat } from 'lodash-es';

type ProFormPhoneNumberProps = ProFormItemProps & {
  fieldProps?: InputProps;
};

const pattern = /^1\d{10}$/;

const ProFormPhoneNumber = (props: ProFormPhoneNumberProps) => {
  const { rules: propsRules, ...restProps } = props;
  const rules = concat(compact(propsRules), [
    {
      pattern,
      message: '手机号码格式不正确',
    },
  ]);
  return (
    <ProFormText fieldProps={{ maxLength: 11 }} {...restProps} rules={rules} />
  );
};

export default ProFormPhoneNumber;
