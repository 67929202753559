// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 详情 GET /admin/school/${param0} */
export async function getAdminSchoolId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminSchoolIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.AdminSchoolDetailVo>(`/admin/school/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 修改 POST /admin/school/${param0} */
export async function postAdminSchoolId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postAdminSchoolIdParams,
  body: API.AdminSchoolEditRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.AdminSchoolDetailVo>(`/admin/school/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 添加 POST /admin/school/add */
export async function postAdminSchoolAdd(
  body: API.AdminSchoolAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.School>('/admin/school/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /admin/school/delete */
export async function postAdminSchoolOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/admin/school/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /admin/school/list */
export async function getAdminSchoolList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminSchoolListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABAdminSchoolVoC2BB>('/admin/school/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 详情 GET /school/${param0} */
export async function getSchoolId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.School>(`/school/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 修改 POST /school/${param0} */
export async function postSchoolId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postSchoolIdParams,
  body: API.SchoolAddRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.School>(`/school/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 添加 POST /school/add */
export async function postSchoolAdd(body: API.SchoolAddRequest, options?: { [key: string]: any }) {
  return request<API.School>('/school/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表list GET /school/all_list */
export async function getSchoolAllList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolAllListParams,
  options?: { [key: string]: any },
) {
  return request<API.SchoolVo[]>('/school/all_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除 POST /school/delete */
export async function postSchoolOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/school/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 快捷添加 POST /school/fast_add */
export async function postSchoolFastAdd(
  body: API.SchoolFastAddRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/school/fast_add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /school/list */
export async function getSchoolList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolVoC2BB>('/school/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 学校班级体检数据导入 POST /school/school_checkup/import */
export async function postSchoolSchoolCheckupOpenApiImport(
  body: {},
  file?: File,
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<string>('/school/school_checkup/import', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 体检汇总导入模板下载 GET /school/school_checkup/import_template */
export async function getSchoolSchoolCheckupImportTemplate(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/school/school_checkup/import_template', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 教育局体检学校汇总数据 GET /school/school_checkup/org_list */
export async function getSchoolSchoolCheckupOrgList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolSchoolCheckupOrgListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolCheckupOrgVoC2BB>('/school/school_checkup/org_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 学校体检数据 GET /school/school_checkup/school_list */
export async function getSchoolSchoolCheckupSchoolList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolSchoolCheckupSchoolListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolCheckupSchoolVoC2BB>('/school/school_checkup/school_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
