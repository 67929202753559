// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 创建图片章 POST /v2/official_document/seal/add */
export async function postV2OfficialDocumentSealAdd(
  body: API.OfficialDocumentSealRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentSeal>('/v2/official_document/seal/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 创建模板章 POST /v2/official_document/seal/add_template */
export async function postV2OfficialDocumentSealAddTemplate(
  body: API.OfficialDocumentV2SealAddTemplateRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentSeal>('/v2/official_document/seal/add_template', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 申请授权 POST /v2/official_document/seal/apply_auth */
export async function postV2OfficialDocumentSealApplyAuth(
  body: API.OfficialDocumentV2ApplySealAuthRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentV2SealApplyAuthVo>('/v2/official_document/seal/apply_auth', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /v2/official_document/seal/delete */
export async function postV2OfficialDocumentSealOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/v2/official_document/seal/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /v2/official_document/seal/list */
export async function getV2OfficialDocumentSealList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getV2OfficialDocumentSealListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABOfficialDocumentSealVoC2BB>('/v2/official_document/seal/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
