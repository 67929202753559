import { isDev } from '@hudiemon/utils';
import { request } from '@umijs/max';
import { useRequest } from 'ahooks';
import { Modal } from 'antd';

/**
 * 检查系统更新
 */
const metaElement = document.querySelector('meta[name="version"]');
const domVersion = metaElement?.getAttribute('content');
const useDetectUpdates = () => {
  const { cancel } = useRequest(
    () =>
      request('../config.json', {
        params: {
          t: +new Date(),
        },
      }),
    {
      ready: !isDev,
      pollingInterval: 10000,
      pollingWhenHidden: false,
      pollingErrorRetryCount: 3,
      onSuccess: ({ version }) => {
        if (domVersion !== `${version}`) {
          console.log('html version', domVersion);
          console.log('接口返回', version);
          cancel();
          Modal.warning({
            title: '当系统已更新，请刷新',
            okText: '刷新',
            maskClosable: false,
            onOk: () => {
              window.location.replace(`/?t=${+new Date()}`);
            },
          });
        }
      },
    },
  );
};
export default useDetectUpdates;
