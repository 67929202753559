// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 获取系统配置 GET /system/get_config */
export async function getSystemGetConfig(options?: { [key: string]: any }) {
  return request<API.SystemConfigVo>('/system/get_config', {
    method: 'GET',
    ...(options || {}),
  });
}
