// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 历年学段数 GET /data_screen/calendar_school/level_counter */
export async function getDataScreenCalendarSchoolLevelCounter(options?: { [key: string]: any }) {
  return request<API.MapC2ABLongC2BB>('/data_screen/calendar_school/level_counter', {
    method: 'GET',
    ...(options || {}),
  });
}
