// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 创建 POST /official_document/history/create */
export async function postOfficialDocumentHistoryCreate(options?: { [key: string]: any }) {
  return request<API.OfficialDocumentHistoryCreateVo>('/official_document/history/create', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 获取文档信息（wps服务器用） GET /v3/3rd/files/${param0} */
export async function getV33rdFilesFileId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getV33rdFilesFileIdParams,
  options?: { [key: string]: any },
) {
  const { fileId: param0, ...queryParams } = params;
  return request<API.OfficialDocumentHistoryResultVoC2ABOfficialDocumentHistoryInfoVoC2BB>(
    `/v3/3rd/files/${param0}`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 获取文件下载地址（wps服务器用） GET /v3/3rd/files/${param0}/download */
export async function getV33rdFilesFileIdDownload(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getV33rdFilesFileIdDownloadParams,
  options?: { [key: string]: any },
) {
  const { fileId: param0, ...queryParams } = params;
  return request<API.OfficialDocumentHistoryResultVoC2ABOfficialDocumentHistoryDownloadVoC2BB>(
    `/v3/3rd/files/${param0}/download`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 文档用户权限（wps服务器用） GET /v3/3rd/files/${param0}/permission */
export async function getV33rdFilesFileIdPermission(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getV33rdFilesFileIdPermissionParams,
  options?: { [key: string]: any },
) {
  const { fileId: param0, ...queryParams } = params;
  return request<API.OfficialDocumentHistoryResultVoC2ABOfficialDocumentHistoryPermissionVoC2BB>(
    `/v3/3rd/files/${param0}/permission`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 准备上传阶段 POST /v3/3rd/files/${param0}/upload */
export async function postV33rdFilesFileIdUpload(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postV33rdFilesFileIdUploadParams,
  body: {
    /** 文档名称 */
    name?: string;
    /** 大小 */
    size?: string;
    /** 文档校验 */
    sha1?: string;
    /** 是否手动保存 */
    is_manual?: string;
    /** 附件大小 */
    attachment_size?: string;
    /** 文档的mime类型 */
    content_type?: string;
  },
  file?: File,
  options?: { [key: string]: any },
) {
  const { fileId: param0, ...queryParams } = params;
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<API.OfficialDocumentHistoryResultVoC2ABOfficialDocumentHistoryAddressVoC2BB>(
    `/v3/3rd/files/${param0}/upload`,
    {
      method: 'POST',
      params: { ...queryParams },
      data: formData,
      requestType: 'form',
      ...(options || {}),
    },
  );
}

/** 文档水印 GET /v3/3rd/files/${param0}/watermark */
export async function getV33rdFilesFileIdWatermark(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getV33rdFilesFileIdWatermarkParams,
  options?: { [key: string]: any },
) {
  const { fileId: param0, ...queryParams } = params;
  return request<API.OfficialDocumentHistoryResultVoC2ABMapC2ABObjectC2BBC2BB>(
    `/v3/3rd/files/${param0}/watermark`,
    {
      method: 'GET',
      params: { ...queryParams },
      ...(options || {}),
    },
  );
}

/** 获取用户信息（wps服务器用） GET /v3/3rd/users */
export async function getV33rdUsers(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getV33rdUsersParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentHistoryResultVoC2ABListC2ABOfficialDocumentHistoryUserInfoVoC2BBC2BB>(
    '/v3/3rd/users',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}
