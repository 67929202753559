// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 删除 POST /smsRecord/delete */
export async function postSmsRecordOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/smsRecord/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 导出 GET /smsRecord/excel */
export async function getSmsRecordExcel(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/smsRecord/excel', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 详情 GET /smsRecord/get/${param0} */
export async function getSmsRecordGetId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSmsRecordGetIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.SmsRecord>(`/smsRecord/get/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 列表 GET /smsRecord/list */
export async function getSmsRecordList(options?: { [key: string]: any }) {
  return request<API.SPageC2ABSmsRecordC2BB>('/smsRecord/list', {
    method: 'GET',
    ...(options || {}),
  });
}
