import { Input, Tabs } from 'antd';
import { uniqBy } from 'lodash-es';
import { useEffect, useMemo, useState } from 'react';
import DepartmentTree from './DepartmentTree';
import Member from './Member';
import SearchResult from './SearchResult';
import style from './Selector.module.less';
import Tags from './Tags';

export type CheckedNodesType = {
  label: string;
  value: string;
  id: string;
  _original: any;
  children?: CheckedNodesType[];
};
export type SetCheckedNodesType = (
  checkedNodes: CheckedNodesType[],
  checked?: boolean,
) => void;

type SelectorProps = {
  value?: React.Key[];
  // 0:教育局、1:学校
  orgType?: ('0' | '1')[];
  multiple?: boolean;
  onChange?: (checkedNodes: CheckedNodesType[]) => void;
};
const Selector = (props: SelectorProps) => {
  const { orgType: propsOrgType } = props;
  const [keyword, setKeyword] = useState<string>();
  const [checkedNodes, setCheckedNodes] = useState<CheckedNodesType[]>([]);
  useEffect(() => {
    props.onChange?.(checkedNodes);
  }, [checkedNodes]);
  const onSetCheckedNodes: SetCheckedNodesType = (checkedNodes, checked) => {
    if (checked === true) {
      setCheckedNodes((originalCheckedNodes) => {
        return uniqBy(originalCheckedNodes.concat(checkedNodes), 'id');
      });
    } else if (checked === false) {
      setCheckedNodes((originalCheckedNodes) => {
        return originalCheckedNodes.filter(
          ({ id }) => !checkedNodes.some((node) => id === node.id),
        );
      });
    } else {
      setCheckedNodes(checkedNodes);
    }
  };
  const orgType = useMemo(() => {
    if (propsOrgType) {
      return propsOrgType;
    }
    if (window.orgType === 0) {
      return ['0', '1'];
    }
    return ['1'];
  }, [propsOrgType]);
  const items = useMemo(() => {
    const school = {
      key: '1',
      label: '学校',
      children: (
        <DepartmentTree
          multiple={props.multiple}
          params={
            { type: '1', my: window.orgType === 0 ? '2' : undefined } as any
          }
          checkedNodes={checkedNodes}
          setCheckedNodes={onSetCheckedNodes}
        />
      ),
    };
    const bureau = {
      key: '0',
      label: '教育局',
      children: (
        <DepartmentTree
          multiple={props.multiple}
          params={{ type: '0' } as any}
          checkedNodes={checkedNodes}
          setCheckedNodes={onSetCheckedNodes}
        />
      ),
    };
    const items = [];
    if (orgType.includes('0')) {
      items.push(bureau);
    }
    if (orgType.includes('1')) {
      items.push(school);
    }
    items.push({
      key: '2',
      label: '标签',
      children: (
        <Tags checkedNodes={checkedNodes} setCheckedNodes={onSetCheckedNodes} />
      ),
    });
    return items;
  }, [orgType, checkedNodes]);

  return (
    <div className={style.Selector}>
      <Input.Search
        placeholder={'搜索人员'}
        onSearch={(keyword) => {
          setKeyword(keyword);
        }}
      />
      <div className={'flex gap-x-[20px] h-[50vh]'}>
        <div
          className={
            'flex-1 border-[1px] border-solid border-[#d9d9d9] rounded-[10px] flex flex-col'
          }
        >
          {!keyword ? (
            <Tabs
              items={items}
              renderTabBar={(props, DefaultTabBar) => {
                if (items.length === 1) return <div></div>;
                return <DefaultTabBar {...props} />;
              }}
            />
          ) : (
            <SearchResult
              keyword={keyword}
              // @ts-ignore
              orgType={orgType}
              checkedNodes={checkedNodes}
              setCheckedNodes={onSetCheckedNodes}
            />
          )}
        </div>
        <div
          className={
            'flex-1 border-[1px] border-solid border-[#d9d9d9] rounded-[10px] flex flex-col'
          }
        >
          <Member
            value={props.value}
            checkedNodes={checkedNodes}
            setCheckedNodes={onSetCheckedNodes}
          />
        </div>
      </div>
    </div>
  );
};

export default Selector;
