// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 获取填报数据 GET /form_report/fill/answer_data */
export async function getFormReportFillAnswerData(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportFillAnswerDataParams,
  options?: { [key: string]: any },
) {
  return request<API.FormReportFilledDataBaseVo>('/form_report/fill/answer_data', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 问卷统计已填报详情导出 GET /form_report/fill/count_export_filled/export */
export async function getFormReportFillCountExportFilledOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportFillCountExportFilled_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/form_report/fill/count_export_filled/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 问卷统计未填报详情导出 GET /form_report/fill/count_export_not_filled/export */
export async function getFormReportFillCountExportNotFilledOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportFillCountExportNotFilled_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/form_report/fill/count_export_not_filled/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取问卷统计详情可导出列 GET /form_report/fill/count_export/columns */
export async function getFormReportFillCountExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/form_report/fill/count_export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取已填报列表（统计） GET /form_report/fill/filled */
export async function getFormReportFillFilled(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportFillFilledParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABFormReportFilledVoC2BB>('/form_report/fill/filled', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取未填报列表（统计） GET /form_report/fill/not_filled */
export async function getFormReportFillNotFilled(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportFillNotFilledParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABFormReportNotFilledVoC2BB>('/form_report/fill/not_filled', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 提交问卷结果 POST /form_report/fill/submit */
export async function postFormReportFillSubmit(
  body: API.FormReportFillSubmitRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/form_report/fill/submit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 新建问卷 POST /form_report/survey/add */
export async function postFormReportSurveyAdd(
  body: API.FormReportSurveyAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.FormReportSurvey>('/form_report/survey/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 问卷列表可导出列 GET /form_report/survey/columns */
export async function getFormReportSurveyColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/form_report/survey/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 删除 POST /form_report/survey/delete */
export async function postFormReportSurveyOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/form_report/survey/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /form_report/survey/edit */
export async function postFormReportSurveyEdit(
  body: API.FormReportSurveyQuestionEditRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/form_report/survey/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 导出问卷列表 GET /form_report/survey/export */
export async function getFormReportSurveyOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportSurvey_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/form_report/survey/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 详情 GET /form_report/survey/get */
export async function getFormReportSurveyGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportSurveyGetParams,
  options?: { [key: string]: any },
) {
  return request<API.FormReportSurveyDetailVo>('/form_report/survey/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 列表（全部问卷/全部上报） GET /form_report/survey/list */
export async function getFormReportSurveyList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportSurveyListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABFormReportSurveyVoC2BB>('/form_report/survey/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取问卷分页列表（已废弃） POST /form_report/survey/list */
export async function postFormReportSurveyList(options?: { [key: string]: any }) {
  return request<API.SPageC2ABFormReportSurveyVoC2BB>('/form_report/survey/list', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 列表（我创建的） GET /form_report/survey/my_create */
export async function getFormReportSurveyMyCreate(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportSurveyMyCreateParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABFormReportSurveyVoC2BB>('/form_report/survey/my_create', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 我的创建可导出列 GET /form_report/survey/my_create/columns */
export async function getFormReportSurveyMyCreateColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/form_report/survey/my_create/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 我的创建导出 GET /form_report/survey/my_create/export */
export async function getFormReportSurveyMyCreateOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportSurveyMyCreate_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/form_report/survey/my_create/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 列表（我填报的） GET /form_report/survey/my_filled */
export async function getFormReportSurveyMyFilled(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportSurveyMyFilledParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABFormReportSurveyAcceptVoC2BB>('/form_report/survey/my_filled', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 我的填报可导出列 GET /form_report/survey/my_filled/columns */
export async function getFormReportSurveyMyFilledColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/form_report/survey/my_filled/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 我的填报导出 GET /form_report/survey/my_filled/export */
export async function getFormReportSurveyMyFilledOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportSurveyMyFilled_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/form_report/survey/my_filled/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 发布 POST /form_report/survey/publish */
export async function postFormReportSurveyPublish(
  body: API.FormReportSurveyPublishRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/form_report/survey/publish', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 问卷撤销发布 POST /form_report/survey/revoke_publish */
export async function postFormReportSurveyRevokePublish(
  body: API.FormReportSurveyPublishRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/form_report/survey/revoke_publish', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
