// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 列表 GET /school/level/list */
export async function getSchoolLevelList(options?: { [key: string]: any }) {
  return request<API.SchoolLevel[]>('/school/level/list', {
    method: 'GET',
    ...(options || {}),
  });
}
