// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 修改用户信息 POST /user/${param0} */
export async function postUserId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postUserIdParams,
  body: API.UserEditRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<Record<string, any>>(`/user/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 添加用户 POST /user/add */
export async function postUserAdd(body: API.UserAddRequest, options?: { [key: string]: any }) {
  return request<API.User>('/user/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 用户-app绑定映射 POST /user/app_mapping */
export async function postUserAppMapping(
  body: API.UserAppBindingRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/user/app_mapping', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** app消息推送测试接口 POST /user/app_message_push_test */
export async function postUserAppMessagePushTest(
  body: API.AppPushReqDto,
  options?: { [key: string]: any },
) {
  return request<API.AppPushResultDto>('/user/app_message_push_test', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除用户 POST /user/delete */
export async function postUserOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/user/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /user/get */
export async function getUserGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserGetParams,
  options?: { [key: string]: any },
) {
  return request<API.UserVo>('/user/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 极光推送测试接口 POST /user/jg_message_push_test */
export async function postUserJgMessagePushTest(
  body: API.JGPushReqDto,
  options?: { [key: string]: any },
) {
  return request<API.PushSendResult>('/user/jg_message_push_test', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /user/list */
export async function getUserList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABUserC2BB>('/user/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 批量获取用户信息 GET /user/list_ids */
export async function getUserListIds(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserListIdsParams,
  options?: { [key: string]: any },
) {
  return request<API.User[]>('/user/list_ids', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 用户注销 GET /user/logout */
export async function getUserLogout(options?: { [key: string]: any }) {
  return request<number>('/user/logout', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取绑定角色 GET /user/roles */
export async function getUserRoles(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserRolesParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/user/roles', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 设置绑定角色 POST /user/set_roles */
export async function postUserSetRoles(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postUserSetRolesParams,
  body: API.UserSetRoleRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/user/set_roles', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改个人信息 POST /user/update_personal_info */
export async function postUserUpdatePersonalInfo(
  body: API.UpdatePersonalInfoRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/user/update_personal_info', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 用户组织查询 GET /user/user_auth_org */
export async function getUserUserAuthOrg(options?: { [key: string]: any }) {
  return request<API.UserAuthOrgDto[]>('/user/user_auth_org', {
    method: 'GET',
    ...(options || {}),
  });
}
