import Api from '@/services/api';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import {
  ProForm,
  ProFormItemProps,
  ProFormSelect,
  ProFormText,
} from '@ant-design/pro-components';
import { Button } from '@dyrl/web';
import { Access } from '@umijs/max';
import { useRequest } from 'ahooks';
import { Divider, message } from 'antd';
import { useState } from 'react';

type ProFormTagsProps = ProFormItemProps;

const ProFormTags = (props: ProFormTagsProps) => {
  const { ...restProps } = props;
  const [active, setActive] = useState<boolean>(false);
  const { data, refresh, loading, run } = useRequest(
    Api.bumenchengyuanbiaoqianguanli.getDepartmentMemberTagPageList,
  );
  return (
    <ProFormSelect
      {...restProps}
      showSearch
      mode={'multiple'}
      options={data}
      fieldProps={{
        loading,
        onSearch: (name) => run({ name }),
        fieldNames: {
          value: 'id',
          label: 'name',
        },
        dropdownRender: (menu) => {
          return (
            <div>
              {menu}
              <Divider className={'my-[8px]'} />
              <div className={'px-[8px] pb-[4px]'}>
                <Access
                  accessible={active}
                  fallback={
                    <Button
                      type={'link'}
                      icon={<PlusOutlined />}
                      onClick={() => setActive((active) => !active)}
                    >
                      新建标签
                    </Button>
                  }
                >
                  <ProForm<API.DepartmentMemberAddRequest>
                    layout={'inline'}
                    submitter={{
                      render: ({ submit }) => {
                        return (
                          <>
                            <Button
                              type={'link'}
                              icon={<CheckOutlined />}
                              onClick={() => submit()}
                            />
                            <Button
                              danger
                              type={'link'}
                              icon={<CloseOutlined />}
                              onClick={() => setActive((active) => !active)}
                            />
                          </>
                        );
                      },
                    }}
                    onFinish={async (formData) => {
                      await Api.bumenchengyuanbiaoqianguanli.postDepartmentMemberTagAdd(
                        formData,
                      );
                      message.success('新建标签成功');
                      setActive((active) => !active);
                      refresh();
                    }}
                  >
                    <ProFormText
                      name={'name'}
                      fieldProps={{
                        onKeyDown: (e) => e.stopPropagation(),
                      }}
                      placeholder={'请输入标签'}
                      rules={[{ required: true, message: '请输入标签' }]}
                    />
                  </ProForm>
                </Access>
              </div>
            </div>
          );
        },
      }}
    />
  );
};

export default ProFormTags;
