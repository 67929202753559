import { Result } from 'ahooks/lib/useRequest/src/types';
import { TreeProps } from 'antd';
import {
  compact,
  differenceBy,
  filter,
  flatten,
  map,
  unionBy,
} from 'lodash-es';
import { Dispatch, SetStateAction, useState } from 'react';
import { TreeDataType } from '../index';
import { departmentDeep, departmentLoaded, requestTagMember } from '../utils';

type Store = {
  data: TreeDataType[];
  loadedKeys: string[];
  setLoadedKeys: Dispatch<SetStateAction<string[]>>;
  setCheckedNodes: Dispatch<SetStateAction<TreeDataType[]>>;
  mutate: Result<TreeDataType[], any>['mutate'];
};

export const useCheck = ({
  data,
  mutate,
  loadedKeys,
  setLoadedKeys,
  setCheckedNodes,
}: Store) => {
  const [loading, setLoading] = useState<boolean>(false);
  const onCheck: TreeProps<TreeDataType>['onCheck'] = async (checked, info) => {
    console.log('onCheck', info.node);
    // 人员节点点击
    if (info.node.isMember === true) {
      console.log('info.node.checked', info.node.checked);
      if (!info.node.checked) {
        setCheckedNodes((checkedNodes) =>
          unionBy(checkedNodes, filter(data, { id: info.node.id }), 'key'),
        );
        return;
      }
      setCheckedNodes((checkedNodes) =>
        differenceBy(checkedNodes, info.node.value!, 'id'),
      );
      return;
    }
    // 部门节点点击
    const loaded = departmentLoaded(info.node, loadedKeys);
    if (!loaded) {
      try {
        setLoading(true);
        const departmentKeys = departmentDeep([info.node], 'key');
        const loadData = await requestTagMember({
          tag_ids: [info.node.id],
        });
        mutate((oldData) => unionBy(oldData, loadData, 'key'));
        setCheckedNodes((checkedNodes) =>
          unionBy(
            checkedNodes,
            flatten(compact(map(loadData, 'value'))),
            'key',
          ),
        );
        setLoadedKeys((loadedKeys) => unionBy(loadedKeys, departmentKeys));
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
      return;
    }
    const memberNodes = map(info.node.children, (item) => item.value);
    if (!info.node.checked) {
      setCheckedNodes((checkedNodes) =>
        unionBy(checkedNodes, flatten(compact(memberNodes)), 'key'),
      );
      return;
    }
    setCheckedNodes((checkedNodes) =>
      differenceBy(checkedNodes, flatten(compact(memberNodes)), 'key'),
    );
  };
  return { onCheck, loading };
};
