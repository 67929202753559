import { CloseOutlined } from '@ant-design/icons';
import { Avatar } from '@dyrl/web';
import { Empty, Tag } from 'antd';
import { differenceBy, isEmpty, unionBy } from 'lodash-es';
import { SetCheckedNodes, TreeDataType } from './index';

type MemberListProps = {
  checkedNodes: TreeDataType[];
  setCheckedNodes: SetCheckedNodes;
};

const MemberList = (props: MemberListProps) => {
  const { checkedNodes, setCheckedNodes } = props;
  const data = unionBy(checkedNodes, 'id');
  return (
    <div className={'flex flex-col flex-auto overflow-hidden'}>
      <div
        style={{ borderBottom: '1px solid #f0f0f0' }}
        className={
          'flex justify-between items-center h-[45px] px-[15px] min-h-[45px]'
        }
      >
        已选：{data.length}名成员
        <a onClick={() => setCheckedNodes([])}>清空</a>
      </div>
      {isEmpty(data) ? (
        <div className={'flex flex-1 justify-center items-center'}>
          <Empty />
        </div>
      ) : (
        <div className={'flex-auto overflow-y-auto'}>
          {data?.map((item) => {
            return (
              <div
                key={item.key}
                className={'flex justify-between py-[5px] px-[15px]'}
              >
                <div className={'flex gap-x-[5px] items-center'}>
                  <Avatar size={30} src={item.avatarUrl}>
                    {item.title}
                  </Avatar>
                  {item.title}
                  <Tag>{item.orgName}</Tag>
                </div>
                <div
                  className={'cursor-pointer flex items-center'}
                  onClick={() =>
                    setCheckedNodes((checkedNodes) =>
                      differenceBy(checkedNodes, [item], 'id'),
                    )
                  }
                >
                  <CloseOutlined />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MemberList;
