// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加 POST /admin/user/add */
export async function postAdminUserAdd(
  body: API.AdminUserRequest,
  options?: { [key: string]: any },
) {
  return request<API.AdminUserVo>('/admin/user/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /admin/user/delete */
export async function postAdminUserOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number[]>('/admin/user/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /admin/user/edit */
export async function postAdminUserEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postAdminUserEditParams,
  body: API.AdminUserRequest,
  options?: { [key: string]: any },
) {
  return request<API.AdminUserVo>('/admin/user/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /admin/user/get */
export async function getAdminUserGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminUserGetParams,
  options?: { [key: string]: any },
) {
  return request<API.Detail>('/admin/user/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 列表 GET /admin/user/list */
export async function getAdminUserList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminUserListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABAdminUserVoC2BB>('/admin/user/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
