// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 列表 GET /admin/official_document/rank/list */
export async function getAdminOfficialDocumentRankList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminOfficialDocumentRankListParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentRankVo[]>('/admin/official_document/rank/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 导出 POST /official_document/rank/export */
export async function postOfficialDocumentRankOpenApiExport(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/rank/export', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /official_document/rank/list */
export async function getOfficialDocumentRankList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentRankListParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentRankVo[]>('/official_document/rank/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
