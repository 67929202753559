// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 组织、部门\成员同步到华创云 POST /net_meeting/data_sync */
export async function postNetMeetingDataSync(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postNetMeetingDataSyncParams,
  options?: { [key: string]: any },
) {
  return request<string>('/net_meeting/data_sync', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除会议 POST /net_meeting/delete */
export async function postNetMeetingOpenApiDelete(
  body: API.NetMeetingDeleteRequest,
  options?: { [key: string]: any },
) {
  return request<string[]>('/net_meeting/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改会议信息 POST /net_meeting/edit */
export async function postNetMeetingEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postNetMeetingEditParams,
  body: API.NetMeetingSubscribeRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/net_meeting/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取可导出列 GET /net_meeting/export/columns */
export async function getNetMeetingOpenApiExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/net_meeting/export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取会议详情 GET /net_meeting/get */
export async function getNetMeetingGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingGetParams,
  options?: { [key: string]: any },
) {
  return request<API.NetMeetingDetailVo>('/net_meeting/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据分享码获取简单信息 GET /net_meeting/get_by_share_code */
export async function getNetMeetingGetByShareCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingGetByShareCodeParams,
  options?: { [key: string]: any },
) {
  return request<API.NetMeetingSimpleVo>('/net_meeting/get_by_share_code', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取华创云配置 GET /net_meeting/get_config */
export async function getNetMeetingGetConfig(options?: { [key: string]: any }) {
  return request<API.NetMeetingConfigVo>('/net_meeting/get_config', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取成员对应华创云ID GET /net_meeting/get_dh_id/${param0} */
export async function getNetMeetingGetDhIdMemberId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingGetDhIdMemberIdParams,
  options?: { [key: string]: any },
) {
  const { member_id: param0, ...queryParams } = params;
  return request<string>(`/net_meeting/get_dh_id/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 获取会议人员列表 GET /net_meeting/get_member_list */
export async function getNetMeetingGetMemberList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingGetMemberListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABNetMeetingMemberVoC2BB>('/net_meeting/get_member_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 加入会议 POST /net_meeting/join */
export async function postNetMeetingJoin(
  body: API.NetMeetingJoinRequest,
  options?: { [key: string]: any },
) {
  return request<API.NetMeetingActionResultVo>('/net_meeting/join', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取参会信息 GET /net_meeting/join_info */
export async function getNetMeetingJoinInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingJoinInfoParams,
  options?: { [key: string]: any },
) {
  return request<API.NetMeetingJoinInfoVo>('/net_meeting/join_info', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 会议列表 GET /net_meeting/list */
export async function getNetMeetingList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABNetMeetingVoC2BB>('/net_meeting/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取云会议小程序打开链接 GET /net_meeting/meeting/url_scheme */
export async function getNetMeetingMeetingUrlScheme(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingMeetingUrlSchemeParams,
  options?: { [key: string]: any },
) {
  return request<string>('/net_meeting/meeting/url_scheme', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取会议成员可导出列 GET /net_meeting/member_export/columns */
export async function getNetMeetingMemberExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/net_meeting/member_export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 导出会议成员列表 GET /net_meeting/net_meeting_member/export */
export async function getNetMeetingNetMeetingMemberOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingNetMeetingMember_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/net_meeting/net_meeting_member/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 状态事件回调 POST /net_meeting/notify_call_back */
export async function postNetMeetingNotifyCallBack(
  body: string[],
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/net_meeting/notify_call_back', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 结束会议 POST /net_meeting/over */
export async function postNetMeetingOver(
  body: API.NetMeetingOverRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/net_meeting/over', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 会议记录导出 GET /net_meeting/record/export */
export async function getNetMeetingRecordOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingRecord_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/net_meeting/record/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 跳转至获取入会链接 GET /net_meeting/redirect */
export async function getNetMeetingRedirect(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingRedirectParams,
  options?: { [key: string]: any },
) {
  return request<API.ResponseEntityC2ABObjectC2BB>('/net_meeting/redirect', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取会议分享链接 GET /net_meeting/share_url */
export async function getNetMeetingShareUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingShareUrlParams,
  options?: { [key: string]: any },
) {
  return request<string>('/net_meeting/share_url', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 快速开始 POST /net_meeting/start */
export async function postNetMeetingStart(
  body: API.NetMeetingStartRequest,
  options?: { [key: string]: any },
) {
  return request<API.NetMeetingActionResultVo>('/net_meeting/start', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 预约会议 POST /net_meeting/subscribe */
export async function postNetMeetingSubscribe(
  body: API.NetMeetingSubscribeRequest,
  options?: { [key: string]: any },
) {
  return request<API.NetMeetingActionResultVo>('/net_meeting/subscribe', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 小程序会议订阅流 POST /net_meeting/subscribe_stream */
export async function postNetMeetingSubscribeStream(
  body: API.NetMeetingSubscribeStreamRequest,
  options?: { [key: string]: any },
) {
  return request<API.NetMeetingSubscribeStreamResultDto>('/net_meeting/subscribe_stream', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 回调地址获取 GET /net_meeting/web_hook_url_get/${param0} */
export async function getNetMeetingWebHookUrlGetGid(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingWebHookUrlGetGidParams,
  options?: { [key: string]: any },
) {
  const { gid: param0, ...queryParams } = params;
  return request<string>(`/net_meeting/web_hook_url_get/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
