// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 详情导出 GET /meeting_count/detail_export/${param0} */
export async function getMeetingCountDetailExportDepartmentId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingCountDetailExportDepartmentIdParams,
  options?: { [key: string]: any },
) {
  const { departmentId: param0, ...queryParams } = params;
  return request<number[]>(`/meeting_count/detail_export/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取详情可导出列 GET /meeting_count/detail_export/columns */
export async function getMeetingCountDetailExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/meeting_count/detail_export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 详情（图表统计数据） GET /meeting_count/detail/count_data/${param0} */
export async function getMeetingCountDetailCountDataDepartmentId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingCountDetailCountDataDepartmentIdParams,
  options?: { [key: string]: any },
) {
  const { departmentId: param0, ...queryParams } = params;
  return request<API.MeetingSignCountChartVo[]>(`/meeting_count/detail/count_data/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 详情（用户统计数据） GET /meeting_count/detail/list_user/${param0} */
export async function getMeetingCountDetailListUserDepartmentId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingCountDetailListUserDepartmentIdParams,
  options?: { [key: string]: any },
) {
  const { departmentId: param0, ...queryParams } = params;
  return request<API.MeetingMemberInfoVo[]>(`/meeting_count/detail/list_user/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 列表 GET /meeting_count/list */
export async function getMeetingCountList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingCountListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABMeetingSignCountVoC2BB>('/meeting_count/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 列表导出 GET /meeting_count/list_export */
export async function getMeetingCountListExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingCountListExportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/meeting_count/list_export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取列表可导出列 GET /meeting_count/list_export/columns */
export async function getMeetingCountListExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/meeting_count/list_export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 成员会议签到记录查询 GET /meeting_count/planSignList/${param0} */
export async function getMeetingCountPlanSignListMemberId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingCountPlanSignListMemberIdParams,
  options?: { [key: string]: any },
) {
  const { memberId: param0, ...queryParams } = params;
  return request<API.SPageC2ABMeetingPlanSignUserVoC2BB>(`/meeting_count/planSignList/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 用户会议记录查询 GET /meeting_count/planSignList/${param0} */
export async function getMeetingCountPlanSignListUserId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingCountPlanSignListUserIdParams,
  options?: { [key: string]: any },
) {
  const { userId: param0, ...queryParams } = params;
  return request<API.SPageC2ABMeetingPlanSignUserVoC2BB>(`/meeting_count/planSignList/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}
