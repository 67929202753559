// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 监控点回放取流 GET /hk_vision/camera/back_url */
export async function getHkVisionCameraBackUrl(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkVisionCameraBackUrlParams,
  options?: { [key: string]: any },
) {
  return request<API.HkCameraBackVo>('/hk_vision/camera/back_url', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 监控点预览取流 GET /hk_vision/camera/preview_url/${param0} */
export async function getHkVisionCameraPreviewUrlCameraIndexCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkVisionCameraPreviewUrlCameraIndexCodeParams,
  options?: { [key: string]: any },
) {
  const { cameraIndexCode: param0, ...queryParams } = params;
  return request<string>(`/hk_vision/camera/preview_url/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 设备列表（废弃） GET /hk_vision/device/list */
export async function getHkVisionDeviceList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkVisionDeviceListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHKDeviceVoC2BB>('/hk_vision/device/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 区域列表（废弃） GET /hk_vision/region/list */
export async function getHkVisionRegionList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkVisionRegionListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHKVisionRegionVoC2BB>('/hk_vision/region/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
