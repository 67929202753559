// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 新增 POST /inform/add */
export async function postInformAdd(body: API.InformAddRequest, options?: { [key: string]: any }) {
  return request<API.Inform>('/inform/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /inform/delete */
export async function postInformOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/inform/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 导出 GET /inform/excel */
export async function getInformExcel(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/inform/excel', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 详情 GET /inform/get/${param0} */
export async function getInformGetId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getInformGetIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.Inform>(`/inform/get/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 列表 GET /inform/list */
export async function getInformList(options?: { [key: string]: any }) {
  return request<API.SPageC2ABInformC2BB>('/inform/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 发送短信 POST /inform/sendMessage */
export async function postInformSendMessage(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postInformSendMessageParams,
  options?: { [key: string]: any },
) {
  return request<API.Inform>('/inform/sendMessage', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
