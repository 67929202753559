import { Result } from 'ahooks/lib/useRequest/src/types';
import { TreeProps } from 'antd';
import { differenceBy, filter, unionBy } from 'lodash-es';
import { Dispatch, SetStateAction, useState } from 'react';
import { TreeDataType } from '../index';
import {
  departmentDeep,
  departmentLoaded,
  memberDeep,
  requestMember,
} from '../utils';

type Store = {
  type?: string;
  data: TreeDataType[];
  loadedKeys: string[];
  setLoadedKeys: Dispatch<SetStateAction<string[]>>;
  setCheckedNodes: Dispatch<SetStateAction<TreeDataType[]>>;
  mutate: Result<TreeDataType[], any>['mutate'];
};

export const useCheck = ({
  data,
  type,
  mutate,
  loadedKeys,
  setLoadedKeys,
  setCheckedNodes,
}: Store) => {
  const [loading, setLoading] = useState<boolean>(false);
  const onCheck: TreeProps<TreeDataType>['onCheck'] = async (checked, info) => {
    // 人员节点点击
    if (info.node.isMember === true) {
      if (!info.node.checked) {
        setCheckedNodes((checkedNodes) =>
          unionBy(checkedNodes, filter(data, { id: info.node.id }), 'key'),
        );
        return;
      }
      setCheckedNodes((checkedNodes) =>
        differenceBy(checkedNodes, [info.node], 'id'),
      );
      return;
    }
    // 部门节点点击
    const loaded = departmentLoaded(info.node, loadedKeys);
    console.log(`【${info.node.title}】是否加载完成`, loaded);
    if (!loaded) {
      try {
        setLoading(true);
        const departmentKeys = departmentDeep([info.node], 'key');
        // 首次全选，将当前节点下的所有人员加载
        const params: API.getDepartmentMemberListByDepartmentParams = {};
        if (type) {
          params.type = [type];
          params.load_children = 1;
          params.department_id = info.node.id;
        } else {
          params.tag_ids = [`${info.node.id}`];
        }
        const loadData = await requestMember(params, departmentKeys);
        console.log(`【${info.node.title}】加载了${loadData.length}个人员`);
        mutate((oldData) => unionBy(oldData, loadData, 'key'));
        setLoadedKeys((loadedKeys) => unionBy(loadedKeys, departmentKeys));
        setCheckedNodes((checkedNodes) =>
          unionBy(checkedNodes, loadData, 'key'),
        );
        setLoading(false);
      } catch (e) {
        setLoading(false);
      }
      // return;
    }
    const memberIds = memberDeep(info.node.children || [], 'id');
    const members = data?.reduce(
      (previousValue: TreeDataType[], currentValue) => {
        if (
          currentValue.isMember === true &&
          memberIds.includes(currentValue?.id)
        ) {
          previousValue.push(currentValue);
        }
        return previousValue;
      },
      [],
    );
    if (!info.node.checked) {
      setCheckedNodes((checkedNodes) => unionBy(checkedNodes, members, 'key'));
      return;
    }
    setCheckedNodes((checkedNodes) =>
      differenceBy(checkedNodes, members!, 'key'),
    );
  };
  return { onCheck, loading };
};
