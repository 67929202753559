import { useRequest } from 'ahooks';
import {
  Tree as AntTree,
  TreeProps as AntTreeProps,
  Skeleton,
  Spin,
} from 'antd';
import { isFunction } from 'lodash-es';

export type TreeProps = AntTreeProps & {
  loading?: boolean;
  value?: React.Key[];
  onChange?: AntTreeProps['onCheck'];
  request?: () => Promise<any>;
};
const Tree = (props: TreeProps) => {
  // @ts-ignore
  const { loading, data = [] } = useRequest(props.request, {
    ready: isFunction(props.request),
  });
  if (loading) {
    return <Skeleton paragraph={{ rows: 8 }} />;
  }
  return (
    <Spin spinning={props.loading}>
      <AntTree
        blockNode
        checkable
        fieldNames={{
          title: 'label',
          key: 'value',
          children: 'children',
        }}
        treeData={data}
        checkedKeys={props.value}
        onCheck={props?.onChange}
        {...props}
      />
    </Spin>
  );
};

export default Tree;
