// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 楼宇详情 GET /school/building/${param0} */
export async function getSchoolBuildingId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolBuildingIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.SchoolBuildingVo>(`/school/building/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 楼宇新增 POST /school/building/add */
export async function postSchoolBuildingAdd(
  body: API.SchoolBuildingAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolBuildingVo>('/school/building/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 房间新增 POST /school/building/add_room */
export async function postSchoolBuildingAddRoom(
  body: API.SchoolBuildingRoomAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolBuildingRoomVo>('/school/building/add_room', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 学校场地删除 POST /school/building/delete */
export async function postSchoolBuildingOpenApiDelete(
  body: API.SchoolBuildingDeleteRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/school/building/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 导出 GET /school/building/export */
export async function getSchoolBuildingOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolBuilding_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/school/building/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取可导出列 GET /school/building/export/columns */
export async function getSchoolBuildingOpenApiExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/school/building/export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 房间列表 GET /school/building/getRoomList */
export async function getSchoolBuildingGetRoomList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolBuildingGetRoomListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolBuildingRoomVoC2BB>('/school/building/getRoomList', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 楼宇列表 GET /school/building/list */
export async function getSchoolBuildingList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolBuildingListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolBuildingVoC2BB>('/school/building/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 房间修改 POST /school/building/update_room/${param0} */
export async function postSchoolBuildingUpdateRoomId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postSchoolBuildingUpdateRoomIdParams,
  body: API.SchoolBuildingRoomAddRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.SchoolBuildingRoomVo>(`/school/building/update_room/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 楼宇修改 POST /school/building/update/${param0} */
export async function postSchoolBuildingUpdateId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postSchoolBuildingUpdateIdParams,
  body: API.SchoolBuildingAddRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.SchoolBuildingVo>(`/school/building/update/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}
