// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 录播会议修改 POST /net_meeting/record */
export async function postNetMeetingRecord(
  body: API.NetMeetingRecordUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/net_meeting/record', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 新增录播会议记录 POST /net_meeting/record/add */
export async function postNetMeetingRecordAdd(
  body: API.NetMeetingRecordAddRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/net_meeting/record/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 录播会议删除 POST /net_meeting/record/delete */
export async function postNetMeetingRecordOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/net_meeting/record/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /net_meeting/record/list */
export async function getNetMeetingRecordList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingRecordListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABNetMeetingRecordVoC2BB>('/net_meeting/record/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 录播会议分享 GET /net_meeting/record/share_record */
export async function getNetMeetingRecordShareRecord(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingRecordShareRecordParams,
  options?: { [key: string]: any },
) {
  return request<API.NetMeetingRecordShareVo>('/net_meeting/record/share_record', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 手动同步录播会议 GET /net_meeting/record/sync_record */
export async function getNetMeetingRecordSyncRecord(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getNetMeetingRecordSyncRecordParams,
  options?: { [key: string]: any },
) {
  return request<boolean>('/net_meeting/record/sync_record', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
