// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 获取未完成下载数 GET /download_task/count */
export async function getDownloadTaskCount(options?: { [key: string]: any }) {
  return request<number>('/download_task/count', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 创建 创建任务后使用【查询任务信息】获取结果 POST /download_task/create */
export async function postDownloadTaskCreate(
  body: API.DownloadTaskCreateRequest,
  options?: { [key: string]: any },
) {
  return request<API.DownloadTaskNewVo>('/download_task/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询任务信息 GET /download_task/get */
export async function getDownloadTaskGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDownloadTaskGetParams,
  options?: { [key: string]: any },
) {
  return request<API.DownloadTaskNewVo>('/download_task/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 下载任务列表 GET /download_task/list */
export async function getDownloadTaskList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDownloadTaskListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABDownloadTaskVoC2BB>('/download_task/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
