import { Form, FormItemProps } from 'antd';
import Tree, { TreeProps } from './Tree';

type ProFormTreeProps = FormItemProps & {
  fieldProps?: TreeProps;
  request?: () => Promise<any>;
};
const ProFormTree = (props: ProFormTreeProps) => {
  const { request, fieldProps, ...formItemProps } = props;
  return (
    <Form.Item {...formItemProps}>
      <Tree {...fieldProps} request={request} />
    </Form.Item>
  );
};

export default ProFormTree;
