// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 获取登录二维码 POST /wechat/get_login_qr_code */
export async function postWechatGetLoginQrCode(options?: { [key: string]: any }) {
  return request<API.WechatLoginQrCodeVo>('/wechat/get_login_qr_code', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 微信小程序scheme码获取 GET /wechat/get_url_scheme */
export async function getWechatGetUrlScheme(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getWechatGetUrlSchemeParams,
  options?: { [key: string]: any },
) {
  return request<string>('/wechat/get_url_scheme', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 地址解析 GET /wechat/get_ws_address */
export async function getWechatGetWsAddress(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getWechatGetWsAddressParams,
  options?: { [key: string]: any },
) {
  return request<string[]>('/wechat/get_ws_address', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 微信JSSDK接口签名 GET /wechat/get_wx_jsapi_signature */
export async function getWechatGetWxJsapiSignature(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getWechatGetWxJsapiSignatureParams,
  options?: { [key: string]: any },
) {
  return request<API.WxJsapiSignature>('/wechat/get_wx_jsapi_signature', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 微信事件推送接口实现 GET /wechat/message */
export async function getWechatMessage(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/wechat/message', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 微信事件推送接口实现 GET /wechat/sign */
export async function getWechatSign(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/wechat/sign', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 微信扫码登录（轮询调用） POST /wechat/wechat_open_id_login */
export async function postWechatWechatOpenIdLogin(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postWechatWechatOpenIdLoginParams,
  options?: { [key: string]: any },
) {
  return request<API.WechatLoginResultVo>('/wechat/wechat_open_id_login', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
