import { history } from '@umijs/max';
import { useEffect } from 'react';

type RedirectProps = {
  to: string;
};
const Redirect = (props: RedirectProps) => {
  useEffect(() => {
    history.replace(props.to);
  }, []);
  return null;
};

export default Redirect;
