import Api from '@/services/api';
import { isDev } from '@/utils';
import { RightOutlined } from '@ant-design/icons';
import { history } from '@umijs/max';
import { useRequest } from 'ahooks';
import { Popover as AntPopover, Badge, Button, Empty, Skeleton } from 'antd';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { navigateRead } from '../../utils';

type PopoverProps = {
  children: React.ReactNode;
};

const Container = (props: { children: React.ReactNode }) => {
  return (
    <div>
      <div
        className={
          'flex flex-col gap-y-[10px] w-[300px] mx-[-12px] h-[250px] overflow-auto'
        }
      >
        {props.children}
      </div>
      <div className={'h-[1px] bg-[var(--ant-color-split)] my-[6px]'} />
      <Button
        block
        type={'text'}
        onClick={() => {
          history.push('/message');
        }}
      >
        查看更多
        <RightOutlined />
      </Button>
    </div>
  );
};
const Popover = (props: PopoverProps) => {
  const { data, loading, refresh } = useRequest(
    Api.xitongxiaoxi.getSystemMessageListByNotReady,
    {
      ready: !isDev,
    },
  );
  window.event$.useSubscription(({ type }) => {
    if (type === 'message@refresh') refresh();
  });
  const content = useMemo(() => {
    if (loading) {
      return (
        <Container>
          <div className={'px-[12px]'}>
            <Skeleton paragraph={{ rows: 5 }} />
          </div>
        </Container>
      );
    }
    if (isEmpty(data)) {
      return (
        <Container>
          <div className={'flex flex-1 items-center justify-center'}>
            <Empty />
          </div>
        </Container>
      );
    }
    return (
      <Container>
        {data?.map((item) => {
          return (
            <div
              key={item.id}
              className={
                'cursor-pointer hover:text-[var(--ant-color-primary)] px-[12px]'
              }
              onClick={async () => {
                await navigateRead(item);
              }}
            >
              <div className={'flex justify-between items-center'}>
                <Badge dot offset={[0, 6]} className={'text-[unset]'}>
                  <div>【{item.title}】</div>
                </Badge>
                <div>{item.create_time}</div>
              </div>
              <div
                className={
                  'overflow-hidden whitespace-nowrap overflow-ellipsis'
                }
              >
                {item.content}
              </div>
            </div>
          );
        })}
      </Container>
    );
  }, [data, loading]);
  return <AntPopover content={content}>{props.children}</AntPopover>;
};

export default Popover;
