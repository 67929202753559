// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 发送验证码 测试 POST /sms/send_sms */
export async function postSmsSendSms(
  body: API.SendMessageRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/sms/send_sms', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
