import {
  CheckedNodesType,
  SetCheckedNodesType,
} from '@/components/SelectMember/Selector';
import Api from '@/services/api';
import { TagFilled } from '@ant-design/icons';
import { Avatar, Button, Tree } from '@dyrl/web';
import { useRequest } from 'ahooks';
import { Checkbox, ConfigProvider, Skeleton } from 'antd';
import { isEmpty, map } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';

type TagsProps = {
  checkedNodes: CheckedNodesType[];
  setCheckedNodes: SetCheckedNodesType;
};

const Tags = (props: TagsProps) => {
  const { checkedNodes, setCheckedNodes } = props;
  const {
    data: treeData = [],
    loading,
    mutate: mutateTreeData,
  } = useRequest(async () => {
    const data =
      await Api.bumenchengyuanbiaoqianguanli.getDepartmentMemberTagPageList({});
    return data?.map((item: any) => {
      return {
        id: item.id,
        value: item.id,
        label: item.name,
        _original: item,
      };
    });
  });
  if (loading) {
    return (
      <div className={'p-[15px]'}>
        <Skeleton active paragraph={{ rows: 7 }} />
      </div>
    );
  }
  const checked = ({ id }: { id: string }) => {
    return checkedNodes.some((item) => item.id === id);
  };
  const onLoadData = async ({ _original }: any) => {
    const members = await Api.bumenchengyuanguanli
      .getDepartmentMemberListByDepartment({
        tag_ids: [_original.id],
      })
      .then((data) => {
        return data.map((item: any) => {
          return {
            value: uuidv4(),
            label: item.nickname,
            id: item.id,
            isLeaf: true,
            isMember: true,
            _original: item,
          };
        });
      });
    if (isEmpty(members)) return;
    mutateTreeData((treeData: any) => {
      return treeData?.map((item: any) => {
        if (item.id === _original.id) {
          return {
            ...item,
            children: members,
          };
        }
        return item;
      });
    });
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Tree: {
            titleHeight: 40,
          },
        },
      }}
    >
      <Tree
        checkable={false}
        selectable={false}
        treeData={treeData}
        loadData={onLoadData}
        checkedKeys={map(checkedNodes, 'value')}
        titleRender={(nodeData: any) => {
          if (nodeData.isMember) {
            return (
              <Checkbox
                checked={checked(nodeData)}
                onChange={(e) => {
                  setCheckedNodes([nodeData], e.target.checked);
                }}
              >
                <div className={'flex items-center gap-x-[5px]'}>
                  <Avatar size={30} src={nodeData._original.avatar_url}>
                    {nodeData.label}
                  </Avatar>
                  {nodeData.label}
                </div>
              </Checkbox>
            );
          }
          return (
            <>
              {nodeData.label}
              <div className={'absolute right-0 top-0'}>
                <Button
                  type={'link'}
                  onClick={async () => {
                    const members =
                      await Api.bumenchengyuanguanli.getDepartmentMemberListByDepartment(
                        {
                          tag_ids: nodeData.id,
                          load_children: 1,
                        },
                      );
                    const checkedNodes = members.map((item: any) => {
                      return {
                        value: uuidv4(),
                        label: item.nickname,
                        id: item.id,
                        isLeaf: true,
                        isMember: true,
                        _original: item,
                      };
                    });
                    setCheckedNodes(checkedNodes, true);
                  }}
                >
                  全选
                </Button>
              </div>
            </>
          );
        }}
        icon={({ isLeaf }: any) => {
          if (!isLeaf) {
            return (
              <div className={'icon-wrap'}>
                <TagFilled />
              </div>
            );
          }
        }}
      />
    </ConfigProvider>
  );
};

export default Tags;
