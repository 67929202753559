import { useInitialState } from '@/hooks';
import { RequestConfig } from '@umijs/max';
import { message } from 'antd';
import { set } from 'lodash-es';
import qs from 'qs';

const controller = new AbortController();

// 与后端约定的响应数据格式
interface ResponseStructure {
  data: any;
  code?: number;
  message?: string;
}

const request: RequestConfig = {
  baseURL: '/api',
  headers: {
    'x-platform': 'pc',
  },
  signal: controller.signal,
  paramsSerializer(params) {
    return qs.stringify(params, { arrayFormat: 'repeat' });
  },
  errorConfig: {
    errorThrower: (info: ResponseStructure) => {
      const error: any = new Error();
      error.name = 'BizError';
      error.info = info;
      throw error;
    },
    errorHandler: (error: any, opts: any) => {
      if (opts?.skipErrorHandler) throw error;
      const { data, status } = error?.response || {};
      const errorMessage = data?.message;
      if (status === 401) {
        useInitialState.getState().onLogout();
      }
      message.error(errorMessage || '网络异常，请稍后重试');
    },
  },
  requestInterceptors: [
    (url, options) => {
      const { token, orgId } = useInitialState.getState();
      if (token) {
        set(options, 'headers.Authorization', token);
      }
      if (orgId) {
        set(options, 'headers.X-ORG-ID', JSON.parse(`${orgId}`));
      }
      return { url, options };
    },
  ],
  responseInterceptors: [
    (response) => {
      if (response.status === 401) {
        useInitialState.getState().onLogout();
      } else if (response.status !== 200) {
        set(response, 'data.success', false);
      }
      return response;
    },
  ],
};
export default request;
