// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 我的申请列表 GET /work_process/apply_list */
export async function getWorkProcessApplyList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getWorkProcessApplyListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABWorkProcessVoC2BB>('/work_process/apply_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 流程审核 POST /work_process/audit */
export async function postWorkProcessAudit(
  body: API.WorkProcessAuditRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/work_process/audit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 取消流程 POST /work_process/cancel */
export async function postWorkProcessCancel(
  body: API.WorkProcessCancelRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/work_process/cancel', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 事务审批导出 GET /work_process/export_excel */
export async function getWorkProcessExportExcel(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getWorkProcessExportExcelParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/work_process/export_excel', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 事务审批获取可导出列 GET /work_process/export/columns */
export async function getWorkProcessOpenApiExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/work_process/export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 流程详情 GET /work_process/get */
export async function getWorkProcessGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getWorkProcessGetParams,
  options?: { [key: string]: any },
) {
  return request<API.WorkProcessDetailVo>('/work_process/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 待办事项 GET /work_process/getTodoTasks */
export async function getWorkProcessGetTodoTasks(options?: { [key: string]: any }) {
  return request<API.WorkProcessTodoTaskVo>('/work_process/getTodoTasks', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 我的流程列表 GET /work_process/list */
export async function getWorkProcessList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getWorkProcessListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABWorkProcessVoC2BB>('/work_process/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 事务审批导出（弃用） GET /work_process/oaExport */
export async function getWorkProcessOaExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getWorkProcessOaExportParams,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/work_process/oaExport', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 重新提交流程 POST /work_process/restart */
export async function postWorkProcessRestart(
  body: API.WorkProcessApplyRequest,
  options?: { [key: string]: any },
) {
  return request<API.WorkProcess>('/work_process/restart', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 开始流程 POST /work_process/start */
export async function postWorkProcessStart(
  body: API.WorkProcessApplyRequest,
  options?: { [key: string]: any },
) {
  return request<API.WorkProcess>('/work_process/start', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
