import Api from '@/services/api';
import { Avatar } from '@dyrl/web';
import { useRequest } from 'ahooks';
import { Select, SelectProps, Tag } from 'antd';
import classNames from 'classnames';
import { isEmpty } from 'lodash-es';
import React, { useState } from 'react';
import open from './open';

type SelectAddressBookProps = Omit<
  SelectProps,
  'options' | 'mode' | 'open' | 'tagRender' | 'onChange' | 'loading'
> & {
  title?: string;
  // 0:教育局、1:学校
  orgType?: ('0' | '1')[];
  multiple?: boolean;
  onChange?: (value: React.Key[], checkedNodes: any[]) => void;
};
const SelectMember = (props: SelectAddressBookProps) => {
  const {
    title: propsTitle,
    orgType: propsOrgType,
    multiple: propsMultiple,
    ...restProps
  } = props;
  const [options, setOptions] = useState();
  const { loading } = useRequest(
    () =>
      Api.bumenchengyuanguanli.getDepartmentMemberList({
        pageSize: props.value?.length,
        ids: props.value,
      } as any),
    {
      ready: !isEmpty(props.value),
      refreshDeps: [props.value],
      onSuccess: (data) => {
        const options = data?.data.map((item: any) => {
          return {
            value: item.id,
            label: item.nickname,
            _original: item,
          };
        });
        setOptions(options);
      },
    },
  );
  const tagRender = ({ label }: any) => {
    const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
      event.preventDefault();
      event.stopPropagation();
    };
    return (
      <Tag
        onMouseDown={onPreventMouseDown}
        className={classNames({
          'ant-select-selection-item': props.disabled,
        })}
      >
        <div className={'flex gap-x-[2px] '}>
          <Avatar size={20}>{label}</Avatar>
          {label}
        </div>
      </Tag>
    );
  };

  return (
    // @ts-ignore
    <Select
      placeholder={'请选择'}
      {...restProps}
      loading={loading}
      open={false}
      mode={'multiple'}
      options={options}
      tagRender={tagRender}
      onClick={() => {
        if (props.disabled) return;
        open({
          title: propsTitle,
          value: props.value,
          orgType: propsOrgType,
          multiple: propsMultiple,
          onConfirm: (checkedNodes) => {
            const { value } = checkedNodes.reduce(
              (result: any, item) => {
                result.value.push(item._original.id);
                return result;
              },
              { options: [], value: [] },
            );
            console.log('open', value);
            props.onChange?.(value, checkedNodes);
          },
        });
      }}
    />
  );
};

export default SelectMember;
