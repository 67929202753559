// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加公文附件 POST /official_document/file/add */
export async function postOfficialDocumentFileAdd(
  body: API.OfficialDocumentFileAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentFile>('/official_document/file/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除附件 POST /official_document/file/delete */
export async function postOfficialDocumentFileOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/official_document/file/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改公文附件 POST /official_document/file/edit */
export async function postOfficialDocumentFileEdit(
  body: API.OfficialDocumentFileEditRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentFile>('/official_document/file/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取公文的附件列表 GET /official_document/file/list_by_doc */
export async function getOfficialDocumentFileListByDoc(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentFileListByDocParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentFileVo[]>('/official_document/file/list_by_doc', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
