// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 日志详情 GET /admin/log/detail */
export async function getAdminLogDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminLogDetailParams,
  options?: { [key: string]: any },
) {
  return request<API.ElaticSearchLogDto>('/admin/log/detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 日志列表 GET /admin/log/page */
export async function getAdminLogPage(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminLogPageParams,
  options?: { [key: string]: any },
) {
  return request<API.ElaticSearchLogDto[]>('/admin/log/page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
