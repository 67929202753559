// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 新增图片 POST /admin/picture_library/add */
export async function postAdminPictureLibraryAdd(
  body: API.AdminPictureLibraryRequest,
  options?: { [key: string]: any },
) {
  return request<API.AdminPictureLibrary>('/admin/picture_library/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除图片 POST /admin/picture_library/delete */
export async function postAdminPictureLibraryOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/admin/picture_library/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 编辑图片 POST /admin/picture_library/edit */
export async function postAdminPictureLibraryEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postAdminPictureLibraryEditParams,
  body: API.AdminPictureLibraryRequest,
  options?: { [key: string]: any },
) {
  return request<API.AdminPictureLibrary>('/admin/picture_library/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 图片列表 GET /admin/picture_library/list */
export async function getAdminPictureLibraryList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminPictureLibraryListParams,
  options?: { [key: string]: any },
) {
  return request<API.AdminPictureLibrary[]>('/admin/picture_library/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
