// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加系统公告分类 POST /system/notice/category/add */
export async function postSystemNoticeCategoryAdd(
  body: API.SystemNoticeCategoryAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.SystemNoticeCategory>('/system/notice/category/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除系统公告分类 POST /system/notice/category/delete */
export async function postSystemNoticeCategoryOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/system/notice/category/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改系统公告分类 POST /system/notice/category/edit */
export async function postSystemNoticeCategoryEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postSystemNoticeCategoryEditParams,
  body: API.SystemNoticeCategoryAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.SystemNoticeCategory>('/system/notice/category/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 系统公告分类列表 GET /system/notice/category/list */
export async function getSystemNoticeCategoryList(options?: { [key: string]: any }) {
  return request<API.SystemNoticeCategory[]>('/system/notice/category/list', {
    method: 'GET',
    ...(options || {}),
  });
}
