// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加部门 POST /admin/department/add */
export async function postAdminDepartmentAdd(
  body: API.AdminDepartmentRequest,
  options?: { [key: string]: any },
) {
  return request<API.Department>('/admin/department/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除部门 POST /admin/department/delete */
export async function postAdminDepartmentOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/admin/department/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改部门 POST /admin/department/edit/${param0} */
export async function postAdminDepartmentEditId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postAdminDepartmentEditIdParams,
  body: API.AdminDepartmentRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.Department>(`/admin/department/edit/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 获取部门信息 GET /admin/department/get */
export async function getAdminDepartmentGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminDepartmentGetParams,
  options?: { [key: string]: any },
) {
  return request<API.Department>('/admin/department/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 当前组织的所有部门 GET /admin/department/list */
export async function getAdminDepartmentList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminDepartmentListParams,
  options?: { [key: string]: any },
) {
  return request<API.AdminDepartmentVo[]>('/admin/department/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据id获取部门 GET /admin/department/list_ids */
export async function getAdminDepartmentListIds(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminDepartmentListIdsParams,
  options?: { [key: string]: any },
) {
  return request<API.Department[]>('/admin/department/list_ids', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 修改部门 POST /department/${param0} */
export async function postDepartmentId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postDepartmentIdParams,
  body: API.DepartmentRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.Department>(`/department/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 添加部门 POST /department/add */
export async function postDepartmentAdd(
  body: API.DepartmentRequest,
  options?: { [key: string]: any },
) {
  return request<API.Department>('/department/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取指定code的下级 GET /department/children/by_code */
export async function getDepartmentChildrenByCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDepartmentChildrenByCodeParams,
  options?: { [key: string]: any },
) {
  return request<API.Department[]>('/department/children/by_code', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除部门 POST /department/delete */
export async function postDepartmentOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number[]>('/department/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 当前组织的直属组织部门架构 注意：不含自身组织的部门 GET /department/direct_list */
export async function getDepartmentDirectList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDepartmentDirectListParams,
  options?: { [key: string]: any },
) {
  return request<API.Department[]>('/department/direct_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取部门信息 GET /department/get */
export async function getDepartmentGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDepartmentGetParams,
  options?: { [key: string]: any },
) {
  return request<API.Department>('/department/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 当前组织的所有部门 GET /department/list */
export async function getDepartmentList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDepartmentListParams,
  options?: { [key: string]: any },
) {
  return request<API.DepartmentVo[]>('/department/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 批量code获取部门 GET /department/list_codes */
export async function getDepartmentListCodes(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDepartmentListCodesParams,
  options?: { [key: string]: any },
) {
  return request<API.Department[]>('/department/list_codes', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据id获取部门 GET /department/list_ids */
export async function getDepartmentListIds(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDepartmentListIdsParams,
  options?: { [key: string]: any },
) {
  return request<API.Department[]>('/department/list_ids', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
