import defaultAvatar from '@/assets/default-avatar.svg';
import { useInitialState } from '@/hooks';
import Api from '@/services/api';
import { CaretDownFilled } from '@ant-design/icons';
import { Avatar, UpdatePassword, message } from '@dyrl/web';
import { useRequest } from 'ahooks';
import { Dropdown, MenuProps } from 'antd';
import { compact } from 'lodash-es';
import { Userinfo } from './components';

const User = () => {
  const { currentUser, onLogout, orgId } = useInitialState();
  const { data } = useRequest(Api.yonghuguanli.getUserUserAuthOrg);
  const items: MenuProps['items'] = compact([
    {
      label: '账号信息',
      key: 'userinfo',
      onClick: Userinfo.open,
    },
    (data?.length ?? 0) > 1 && {
      label: '切换组织',
      key: 'switchingOrg',
      children: data?.map((item) => {
        return {
          key: `${item.org_id}`,
          label: item.org_name,
          onClick: () => {
            localStorage.setItem('orgId', `${item.org_id}`);
            window.location.replace(`/?t=${+new Date()}`);
          },
        };
      }),
    },
    {
      key: 'updatePassword',
      label: '修改密码',
      onClick: () => {
        UpdatePassword.open({
          mode: 'reset',
          onFinish: async (formData) => {
            await Api.yonghuzhongxin.postOauthUserEditPassword({
              new_password: formData.newPassword,
              old_password: formData.currentPassword,
            } as any);
            message.success('修改密码成功，请重新登录');
            setTimeout(() => {
              onLogout();
            }, 700);
          },
        });
      },
    },
    {
      key: 'logout',
      label: '退出登录',
      onClick: onLogout,
    },
  ]);
  return (
    <div>
      <Dropdown
        arrow
        placement="bottom"
        menu={{
          items,
          activeKey: `${orgId}`,
        }}
      >
        <div
          className={
            'flex align-middle gap-x-[10px] text-[#ffffffcc] hover:text-[#ffffff]'
          }
        >
          <Avatar
            size={'large'}
            src={currentUser?.avatarSrc || defaultAvatar}
          />
          <span>{currentUser?.nickname}</span>
          <CaretDownFilled />
        </div>
      </Dropdown>
    </div>
  );
};

export default User;
