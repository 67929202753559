import TweenOne from 'rc-tween-one';
import Children from 'rc-tween-one/lib/plugin/ChildrenPlugin';

TweenOne.plugins.push(Children);

interface Props {
  value?: number;
  className?: any;
  floatLength?: number;
}

// 数字滚动特效
const DigitalEffects = (props: Props) => {
  return (
    <TweenOne
      className={props.className}
      animation={{
        Children: {
          value: props.value,
          floatLength: props.floatLength ?? 0,
        },
        duration: 800,
      }}
      style={{
        fontSize: '34px',
        fontFamily: 'DIN1451Mittelschrift',
        display: 'inline-grid',
        color: '#2F343A',
      }}
    >
      0
    </TweenOne>
  );
};
export default DigitalEffects;
