// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 当前登录信息 GET /oauth/user/current_info */
export async function getOauthUserCurrentInfo(options?: { [key: string]: any }) {
  return request<API.OAuthCurrentInfoResultVo>('/oauth/user/current_info', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 修改登录密码 修改成功后token会失效，请通知用户并重定向到登录页 POST /oauth/user/edit_password */
export async function postOauthUserEditPassword(
  body: API.UserEditPasswordRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/oauth/user/edit_password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 账号密码登录 密码登录获取凭证 POST /oauth/user/login */
export async function postOauthUserLogin(
  body: API.UserLoginRequest,
  options?: { [key: string]: any },
) {
  return request<API.OAuthLoginVo>('/oauth/user/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 注销登录 注销当前设备登录状态 POST /oauth/user/logout */
export async function postOauthUserLogout(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/oauth/user/logout', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 重置密码 POST /oauth/user/reset_password */
export async function postOauthUserResetPassword(
  body: API.UserResetPasswordRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/oauth/user/reset_password', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 用户个人配置 GET /oauth/user/setting */
export async function getOauthUserSetting(options?: { [key: string]: any }) {
  return request<API.UserSettingValue>('/oauth/user/setting', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 修改用户个人配置 POST /oauth/user/setting */
export async function postOauthUserSetting(
  body: API.UserSettingValue,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/oauth/user/setting', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 验证码登录 POST /oauth/user/sms_login */
export async function postOauthUserSmsLogin(
  body: API.UserSmsLoginRequest,
  options?: { [key: string]: any },
) {
  return request<API.OAuthLoginVo>('/oauth/user/sms_login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取微信公众号code 回调接口：/oauth/user/wechat_mp_login?platform=0 GET /oauth/user/wechat_auth_page */
export async function getOauthUserWechatAuthPage(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOauthUserWechatAuthPageParams,
  options?: { [key: string]: any },
) {
  return request<string>('/oauth/user/wechat_auth_page', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 微信公众号授权登录 微信公众号授权登录回调接口。接口返回redirectLoginPage=true时需要引导用户登录，用户登录后就会绑定微信公众号openid POST /oauth/user/wechat_mp_login */
export async function postOauthUserWechatMpLogin(
  body: API.WechatMpLoginRequest,
  options?: { [key: string]: any },
) {
  return request<API.LoginResponse>('/oauth/user/wechat_mp_login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 当前登录家校信息 GET /school/oauth/current_info */
export async function getSchoolOauthCurrentInfo(options?: { [key: string]: any }) {
  return request<API.SchoolOAuthNewCurrentInfoVo>('/school/oauth/current_info', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 账号密码登录(学生/家长) POST /school/oauth/password_login */
export async function postSchoolOauthPasswordLogin(
  body: API.SchoolOAuthPasswordLoginRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolOAuthLoginVo>('/school/oauth/password_login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 绑定用户(学生/家长) POST /school/oauth/register_user */
export async function postSchoolOauthRegisterUser(
  body: API.SchoolOAuthRegisterUserRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolOAuthLoginVo>('/school/oauth/register_user', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 验证码登录(学生/家长) POST /school/oauth/sms_login */
export async function postSchoolOauthSmsLogin(
  body: API.SchoolOAuthSmsLoginRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolOAuthLoginVo>('/school/oauth/sms_login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
