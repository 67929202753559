import Api from '@/services/api';
import { Avatar } from '@dyrl/web';
import { useAsyncEffect, useDeepCompareEffect } from 'ahooks';
import { Select, SelectProps, Tag } from 'antd';
import { compact, isEmpty, map, unionBy } from 'lodash-es';
import { useState } from 'react';
import { TreeDataType } from './index';
import open from './open';

type SelectMemberProps = Omit<
  SelectProps,
  | 'options'
  | 'mode'
  | 'open'
  | 'tagRender'
  | 'onChange'
  | 'loading'
  | 'labelInValue'
> & {
  title?: string;
  value?: number[];
  onChange?: (value?: number[], checkedNodes?: any[]) => void;
};

const SelectMember = (props: SelectMemberProps) => {
  const {
    title: propsTitle,
    value: propsValue,
    onChange: propsOnChange,
    ...restProps
  } = props;
  const [loading, setLoading] = useState<boolean>(false);
  const [checkedNodes, setCheckedNodes] = useState<TreeDataType[]>([]);
  const [options, setOptions] = useState<SelectProps['options']>();
  useDeepCompareEffect(() => {
    const options = checkedNodes.map((item) => ({
      value: item.id,
      label: item.title,
    }));
    setOptions(options);
    propsOnChange?.(
      compact(map(unionBy(checkedNodes, 'id'), 'id')),
      checkedNodes,
    );
  }, [checkedNodes]);
  useAsyncEffect(async () => {
    if (isEmpty(propsValue)) return;
    setLoading(true);
    const { data } = await Api.bumenchengyuanguanli.getDepartmentMemberList({
      ids: propsValue,
      pageSize: propsValue.length,
    });
    const checkedNodes = data?.reduce(
      (
        previousValue: TreeDataType[],
        currentValue: {
          departments: any[];
          id: number | undefined;
          nickname: any;
          org_name: any;
          avatar_url: any;
        },
      ) => {
        currentValue.departments?.forEach((department) => {
          const parentId = department.department_id;
          previousValue.push({
            parentId,
            isLeaf: true,
            id: currentValue.id!,
            isMember: true,
            checkable: true,
            title: currentValue.nickname,
            orgName: currentValue.org_name,
            key: `${parentId}-${currentValue.id}`,
            avatarUrl: currentValue.avatar_url || undefined,
          });
        });
        return previousValue;
      },
      [],
    );
    setCheckedNodes(checkedNodes);
    setLoading(false);
  }, []);
  const tagRender: SelectProps['tagRender'] = (props) => {
    return (
      <Tag className={'ant-select-selection-item'}>
        <div className={'flex gap-x-[2px] '}>
          <Avatar size={20} className={'text-[14px]'}>
            {props.label as string}
          </Avatar>
          {props.label}
        </div>
      </Tag>
    );
  };
  return (
    <Select
      placeholder={'请选择'}
      {...restProps}
      open={false}
      options={options}
      loading={loading}
      mode={'multiple'}
      value={propsValue}
      tagRender={tagRender}
      onClick={() => {
        if (props.disabled) return;
        open({
          title: propsTitle,
          value: checkedNodes,
          onOk: (checkedNodes) => setCheckedNodes(checkedNodes),
        });
      }}
    />
  );
};

export default SelectMember;
