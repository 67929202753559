// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加 POST /admin/user/role/add */
export async function postAdminUserRoleAdd(
  body: API.AdminUserRoleAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.UserRole>('/admin/user/role/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 添加策略 POST /admin/user/role/add_policy */
export async function postAdminUserRoleAddPolicy(
  body: API.AdminUserRoleAddPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/admin/user/role/add_policy', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 添加用户 POST /admin/user/role/add_users */
export async function postAdminUserRoleAddUsers(
  body: API.AdminUserRoleAddUserRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/admin/user/role/add_users', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除角色 POST /admin/user/role/delete */
export async function postAdminUserRoleOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number[]>('/admin/user/role/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除策略 POST /admin/user/role/delete_policy */
export async function postAdminUserRoleDeletePolicy(
  body: API.AdminUserRoleAddPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/admin/user/role/delete_policy', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除用户 POST /admin/user/role/delete_users */
export async function postAdminUserRoleDeleteUsers(
  body: API.AdminUserRoleAddUserRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/admin/user/role/delete_users', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改角色信息 POST /admin/user/role/edit */
export async function postAdminUserRoleEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postAdminUserRoleEditParams,
  body: API.AdminUserRoleAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.UserRole>('/admin/user/role/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /admin/user/role/get */
export async function getAdminUserRoleGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminUserRoleGetParams,
  options?: { [key: string]: any },
) {
  return request<API.UserRole>('/admin/user/role/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 列表 GET /admin/user/role/list */
export async function getAdminUserRoleList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminUserRoleListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABAdminUserRoleVoC2BB>('/admin/user/role/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取策略 GET /admin/user/role/policy_list */
export async function getAdminUserRolePolicyList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminUserRolePolicyListParams,
  options?: { [key: string]: any },
) {
  return request<string[]>('/admin/user/role/policy_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 设置策略 POST /admin/user/role/set_policy */
export async function postAdminUserRoleSetPolicy(
  body: API.AdminUserRoleAddPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/admin/user/role/set_policy', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 设置用户 POST /admin/user/role/set_users */
export async function postAdminUserRoleSetUsers(
  body: API.AdminUserRoleAddUserRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/admin/user/role/set_users', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取用户 GET /admin/user/role/users */
export async function getAdminUserRoleUsers(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminUserRoleUsersParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/admin/user/role/users', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 修改角色信息 POST /user_role/${param0} */
export async function postUserRoleId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postUserRoleIdParams,
  body: API.UserRoleAddRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<Record<string, any>>(`/user_role/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 添加 POST /user_role/add */
export async function postUserRoleAdd(
  body: API.UserRoleAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.UserRole>('/user_role/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 添加策略 POST /user_role/add_policy */
export async function postUserRoleAddPolicy(
  body: API.UserRoleAddPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/user_role/add_policy', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 添加用户 POST /user_role/add_users */
export async function postUserRoleAddUsers(
  body: API.UserRoleAddUserRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/user_role/add_users', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除角色 POST /user_role/delete */
export async function postUserRoleOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/user_role/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除策略 POST /user_role/delete_policy */
export async function postUserRoleDeletePolicy(
  body: API.UserRoleAddPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/user_role/delete_policy', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除用户 POST /user_role/delete_users */
export async function postUserRoleDeleteUsers(
  body: API.UserRoleAddUserRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/user_role/delete_users', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /user_role/get */
export async function getUserRoleGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserRoleGetParams,
  options?: { [key: string]: any },
) {
  return request<API.UserRole>('/user_role/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 列表 GET /user_role/list */
export async function getUserRoleList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserRoleListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABUserRoleC2BB>('/user_role/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取策略 GET /user_role/policy_list */
export async function getUserRolePolicyList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserRolePolicyListParams,
  options?: { [key: string]: any },
) {
  return request<string[]>('/user_role/policy_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 设置策略 POST /user_role/set_policy */
export async function postUserRoleSetPolicy(
  body: API.UserRoleAddPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/user_role/set_policy', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 设置用户 POST /user_role/set_users */
export async function postUserRoleSetUsers(
  body: API.UserRoleAddUserRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/user_role/set_users', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取用户 GET /user_role/users */
export async function getUserRoleUsers(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getUserRoleUsersParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/user_role/users', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
