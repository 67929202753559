// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 按事件类型取消订阅 POST /hk_vision_event/cancel_subscription */
export async function postHkVisionEventCancelSubscription(
  body: number[],
  options?: { [key: string]: any },
) {
  return request<boolean>('/hk_vision_event/cancel_subscription', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 事件记录列表 GET /hk_vision_event/event_list */
export async function getHkVisionEventEventList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkVisionEventEventListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHkEventRecordVoC2BB>('/hk_vision_event/event_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 事件触发回调接口 （海康回调接口） 具体按事件类型分 POST /hk_vision_event/eventRcv */
export async function postHkVisionEventEventRcv(body: string, options?: { [key: string]: any }) {
  return request<Record<string, any>>('/hk_vision_event/eventRcv', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 按事件类型订阅事件 POST /hk_vision_event/subscription */
export async function postHkVisionEventSubscription(
  body: API.HkEventSubscriptionDto,
  options?: { [key: string]: any },
) {
  return request<boolean>('/hk_vision_event/subscription', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询事件订阅信息 GET /hk_vision_event/subscription_view */
export async function getHkVisionEventSubscriptionView(options?: { [key: string]: any }) {
  return request<API.HkEventSubscriptionViewVo[]>('/hk_vision_event/subscription_view', {
    method: 'GET',
    ...(options || {}),
  });
}
