// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 接收确认 POST /school/notice/accept/confirm */
export async function postSchoolNoticeAcceptConfirm(
  body: API.SchoolNoticeAcceptConfirmRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/school/notice/accept/confirm', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 统计汇总信息 GET /school/notice/all_counter */
export async function getSchoolNoticeAllCounter(options?: { [key: string]: any }) {
  return request<API.SchoolNoticeAllCounterVo>('/school/notice/all_counter', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 统计信息 GET /school/notice/counter */
export async function getSchoolNoticeCounter(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolNoticeCounterParams,
  options?: { [key: string]: any },
) {
  return request<API.SchoolNoticeCounterVo>('/school/notice/counter', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 统计（班级、部门）详细信息 GET /school/notice/counter_detail */
export async function getSchoolNoticeCounterDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolNoticeCounterDetailParams,
  options?: { [key: string]: any },
) {
  return request<API.SchoolNoticeAcceptPersonVo[]>('/school/notice/counter_detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除 POST /school/notice/delete */
export async function postSchoolNoticeOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/notice/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取可导出列 GET /school/notice/export/columns */
export async function getSchoolNoticeOpenApiExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/school/notice/export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 导出全部列表 GET /school/notice/export/list */
export async function getSchoolNoticeOpenApiExportList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolNotice_openAPI_exportListParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/school/notice/export/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 导出我创建的通知 GET /school/notice/export/my_list */
export async function getSchoolNoticeOpenApiExportMyList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolNotice_openAPI_exportMyListParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/school/notice/export/my_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 导出确认明细 GET /school/notice/export/xlsx */
export async function getSchoolNoticeOpenApiExportXlsx(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolNotice_openAPI_exportXlsxParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/school/notice/export/xlsx', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 详情 GET /school/notice/get */
export async function getSchoolNoticeGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolNoticeGetParams,
  options?: { [key: string]: any },
) {
  return request<API.SchoolNoticeDetailVo>('/school/notice/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 全部列表 GET /school/notice/list */
export async function getSchoolNoticeList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolNoticeListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolNoticeVoC2BB>('/school/notice/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 消息通知详情（我收到的） GET /school/notice/my_accept_detail */
export async function getSchoolNoticeMyAcceptDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolNoticeMyAcceptDetailParams,
  options?: { [key: string]: any },
) {
  return request<API.SchoolNoticeAcceptViewDetailVo>('/school/notice/my_accept_detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 我收到的列表（家长、成员） GET /school/notice/my_accept_list */
export async function getSchoolNoticeMyAcceptList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolNoticeMyAcceptListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolNoticeAcceptViewVoC2BB>('/school/notice/my_accept_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 我创建的 GET /school/notice/my_list */
export async function getSchoolNoticeMyList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolNoticeMyListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolNoticeVoC2BB>('/school/notice/my_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 发布 POST /school/notice/publish */
export async function postSchoolNoticePublish(
  body: API.SchoolNoticeAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolNotice>('/school/notice/publish', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 发送确认提醒 POST /school/notice/tip */
export async function postSchoolNoticeTip(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/school/notice/tip', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
