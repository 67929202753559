import Api from '@/services/api';
import { useLocation } from '@@/exports';
import { CloudDownloadOutlined } from '@ant-design/icons';
import { isDev } from '@hudiemon/utils';
import { history } from '@umijs/max';
import { useRequest } from 'ahooks';
import { Badge } from 'antd';
import classNames from 'classnames';

const Download = () => {
  const location = useLocation();
  const { data } = useRequest(Api.xiazairenwu.getDownloadTaskCount, {
    ready: !isDev,
    pollingInterval: 60000,
    pollingErrorRetryCount: 3,
  });
  return (
    <Badge count={data} size={'small'}>
      <CloudDownloadOutlined
        className={classNames([
          { 'opacity-100': location.pathname === '/download' },
        ])}
        onClick={() => history.push('/download')}
      />
    </Badge>
  );
};
export default Download;
