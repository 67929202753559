// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 概览统计 GET /admin/official_document/counter/overview */
export async function getAdminOfficialDocumentCounterOverview(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminOfficialDocumentCounterOverviewParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentCounterVo>('/admin/official_document/counter/overview', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 概览统计 GET /official_document/counter/overview */
export async function getOfficialDocumentCounterOverview(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentCounterOverviewParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentCounterVo>('/official_document/counter/overview', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 状态数统计 GET /official_document/counter/status_counter */
export async function getOfficialDocumentCounterStatusCounter(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentCounterStatusCounterParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentStatusCounterVo>('/official_document/counter/status_counter', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
