// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 详情 GET /system/area/${param0} */
export async function getSystemAreaId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemAreaIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.Area>(`/system/area/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 修改 POST /system/area/${param0} */
export async function postSystemAreaId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postSystemAreaIdParams,
  body: API.AreaRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.Area>(`/system/area/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 添加 POST /system/area/add */
export async function postSystemAreaAdd(body: API.AreaRequest, options?: { [key: string]: any }) {
  return request<API.Area>('/system/area/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /system/area/delete */
export async function postSystemAreaOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/system/area/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /system/area/list */
export async function getSystemAreaList(options?: { [key: string]: any }) {
  return request<API.SPageC2ABAreaC2BB>('/system/area/list', {
    method: 'GET',
    ...(options || {}),
  });
}
