// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 监控列表（所有同步下来的列表） GET /hk_camera/all_list */
export async function getHkCameraAllList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkCameraAllListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHkCameraVoC2BB>('/hk_camera/all_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 监控回放记录列表 GET /hk_camera/camera_back_list */
export async function getHkCameraCameraBackList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkCameraCameraBackListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHkCameraBackRecordVoC2BB>('/hk_camera/camera_back_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 手动抓图 POST /hk_camera/camera_manual_capture */
export async function postHkCameraCameraManualCapture(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postHkCameraCameraManualCaptureParams,
  options?: { [key: string]: any },
) {
  return request<string>('/hk_camera/camera_manual_capture', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 开始/停止 手动录像 POST /hk_camera/camera_manual_record */
export async function postHkCameraCameraManualRecord(
  body: API.HkCameraManualRecordDto,
  options?: { [key: string]: any },
) {
  return request<boolean>('/hk_camera/camera_manual_record', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 未分类监控点列表 GET /hk_camera/can_use_list */
export async function getHkCameraCanUseList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkCameraCanUseListParams,
  options?: { [key: string]: any },
) {
  return request<API.HkCamera[]>('/hk_camera/can_use_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 云台操作监控点 POST /hk_camera/controlling_camera */
export async function postHkCameraControllingCamera(
  body: API.HkCameraOperationDto,
  options?: { [key: string]: any },
) {
  return request<boolean>('/hk_camera/controlling_camera', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除监控点 POST /hk_camera/delete */
export async function postHkCameraOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/hk_camera/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 监控详情 GET /hk_camera/detail */
export async function getHkCameraDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkCameraDetailParams,
  options?: { [key: string]: any },
) {
  return request<API.HkCameraVo>('/hk_camera/detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 导出 GET /hk_camera/export */
export async function getHkCameraOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkCamera_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/hk_camera/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取可导出列 GET /hk_camera/export/columns */
export async function getHkCameraOpenApiExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/hk_camera/export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 根据分类id查询监控点 GET /hk_camera/get_by_category */
export async function getHkCameraGetByCategory(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkCameraGetByCategoryParams,
  options?: { [key: string]: any },
) {
  return request<API.HkCameraVo[]>('/hk_camera/get_by_category', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据区域获取监控点列表 GET /hk_camera/get_camera_by_region */
export async function getHkCameraGetCameraByRegion(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkCameraGetCameraByRegionParams,
  options?: { [key: string]: any },
) {
  return request<API.HkCameraVo[]>('/hk_camera/get_camera_by_region', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 可选学校列表 GET /hk_camera/get_school */
export async function getHkCameraGetSchool(options?: { [key: string]: any }) {
  return request<API.Department[]>('/hk_camera/get_school', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 监控列表 GET /hk_camera/list */
export async function getHkCameraList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkCameraListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHkCameraVoC2BB>('/hk_camera/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 视频图片查询 GET /hk_camera/picture_info_list */
export async function getHkCameraPictureInfoList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkCameraPictureInfoListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHkPictureInfoVoC2BB>('/hk_camera/picture_info_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 监控统计 GET /hk_camera/statistics */
export async function getHkCameraStatistics(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkCameraStatisticsParams,
  options?: { [key: string]: any },
) {
  return request<API.HkCameraStatisticsVo>('/hk_camera/statistics', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 同步监控点 GET /hk_camera/syn_camera */
export async function getHkCameraSynCamera(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/hk_camera/syn_camera', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 修改监控点名称 POST /hk_camera/update_name */
export async function postHkCameraUpdateName(
  body: API.CameraUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<API.HkCamera>('/hk_camera/update_name', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
