// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 获取公文pdf GET /v2/official_document/sign/pdf */
export async function getV2OfficialDocumentSignPdf(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getV2OfficialDocumentSignPdfParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentV2ResourceVo>('/v2/official_document/sign/pdf', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 预生成签署文件，获取签署链接 POST /v2/official_document/sign/pre_confirm */
export async function postV2OfficialDocumentSignPreConfirm(
  body: API.OfficialDocumentV2PreRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentV2ResultUrlVo>('/v2/official_document/sign/pre_confirm', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 查询签署结果 GET /v2/official_document/sign/query_result */
export async function getV2OfficialDocumentSignQueryResult(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getV2OfficialDocumentSignQueryResultParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentV2SignConfirmResultVo>(
    '/v2/official_document/sign/query_result',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}
