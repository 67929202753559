import { Button as InternalButton } from '@dyrl/web';
import createTask from './createTask';
import { TaskButtonProps } from './index';

const Button = (props: TaskButtonProps) => {
  const { request, ...restProps } = props;
  return <InternalButton {...restProps} onClick={() => createTask(request)} />;
};

export default Button;
