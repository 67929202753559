// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 获取登录凭证 GET /third_app/oauth/get_auth_user */
export async function getThirdAppOauthGetAuthUser(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getThirdAppOauthGetAuthUserParams,
  options?: { [key: string]: any },
) {
  return request<API.ThirdAppOAuthVo>('/third_app/oauth/get_auth_user', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取应用授权码 GET /third_app/oauth/sso/get_auth_code */
export async function getThirdAppOauthSsoGetAuthCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getThirdAppOauthSsoGetAuthCodeParams,
  options?: { [key: string]: any },
) {
  return request<API.ThirdAppSSOAuthCodeVo>('/third_app/oauth/sso/get_auth_code', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 登录应用 GET /third_app/oauth/sso/login */
export async function getThirdAppOauthSsoLogin(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getThirdAppOauthSsoLoginParams,
  options?: { [key: string]: any },
) {
  return request<API.ThirdAppGoVo>('/third_app/oauth/sso/login', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
