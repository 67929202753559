// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 我收到的 GET /system/notice/accept_list */
export async function getSystemNoticeAcceptList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemNoticeAcceptListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSystemNoticeVoC2BB>('/system/notice/accept_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除公告 POST /system/notice/delete */
export async function postSystemNoticeOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/system/notice/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 导出我收到的公告 GET /system/notice/export/accept_list */
export async function getSystemNoticeOpenApiExportAcceptList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemNotice_openAPI_exportAcceptListParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/system/notice/export/accept_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取可导出列 GET /system/notice/export/columns */
export async function getSystemNoticeOpenApiExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/system/notice/export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 导出全部公告 GET /system/notice/export/list */
export async function getSystemNoticeOpenApiExportList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemNotice_openAPI_exportListParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/system/notice/export/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 导出我创建的公告 GET /system/notice/export/my_list */
export async function getSystemNoticeOpenApiExportMyList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemNotice_openAPI_exportMyListParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/system/notice/export/my_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 详情 GET /system/notice/get */
export async function getSystemNoticeGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemNoticeGetParams,
  options?: { [key: string]: any },
) {
  return request<API.SystemNoticeVo>('/system/notice/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取公告头部数据 GET /system/notice/head_data */
export async function getSystemNoticeHeadData(options?: { [key: string]: any }) {
  return request<API.SystemNoticeHeadData>('/system/notice/head_data', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 全部公告 GET /system/notice/list */
export async function getSystemNoticeList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemNoticeListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSystemNoticeVoC2BB>('/system/notice/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 我创建的 GET /system/notice/my_list */
export async function getSystemNoticeMyList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemNoticeMyListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSystemNoticeVoC2BB>('/system/notice/my_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 发布公告 POST /system/notice/publish */
export async function postSystemNoticePublish(
  body: API.SystemNoticePublishRequest,
  options?: { [key: string]: any },
) {
  return request<API.SystemNotice>('/system/notice/publish', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
