// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加 POST /official_document/archive/v2/add */
export async function postOfficialDocumentArchiveV2Add(
  body: API.OfficialDocumentV2ArchiveRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentArchiveV2>('/official_document/archive/v2/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /official_document/archive/v2/get */
export async function getOfficialDocumentArchiveV2Get(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentArchiveV2GetParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentArchiveV2Vo>('/official_document/archive/v2/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 列表 导出使用 module=official_document.archive GET /official_document/archive/v2/list */
export async function getOfficialDocumentArchiveV2List(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentArchiveV2ListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABOfficialDocumentArchiveV2VoC2BB>(
    '/official_document/archive/v2/list',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 迁移旧数据到新版 POST /official_document/archive/v2/migrate */
export async function postOfficialDocumentArchiveV2Migrate(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/official_document/archive/v2/migrate', {
    method: 'POST',
    ...(options || {}),
  });
}
