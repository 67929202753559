// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 新增分类 POST /form_report/category/add */
export async function postFormReportCategoryAdd(
  body: API.FormReportCategoryAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.FormReportCategory>('/form_report/category/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 批量删除分类 POST /form_report/category/delete */
export async function postFormReportCategoryOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/form_report/category/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改分类 POST /form_report/category/edit */
export async function postFormReportCategoryEdit(
  body: API.FormReportCategoryEditRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/form_report/category/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 分类列表 GET /form_report/category/list */
export async function getFormReportCategoryList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportCategoryListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABFormReportCategoryVoC2BB>('/form_report/category/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 添加 POST /school/notice/category/add */
export async function postSchoolNoticeCategoryAdd(
  body: API.SchoolNoticeCategoryAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolNoticeCategory>('/school/notice/category/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /school/notice/category/delete */
export async function postSchoolNoticeCategoryOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/notice/category/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /school/notice/category/edit */
export async function postSchoolNoticeCategoryEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postSchoolNoticeCategoryEditParams,
  body: API.SchoolNoticeCategoryAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolNoticeCategory>('/school/notice/category/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /school/notice/category/list */
export async function getSchoolNoticeCategoryList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolNoticeCategoryListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolNoticeCategoryVoC2BB>('/school/notice/category/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
