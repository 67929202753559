import Api from '@/services/api';
import { isDev } from '@/utils';
import { BellFilled } from '@ant-design/icons';
import { history, useLocation } from '@umijs/max';
import { useRequest } from 'ahooks';
import { Badge } from 'antd';
import classNames from 'classnames';
import Popover from './Popover';
const Message = () => {
  const location = useLocation();
  const { data = 0, refresh } = useRequest(
    Api.xitongxiaoxi.getSystemMessageCountByNotReady,
    {
      ready: !isDev,
      pollingInterval: 60000,
      pollingErrorRetryCount: 3,
    },
  );
  window.event$.useSubscription(({ type }) => {
    if (type === 'message@refresh') refresh();
  });
  return (
    <Popover>
      <Badge size={'small'} count={data}>
        <BellFilled
          className={classNames([
            { 'opacity-100': location.pathname === '/message' },
          ])}
          onClick={() => {
            history.push('/message');
          }}
        />
      </Badge>
    </Popover>
  );
};

export default Message;
