// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 操作日志删除 POST /video/operate_log/delete */
export async function postVideoOperateLogOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/video/operate_log/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 操作日志列表 GET /video/operate_log/list */
export async function getVideoOperateLogList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getVideoOperateLogListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHkCameraOperateLogVoC2BB>('/video/operate_log/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 任务事件类型新增 （废弃） POST /video/video_task_type/add */
export async function postVideoVideoTaskTypeAdd(
  body: API.HkVideoTaskTypeAddDto,
  options?: { [key: string]: any },
) {
  return request<API.HkVideoTaskType>('/video/video_task_type/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 任务事件类型删除 （废弃） POST /video/video_task_type/delete */
export async function postVideoVideoTaskTypeOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/video/video_task_type/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 任务事件类型列表 （废弃） GET /video/video_task_type/list */
export async function getVideoVideoTaskTypeList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getVideoVideoTaskTypeListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHkVideoTaskTypeVoC2BB>('/video/video_task_type/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 新建任务事件 （废弃） POST /video/video_task/add */
export async function postVideoVideoTaskAdd(
  body: API.HkVideoTaskAddDto,
  options?: { [key: string]: any },
) {
  return request<API.HkVideoTask>('/video/video_task/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除任务事件 （废弃） POST /video/video_task/delete */
export async function postVideoVideoTaskOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/video/video_task/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 任务事件详情 （废弃） GET /video/video_task/detail/${param0} */
export async function getVideoVideoTaskDetailId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getVideoVideoTaskDetailIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<Record<string, any>>(`/video/video_task/detail/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 任务事件列表 （废弃） GET /video/video_task/list */
export async function getVideoVideoTaskList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getVideoVideoTaskListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHkVideoTaskVoC2BB>('/video/video_task/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
