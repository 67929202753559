import { ConfigProvider } from '@/components';
import Api from '@/services/api';
import {
  AliyunOSSUpload,
  ConfigProvider as DyrlConfigProvider,
  message,
} from '@dyrl/web';

message.config({
  duration: 0.8,
  maxCount: 1,
});

DyrlConfigProvider.useStore.setState({
  ConfigProvider: ConfigProvider,
});

AliyunOSSUpload.useStore.setState({
  requestFile: Api.wenjian.postFileGetFileId,
  requestOptions: Api.wenjian.postFileObtainStsTokenInfo,
});
