import Api from '@/services/api';
import { history } from '@umijs/max';
import qs from 'qs';

function handleAuditProcess(record: any) {
  const module = new URL(
    window.origin.concat(record?.extend?.url),
  ).searchParams.get('module');
  if (module === 'leave') {
    // 请假
    history.push('/oa/approval/leave');
  } else if (module === 'business_trip') {
    // 出差
    history.push('/oa/approval/businessTrip');
  } else if (module === 'seal') {
    // 用章申请
    history.push('/oa/approval/seal');
  } else if (module === 'item_repair') {
    // 物品报修
    history.push('/oa/approval/maintenance');
  } else if (module === 'item_take') {
    // 物品领用
    history.push('/oa/approval/goodsIssued');
  }
}

export const navigateRead = async (record: any) => {
  if (record.type.includes('official_document')) {
    // 公文通知
    history.push('/oa/docFlow/manage');
  } else if (record.type === 'meeting_plan') {
    // 会议通知
    history.push('/oa/meeting/arrange');
  } else if (record.type === 'system_notice') {
    history.push('/oa/notify/announcement');
  } else if (record.type === 'school_notice') {
    history.push('/oa/notify/notice');
  } else if (record.type === 'audit_process') {
    handleAuditProcess(record);
  } else if (/net_meeting/i.test(record.type)) {
    const query = record.extend.url.split('?')[1];
    const params = qs.parse(query);
    history.push(`/videoconference?activeKey=2&code=${params.id}`);
  } else if (record.type === 'form_report') {
    history.push('/oa/report');
  }
  if (!record.is_ready) {
    await Api.xitongxiaoxi.postSystemMessageSetReady({
      ids: [record.id],
    });
    window.event$.emit({ type: 'message@refresh' });
  }
};
