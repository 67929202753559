import { localStorage } from '@hudiemon/utils';
import { create } from 'zustand';
import fetchDictionary from './fetchDictionary';
import fetchUserInfo from './fetchUserInfo';

export type OrgIdType = number;

export type OrgTypeType = API.OAuthCurrentOrgVo['type'];

type CurrentUserType = {
  userId?: number;
  memberId?: number;
  orgType?: OrgTypeType;
  orgCode?: string;
  orgName?: string;
  orgId?: number;
  avatarSrc?: string;
  nickname?: string;
  access: Record<string, boolean>;
  departments: any[];
  systemRole: string;
  loginRole: string;
};

type InitialStateType = {
  token?: string;
  orgId?: OrgIdType;
  orgName?: string;
  orgType?: 0 | 1 | 2;
  dictionary?: any;
  roles?: any[];
  access: Record<string, boolean>;
  currentUser?: CurrentUserType | any;
  fetchUserInfo: () => Promise<undefined | any>;
  fetchDictionary: () => Promise<any | undefined>;
  fetchInitialState: () => Promise<any | undefined>;
  onLogout: () => void;
};

const useInitialState = create<InitialStateType>((setState, getState) => {
  return {
    access: {},
    token: localStorage.getItem('token'),
    orgId: localStorage.getItem('orgId'),
    fetchUserInfo: () => fetchUserInfo(setState, getState),
    fetchDictionary: () => fetchDictionary(setState),
    fetchInitialState: async () => {
      const {
        access,
        currentUser,
        fetchUserInfo,
        fetchDictionary,
        dictionary,
      } = getState();
      if (!currentUser || !dictionary) {
        const userInfo = await fetchUserInfo();
        const dictionary = await fetchDictionary();
        return {
          ...userInfo,
          dictionary,
        };
      }
      return {
        access,
        currentUser,
      };
    },
    onLogout: () => {
      localStorage.removeItem('token');
      localStorage.removeItem('orgId');
      localStorage.removeItem('passwordExpire');
      window.location.replace('/login');
    },
  };
});
export default useInitialState;
