import Api from '@/services/api';
import { Avatar, Icon, Tree } from '@dyrl/web';
import { toTreeData } from '@hudiemon/utils';
import { useRequest } from 'ahooks';
import { Checkbox, ConfigProvider, Skeleton } from 'antd';
import { concat, isEmpty, map } from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { CheckedNodesType, SetCheckedNodesType } from './Selector';

type TreeProps = {
  multiple?: boolean;
  params: API.getDepartmentListParams;
  checkedNodes: CheckedNodesType[];
  setCheckedNodes: SetCheckedNodesType;
};
const DepartmentTree = (props: TreeProps) => {
  const { checkedNodes, setCheckedNodes } = props;
  const {
    data: treeData = [],
    loading,
    mutate: mutateTreeData,
  } = useRequest(async () => {
    const data = await Api.bumenguanli.getDepartmentList(props.params);
    return toTreeData(data, {
      label: 'name',
      value: 'object_code',
      parentId: 'parent_id',
    });
  });
  const onLoadData = async ({ _original }: any) => {
    const { id, object_code } = _original;
    const members = await Api.bumenchengyuanguanli
      .getDepartmentMemberListByDepartment({
        department_id: id,
      })
      .then((data) => {
        return data.map((item: any) => {
          return {
            value: uuidv4(),
            label: item.nickname,
            id: item.id,
            isLeaf: true,
            isMember: true,
            _original: item,
          };
        });
      });
    if (isEmpty(members)) return;
    mutateTreeData((treeData) => {
      const loop = (data: any) => {
        return data?.map((item: any) => {
          if (item.value === object_code) {
            return {
              ...item,
              children: concat(item.children ?? [], members),
            };
          } else if (!isEmpty(item.children)) {
            return {
              ...item,
              children: loop(item.children),
            };
          }
          return item;
        });
      };
      return loop(treeData);
    });
  };
  if (loading) {
    return (
      <div className={'p-[15px]'}>
        <Skeleton active paragraph={{ rows: 7 }} />
      </div>
    );
  }
  const checked = ({ id }: { id: string }) => {
    return checkedNodes.some((item) => item.id === id);
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Tree: {
            titleHeight: 40,
          },
        },
      }}
    >
      <Tree
        checkable={false}
        // @ts-ignore
        treeData={treeData}
        loadData={onLoadData}
        titleRender={(nodeData: any) => {
          if (nodeData.isMember) {
            return (
              <Checkbox
                checked={checked(nodeData)}
                onChange={(e) => {
                  if (props.multiple === false) {
                    setCheckedNodes([nodeData]);
                  } else {
                    setCheckedNodes([nodeData], e.target.checked);
                  }
                }}
              >
                <div className={'flex items-center gap-x-[5px]'}>
                  <Avatar size={30} src={nodeData._original.avatar_url}>
                    {nodeData.label}
                  </Avatar>
                  {nodeData.label}
                </div>
              </Checkbox>
            );
          }
          return (
            <>
              {nodeData.label}
              {/*<div className={'absolute right-0 top-0'}>*/}
              {/*  <Button*/}
              {/*    type={'link'}*/}
              {/*    onClick={async () => {*/}
              {/*      const { id } = nodeData._original;*/}
              {/*      const members =*/}
              {/*        await Api.bumenchengyuanguanli.getDepartmentMemberListByDepartment(*/}
              {/*          {*/}
              {/*            department_id: id,*/}
              {/*            load_children: 1,*/}
              {/*          },*/}
              {/*        );*/}
              {/*      const checkedNodes = members.map((item: any) => {*/}
              {/*        return {*/}
              {/*          value: uuidv4(),*/}
              {/*          label: item.nickname,*/}
              {/*          id: item.id,*/}
              {/*          isLeaf: true,*/}
              {/*          isMember: true,*/}
              {/*          _original: item,*/}
              {/*        };*/}
              {/*      });*/}
              {/*      setCheckedNodes(checkedNodes, true);*/}
              {/*    }}*/}
              {/*  >*/}
              {/*    全选*/}
              {/*  </Button>*/}
              {/*</div>*/}
            </>
          );
        }}
        checkedKeys={map(checkedNodes, 'value')}
        onCheck={(_, { checked, node }) => {
          // @ts-ignore
          setCheckedNodes([node], checked);
        }}
        icon={({ _original }: any) => {
          if (_original.type === 0) {
            return (
              <div className={'icon-wrap'}>
                <Icon type={'icon-EducationBureau'} />
              </div>
            );
          } else if (_original.type === 1) {
            return (
              <div className={'icon-wrap'}>
                <Icon type={'icon-School'} />
              </div>
            );
          } else if (_original.type === 7) {
            return <Icon type={'icon-fenxiao'} />;
          } else if (
            _original.type === 3 ||
            _original.type === 4 ||
            _original.type === 5
          ) {
            return <Icon type={'icon-classGrade'} />;
          } else if (_original.type === 2 || _original.type === 6) {
            return <Icon type={'icon-bumen'} />;
          }
        }}
      />
    </ConfigProvider>
  );
};

export default DepartmentTree;
