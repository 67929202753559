// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 测试队列 POST /official_document/sms_pool/push */
export async function postOfficialDocumentSmsPoolPush(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/official_document/sms_pool/push', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 开启短信发送 POST /official_document/sms_pool/start */
export async function postOfficialDocumentSmsPoolStart(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/official_document/sms_pool/start', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 获取短信发送开关 GET /official_document/sms_pool/status */
export async function getOfficialDocumentSmsPoolStatus(options?: { [key: string]: any }) {
  return request<boolean>('/official_document/sms_pool/status', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 关闭短信发送 POST /official_document/sms_pool/stop */
export async function postOfficialDocumentSmsPoolStop(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/official_document/sms_pool/stop', {
    method: 'POST',
    ...(options || {}),
  });
}
