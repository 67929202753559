import Api from '@/services/api';
import { Icon } from '@dyrl/web';
import { useRequest } from 'ahooks';
import { Skeleton, Spin, Tree } from 'antd';
import { map } from 'lodash-es';
import { useState } from 'react';
import { useCheck, useLoadData, useTreeData } from './hooks';
import { SetCheckedNodes, TreeDataType } from './index';
import { titleRender } from './utils';

type MemberProps = {
  /**
   * 0：教育局；1：学校；
   */
  type: '0' | '1';
  checkedNodes: TreeDataType[];
  setCheckedNodes: SetCheckedNodes;
};

const MemberTree = (props: MemberProps) => {
  const { checkedNodes, setCheckedNodes } = props;
  const [loadedKeys, setLoadedKeys] = useState<string[]>([]);
  const {
    data = [],
    mutate,
    loading,
  } = useRequest<TreeDataType[], any>(async () => {
    const data = await Api.bumenguanli.getDepartmentList({
      type: [props.type],
    });
    return data.map((item) => ({
      id: item.id!,
      isLeaf: false,
      checkable: true,
      type: item.type,
      title: item.name,
      key: item.object_code,
      parentId: item.parent_id,
    }));
  });
  const treeData = useTreeData({ loadedKeys, data });
  const loadData = useLoadData({
    mutate,
    checkedNodes,
    setLoadedKeys,
    setCheckedNodes,
    type: props.type,
  });
  const { onCheck, loading: checkLoading } = useCheck({
    data,
    mutate,
    loadedKeys,
    setLoadedKeys,
    setCheckedNodes,
    type: props.type,
  });

  return (
    <Skeleton
      active
      loading={loading}
      className={'p-[15px]'}
      paragraph={{ rows: 7 }}
    >
      <Spin spinning={checkLoading}>
        <Tree<TreeDataType>
          showIcon
          blockNode
          checkable
          onCheck={onCheck}
          selectable={false}
          treeData={treeData}
          loadData={loadData}
          loadedKeys={loadedKeys}
          titleRender={titleRender}
          checkedKeys={map(checkedNodes, 'key')}
          icon={(nodeProps: any) => {
            if (nodeProps.type === 0) {
              return (
                <div className={'icon-wrap'}>
                  <Icon type={'icon-EducationBureau'} />
                </div>
              );
            }
            if (nodeProps.type === 1) {
              return (
                <div className={'icon-wrap'}>
                  <Icon type={'icon-School'} />
                </div>
              );
            }
            if (nodeProps.type === 2 || nodeProps.type === 6) {
              return <Icon type={'icon-bumen'} />;
            }
            if (nodeProps.type === 7) {
              return <Icon type={'icon-fenxiao'} />;
            }
            return null;
          }}
        />
      </Spin>
    </Skeleton>
  );
};

export default MemberTree;
