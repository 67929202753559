// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 我的未读消息数 GET /system/message/count/by_not_ready */
export async function getSystemMessageCountByNotReady(options?: { [key: string]: any }) {
  return request<number>('/system/message/count/by_not_ready', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 删除消息 POST /system/message/delete */
export async function postSystemMessageOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/system/message/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 消息详情 GET /system/message/get */
export async function getSystemMessageGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemMessageGetParams,
  options?: { [key: string]: any },
) {
  return request<API.MessageVo>('/system/message/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 全部消息列表 GET /system/message/list */
export async function getSystemMessageList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemMessageListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABMessageVoC2BB>('/system/message/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 我的未读消息列表 GET /system/message/list/by_not_ready */
export async function getSystemMessageListByNotReady(options?: { [key: string]: any }) {
  return request<API.MessageVo[]>('/system/message/list/by_not_ready', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 设置消息已读 POST /system/message/set_ready */
export async function postSystemMessageSetReady(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/system/message/set_ready', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
