// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 教师列表 GET /school/class/list_teacher */
export async function getSchoolOpenApiClassListTeacher(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchool_openAPI_classListTeacherParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolTeacherVoC2BB>('/school/class/list_teacher', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 设置年级、班级、任课老师负责人 POST /school/class/set_class_relation */
export async function postSchoolOpenApiClassSetClassRelation(
  body: API.SchoolClassRelationAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolTeacher[]>('/school/class/set_class_relation', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 负责人列表 GET /school/class/view_class */
export async function getSchoolOpenApiClassViewClass(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchool_openAPI_classViewClassParams,
  options?: { [key: string]: any },
) {
  return request<API.SchoolTeacherRelationVo[]>('/school/class/view_class', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 显示班级负责人 POST /school/class/view_class/${param0} */
export async function postSchoolOpenApiClassViewClassObjectCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postSchool_openAPI_classViewClassObjectCodeParams,
  options?: { [key: string]: any },
) {
  const { object_code: param0, ...queryParams } = params;
  return request<API.SchoolTeacherRelation[]>(`/school/class/view_class/${param0}`, {
    method: 'POST',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 快捷添加班级 POST /telbook/add_class */
export async function postTelbookAddClass(
  body: API.SchoolAddClassRequest,
  options?: { [key: string]: any },
) {
  return request<API.Department>('/telbook/add_class', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 快捷添加年级 POST /telbook/add_grade */
export async function postTelbookAddGrade(
  body: API.SchoolAddGradeRequest,
  options?: { [key: string]: any },
) {
  return request<API.Department>('/telbook/add_grade', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 快捷添加学段 POST /telbook/add_level */
export async function postTelbookAddLevel(
  body: API.SchoolAddLevelRequest,
  options?: { [key: string]: any },
) {
  return request<API.Department>('/telbook/add_level', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 快捷添加学校校区 POST /telbook/add_school */
export async function postTelbookAddSchool(
  body: API.SchoolFastAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.Department>('/telbook/add_school', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 快捷添加学生 POST /telbook/add_student */
export async function postTelbookAddStudent(
  body: API.SchoolFastAddStudentRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolStudent>('/telbook/add_student', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 快捷添加家长 POST /telbook/add_student_parent */
export async function postTelbookAddStudentParent(
  body: API.SchoolFastAddStudentParentRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolStudentParent>('/telbook/add_student_parent', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 家校通讯录导出 GET /telbook/export */
export async function getTelbookOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getTelbook_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/telbook/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取可导出列 GET /telbook/export/columns */
export async function getTelbookOpenApiExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/telbook/export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 家校通讯录导入 POST /telbook/import */
export async function postTelbookOpenApiImport(
  body: {},
  file?: File,
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<string>('/telbook/import', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 导入模板下载 GET /telbook/import_template */
export async function getTelbookImportTemplate(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/telbook/import_template', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 系统内置学段列表 GET /telbook/level_list */
export async function getTelbookLevelList(options?: { [key: string]: any }) {
  return request<API.SysSchoolLevelVo[]>('/telbook/level_list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 数据导入（一次性） POST /telbook/put-data */
export async function postTelbookPutData(body: {}, file?: File, options?: { [key: string]: any }) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<Record<string, any>>('/telbook/put-data', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 设置负责人 POST /telbook/set_leader */
export async function postTelbookSetLeader(
  body: API.SchoolSetLeaderRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/telbook/set_leader', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 系统内置标准年级 GET /telbook/standard_grade/${param0} */
export async function getTelbookStandardGradeCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getTelbookStandardGradeCodeParams,
  options?: { [key: string]: any },
) {
  const { code: param0, ...queryParams } = params;
  return request<API.StandardGrade[]>(`/telbook/standard_grade/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}
