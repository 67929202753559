// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 文件下载 GET /file/download */
export async function getFileDownload(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFileDownloadParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/file/download', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取文件详情 GET /file/get */
export async function getFileGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFileGetParams,
  options?: { [key: string]: any },
) {
  return request<API.FileVo>('/file/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 上传大文件 POST /file/getFileId */
export async function postFileGetFileId(body: API.UpdateFileDto, options?: { [key: string]: any }) {
  return request<API.File[]>('/file/getFileId', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /file/list */
export async function getFileList(options?: { [key: string]: any }) {
  return request<API.SPageC2ABFileC2BB>('/file/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取sts token信息 POST /file/obtainStsTokenInfo */
export async function postFileObtainStsTokenInfo(options?: { [key: string]: any }) {
  return request<API.ObtainStsTokenInfoVo>('/file/obtainStsTokenInfo', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 上传文件 POST /file/upload */
export async function postFileUpload(body: {}, file?: File, options?: { [key: string]: any }) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<API.UploadFileResultVo>('/file/upload', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 上传图片 POST /file/upload-image */
export async function postFileUploadImage(body: {}, file?: File, options?: { [key: string]: any }) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<API.UploadFileResultVo>('/file/upload-image', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}
