// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 清空组织 POST /net_meeting/user/clear_org */
export async function postNetMeetingUserClearOrg(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/net_meeting/user/clear_org', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 清空用户 POST /net_meeting/user/clear_user */
export async function postNetMeetingUserClearUser(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/net_meeting/user/clear_user', {
    method: 'POST',
    ...(options || {}),
  });
}
