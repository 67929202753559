import { attachPropertiesToComponent } from '@hudiemon/utils';
import { DataNode } from 'antd/lib/tree';
import { Dispatch, SetStateAction } from 'react';
import SelectMember from './SelectMember';
import Selector from './Selector';

export interface TreeDataType extends Omit<DataNode, 'id' | 'key' | 'title'> {
  key: string;
  id?: number;
  type?: number;
  title?: string;
  parentId?: number;
  avatarUrl?: string;
  isMember?: boolean;
  orgName?: string;
  tagKey?: string;
  value?: TreeDataType[];
  children?: TreeDataType[];
}

export type SetCheckedNodes = Dispatch<SetStateAction<TreeDataType[]>>;

export default attachPropertiesToComponent(SelectMember, { Selector });
