// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加学生 POST /admin/school/student/add_student */
export async function postAdminSchoolStudentAddStudent(
  body: API.AdminSchoolStudentAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolStudent>('/admin/school/student/add_student', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 学生班级变更 POST /admin/school/student/class_change */
export async function postAdminSchoolStudentClassChange(
  body: API.AdminStudentClassChangeRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/admin/school/student/class_change', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 学生删除 POST /admin/school/student/delete */
export async function postAdminSchoolStudentOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/admin/school/student/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改信息（基础管理） POST /admin/school/student/edit_base */
export async function postAdminSchoolStudentEditBase(
  body: API.AdminSchoolStudentBaseEditRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/admin/school/student/edit_base', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改信息（家校） POST /admin/school/student/edit/${param0} */
export async function postAdminSchoolStudentEditStudentId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postAdminSchoolStudentEditStudentIdParams,
  body: API.AdminSchoolStudentEditRequest,
  options?: { [key: string]: any },
) {
  const { student_id: param0, ...queryParams } = params;
  return request<Record<string, any>>(`/admin/school/student/edit/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /admin/school/student/get/${param0} */
export async function getAdminSchoolStudentGetStudentId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminSchoolStudentGetStudentIdParams,
  options?: { [key: string]: any },
) {
  const { studentId: param0, ...queryParams } = params;
  return request<API.AdminSchoolStudentVo>(`/admin/school/student/get/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 导入 POST /admin/school/student/import */
export async function postAdminSchoolStudentOpenApiImport(
  body: {},
  file?: File,
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<string>('/admin/school/student/import', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 模板下载 GET /admin/school/student/import_template */
export async function getAdminSchoolStudentImportTemplate(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/admin/school/student/import_template', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 列表 GET /admin/school/student/list */
export async function getAdminSchoolStudentList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminSchoolStudentListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABAdminSchoolStudentVoC2BB>('/admin/school/student/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
