// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 新增区域映射 (废弃) POST /hk_region/add */
export async function postHkRegionAdd(body: API.HkRegionAddDto, options?: { [key: string]: any }) {
  return request<API.HkRegionVo>('/hk_region/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除区域映射 POST /hk_region/delete */
export async function postHkRegionOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/hk_region/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取监控点区域树 POST /hk_region/get_camera_region */
export async function postHkRegionGetCameraRegion(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postHkRegionGetCameraRegionParams,
  options?: { [key: string]: any },
) {
  return request<API.DepartmentVo[]>('/hk_region/get_camera_region', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 区域映射列表 GET /hk_region/list */
export async function getHkRegionList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkRegionListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHkRegionVoC2BB>('/hk_region/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 区域映射推送失败重试 POST /hk_region/push_retry */
export async function postHkRegionPushRetry(options?: { [key: string]: any }) {
  return request<string>('/hk_region/push_retry', {
    method: 'POST',
    ...(options || {}),
  });
}

/** 同步、推送区域映射 POST /hk_region/sync */
export async function postHkRegionSync(options?: { [key: string]: any }) {
  return request<string>('/hk_region/sync', {
    method: 'POST',
    ...(options || {}),
  });
}
