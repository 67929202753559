// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 详情 GET /worker/get */
export async function getWorkerGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getWorkerGetParams,
  options?: { [key: string]: any },
) {
  return request<API.WorkerVo>('/worker/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 人员列表 GET /worker/list */
export async function getWorkerList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getWorkerListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABWorkerVoC2BB>('/worker/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
