import Api from '@/services/api';
import { useRequest } from 'ahooks';
import { Empty, Skeleton, Tree } from 'antd';
import { differenceBy, isEmpty, map, unionBy } from 'lodash-es';
import { SetCheckedNodes, TreeDataType } from './index';
import { titleRender } from './utils';

type SearchResultProps = {
  keyword: string;
  checkedNodes: TreeDataType[];
  setCheckedNodes: SetCheckedNodes;
};
const MemberSearch = (props: SearchResultProps) => {
  const { keyword, setCheckedNodes, checkedNodes } = props;
  const { data = [], loading } = useRequest(
    async () => {
      const { data } = await Api.bumenchengyuanguanli.getDepartmentMemberList({
        current: '1',
        pageSize: '100',
        nickname: keyword,
      } as any);
      return data.reduce(
        (
          previousValue: TreeDataType[],
          currentValue: {
            id: number;
            nickname: string;
            org_name: string;
            avatar_url?: string;
            departments: { department_id: number }[];
          },
        ) => {
          const isLeaf = true;
          const isMember = true;
          const checkable = true;
          const id = currentValue.id;
          const key = `${currentValue.id}`;
          const title = currentValue.nickname;
          const orgName = currentValue.org_name;
          const avatarUrl = currentValue.avatar_url || undefined;
          const children = currentValue.departments.map(
            (item: { department_id: number }) => {
              const parentId = item.department_id;
              return {
                id,
                title,
                isLeaf,
                orgName,
                parentId,
                isMember,
                checkable,
                avatarUrl,
                key: `${parentId}-${currentValue.id}`,
              };
            },
          );
          previousValue.push({
            id,
            key,
            title,
            isLeaf,
            orgName,
            isMember,
            children,
            checkable,
            avatarUrl,
          });
          return previousValue;
        },
        [],
      );
    },
    {
      ready: !!keyword,
      refreshDeps: [keyword],
    },
  );
  return (
    <Skeleton
      active
      loading={loading}
      className={'p-[15px]'}
      paragraph={{ rows: 7 }}
    >
      {isEmpty(data) ? (
        <div className={'flex flex-1 justify-center items-center'}>
          <Empty />
        </div>
      ) : (
        <div className={'flex-auto overflow-y-auto'}>
          <Tree<TreeDataType>
            showIcon
            blockNode
            checkable
            treeData={data}
            selectable={false}
            checkedKeys={map(checkedNodes, 'key')}
            titleRender={(node) => titleRender(node, { showOrgName: true })}
            onCheck={(_, info) => {
              if (!info.node.checked) {
                setCheckedNodes((checkedNodes) =>
                  unionBy(checkedNodes, info.node.children, 'key'),
                );
                return;
              }
              setCheckedNodes((checkedNodes) =>
                differenceBy(checkedNodes, info.node.children!, 'id'),
              );
            }}
          />
        </div>
      )}
    </Skeleton>
  );
};

export default MemberSearch;
