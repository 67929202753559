// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 修改 POST /official_document_process/${param0} */
export async function postOfficialDocumentProcessId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postOfficialDocumentProcessIdParams,
  body: API.OfficialDocumentProcessRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.OfficialDocumentProcess>(`/official_document_process/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 添加 POST /official_document_process/add */
export async function postOfficialDocumentProcessAdd(
  body: API.OfficialDocumentProcessRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentProcess>('/official_document_process/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /official_document_process/delete */
export async function postOfficialDocumentProcessOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/official_document_process/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 导出 POST /official_document_process/export */
export async function postOfficialDocumentProcessOpenApiExport(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document_process/export', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /official_document_process/list */
export async function getOfficialDocumentProcessList(options?: { [key: string]: any }) {
  return request<API.OfficialDocumentProcess[]>('/official_document_process/list', {
    method: 'GET',
    ...(options || {}),
  });
}
