// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 详情 GET /admin/official_document/get */
export async function getAdminOfficialDocumentGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminOfficialDocumentGetParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentVo>('/admin/official_document/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 列表 GET /admin/official_document/list */
export async function getAdminOfficialDocumentList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminOfficialDocumentListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABOfficialDocumentReleaseVoC2BB>('/admin/official_document/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 签收信息列表 GET /admin/official_document/org/list */
export async function getAdminOfficialDocumentOrgList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminOfficialDocumentOrgListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABOfficialDocumentListOrgVoC2BB>('/admin/official_document/org/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 公文统计 GET /data_screen/official_document/counter */
export async function getDataScreenOfficialDocumentCounter(options?: { [key: string]: any }) {
  return request<API.OfficialDocumentCounterVo>('/data_screen/official_document/counter', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 签收排名 GET /data_screen/official_document/rank */
export async function getDataScreenOfficialDocumentRank(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDataScreenOfficialDocumentRankParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentRankVo[]>('/data_screen/official_document/rank', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 添加 POST /official_document/add */
export async function postOfficialDocumentAdd(
  body: API.OfficialDocumentAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocument>('/official_document/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 撤销 POST /official_document/cancel */
export async function postOfficialDocumentCancel(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/cancel', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 核稿完成 POST /official_document/check */
export async function postOfficialDocumentCheck(
  body: API.OfficialDocumentCheckRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/check', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 审批 POST /official_document/claim */
export async function postOfficialDocumentClaim(
  body: API.OfficialDocumentAuditRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/claim', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 盖章或者提交审批 POST /official_document/confirm */
export async function postOfficialDocumentConfirm(
  body: API.OfficialDocumentSealOrAuditRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentSubmitVo>('/official_document/confirm', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 指定公文签收统计数 GET /official_document/counter */
export async function getOfficialDocumentCounter(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentCounterParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentSignCounterVo>('/official_document/counter', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除 POST /official_document/delete */
export async function postOfficialDocumentOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/official_document/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除草稿 POST /official_document/delete/draft */
export async function postOfficialDocumentOpenApiDeleteDraft(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/delete/draft', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 草稿列表 GET /official_document/draft */
export async function getOfficialDocumentDraft(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentDraftParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABOfficialDocumentListVoC2BB>('/official_document/draft', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 修改 POST /official_document/edit */
export async function postOfficialDocumentEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postOfficialDocumentEditParams,
  body: API.OfficialDocumentAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocument>('/official_document/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 拟稿完成 POST /official_document/edit_complete */
export async function postOfficialDocumentEditComplete(
  body: API.OfficialDocumentStartRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/edit_complete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 ?????请使用新的

「已废弃」请使用新的 GET /official_document/get */
export async function getOfficialDocumentGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentGetParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentVo>('/official_document/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 触达率 GET /official_document/getCompletionRate */
export async function getOfficialDocumentGetCompletionRate(options?: { [key: string]: any }) {
  return request<API.MapC2ABObjectC2BB>('/official_document/getCompletionRate', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 公文列表 导出module=official_document.all GET /official_document/list */
export async function getOfficialDocumentList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABOfficialDocumentReleaseVoC2BB>('/official_document/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 我的公文 GET /official_document/my */
export async function getOfficialDocumentMy(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentMyParams,
  body: API.OfficialDocumentRequest,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABOfficialDocumentListVoC2BB>('/official_document/my', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 公文短信消费开启、关闭 POST /official_document/operation_consumer */
export async function postOfficialDocumentOperationConsumer(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postOfficialDocumentOperationConsumerParams,
  options?: { [key: string]: any },
) {
  return request<boolean>('/official_document/operation_consumer', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 提醒签收 POST /official_document/overdue_reminder */
export async function postOfficialDocumentOverdueReminder(
  body: API.OfficialDocumentOverdueReminderRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/overdue_reminder', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 触达数据 GET /official_document/percent */
export async function getOfficialDocumentPercent(options?: { [key: string]: any }) {
  return request<API.OfficialDocumentConfirmtVo>('/official_document/percent', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 发文 POST /official_document/publish */
export async function postOfficialDocumentPublish(
  body: API.OfficialDocumentPublishRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentAddResultVo>('/official_document/publish', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 已发公文 导出module=official_document.release GET /official_document/publish_list */
export async function getOfficialDocumentPublishList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentPublishListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABOfficialDocumentReleaseVoC2BB>('/official_document/publish_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 回文 POST /official_document/reply */
export async function postOfficialDocumentReply(
  body: API.OfficialDocumentReplyRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/reply', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改盖章时间 POST /official_document/set_confirm_time */
export async function postOfficialDocumentSetConfirmTime(
  body: API.OfficialDocumentSetConfirmTimeRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/set_confirm_time', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 签收 POST /official_document/sign */
export async function postOfficialDocumentSign(
  body: API.OfficialDocumentSignRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/sign', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 签收信息 GET /official_document/signlist */
export async function getOfficialDocumentSignlist(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentSignlistParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABOfficialDocumentListOrgVoC2BB>('/official_document/signlist', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 转发 POST /official_document/transmit */
export async function postOfficialDocumentTransmit(
  body: API.OfficialDocumentTransmitRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/transmit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情[新] GET /official_document/v2/get */
export async function getOfficialDocumentV2Get(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentV2GetParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentVo>('/official_document/v2/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 待办列表 导出module=official_document.wait_handle GET /official_document/wait_handle */
export async function getOfficialDocumentWaitHandle(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentWaitHandleParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABOfficialDocumentReleaseVoC2BB>('/official_document/wait_handle', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
