// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 详情 GET /admin/system/data_dict/${param0} */
export async function getAdminSystemDataDictId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminSystemDataDictIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.DataDict>(`/admin/system/data_dict/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 修改 POST /admin/system/data_dict/${param0} */
export async function postAdminSystemDataDictId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postAdminSystemDataDictIdParams,
  body: API.AdminDataDictRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.DataDict>(`/admin/system/data_dict/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 添加 POST /admin/system/data_dict/add */
export async function postAdminSystemDataDictAdd(
  body: API.AdminDataDictRequest,
  options?: { [key: string]: any },
) {
  return request<API.DataDict>('/admin/system/data_dict/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /admin/system/data_dict/delete */
export async function postAdminSystemDataDictOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/admin/system/data_dict/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 导出 POST /admin/system/data_dict/export */
export async function postAdminSystemDataDictOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postAdminSystemDataDict_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/admin/system/data_dict/export', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 导入 POST /admin/system/data_dict/import */
export async function postAdminSystemDataDictOpenApiImport(
  body: {},
  file?: File,
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<string>('/admin/system/data_dict/import', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 列表 GET /admin/system/data_dict/list */
export async function getAdminSystemDataDictList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminSystemDataDictListParams,
  options?: { [key: string]: any },
) {
  return request<API.DataDict[]>('/admin/system/data_dict/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 指定type列表 GET /admin/system/data_dict/list_type */
export async function getAdminSystemDataDictListType(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminSystemDataDictListTypeParams,
  options?: { [key: string]: any },
) {
  return request<API.DataDict[]>('/admin/system/data_dict/list_type', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 详情 GET /system/data_dict/${param0} */
export async function getSystemDataDictId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemDataDictIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.DataDict>(`/system/data_dict/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 修改 POST /system/data_dict/${param0} */
export async function postSystemDataDictId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postSystemDataDictIdParams,
  body: API.DataDictRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.DataDict>(`/system/data_dict/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 添加 POST /system/data_dict/add */
export async function postSystemDataDictAdd(
  body: API.DataDictRequest,
  options?: { [key: string]: any },
) {
  return request<API.DataDict>('/system/data_dict/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /system/data_dict/delete */
export async function postSystemDataDictOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/system/data_dict/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /system/data_dict/list */
export async function getSystemDataDictList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemDataDictListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABDataDictC2BB>('/system/data_dict/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 指定type列表 GET /system/data_dict/list_type */
export async function getSystemDataDictListType(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemDataDictListTypeParams,
  options?: { [key: string]: any },
) {
  return request<API.DataDict[]>('/system/data_dict/list_type', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
