// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 列表 GET /admin/official_document/object/list */
export async function getAdminOfficialDocumentObjectList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminOfficialDocumentObjectListParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentObjectPageVo>('/admin/official_document/object/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 添加 POST /official_document/object/add */
export async function postOfficialDocumentObjectAdd(
  body: API.OfficialDocumentObjectRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentObject>('/official_document/object/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /official_document/object/delete */
export async function postOfficialDocumentObjectOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/official_document/object/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /official_document/object/edit */
export async function postOfficialDocumentObjectEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postOfficialDocumentObjectEditParams,
  body: API.OfficialDocumentObjectRequest,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentObject>('/official_document/object/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 公文对象组导出 GET /official_document/object/export */
export async function getOfficialDocumentObjectOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentObject_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/official_document/object/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取公文对象组可导出列 GET /official_document/object/export/columns */
export async function getOfficialDocumentObjectOpenApiExportColumns(options?: {
  [key: string]: any;
}) {
  return request<API.Vo[]>('/official_document/object/export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取小组、组织有上下关系的列表 GET /official_document/object/flat_list */
export async function getOfficialDocumentObjectFlatList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentObjectFlatListParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentObjectFlatVo[]>('/official_document/object/flat_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 详情 GET /official_document/object/get */
export async function getOfficialDocumentObjectGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentObjectGetParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentObjectVo>('/official_document/object/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 导入 POST /official_document/object/import */
export async function postOfficialDocumentObjectOpenApiImport(
  body: {},
  file?: File,
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<Record<string, any>>('/official_document/object/import', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 下载导入模板 GET /official_document/object/import_template */
export async function getOfficialDocumentObjectImportTemplate(options?: { [key: string]: any }) {
  return request<number[]>('/official_document/object/import_template', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 列表 GET /official_document/object/list */
export async function getOfficialDocumentObjectList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentObjectListParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentObjectPageVo>('/official_document/object/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据ids查询列表 GET /official_document/object/list_ids */
export async function getOfficialDocumentObjectListIds(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentObjectListIdsParams,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentObjectVo[]>('/official_document/object/list_ids', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取禁止转发的对象组id GET /official_document/object/transmit/disable_ids */
export async function getOfficialDocumentObjectTransmitDisableIds(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getOfficialDocumentObjectTransmitDisableIdsParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/official_document/object/transmit/disable_ids', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
