// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加目录 POST /admin/handbook/add_catalog */
export async function postAdminHandbookAddCatalog(
  body: API.AdminHandbookRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/admin/handbook/add_catalog', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除目录 POST /admin/handbook/delete_catalog */
export async function postAdminHandbookDeleteCatalog(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postAdminHandbookDeleteCatalogParams,
  options?: { [key: string]: any },
) {
  return request<number>('/admin/handbook/delete_catalog', {
    method: 'POST',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取目录 GET /admin/handbook/get_catalog */
export async function getAdminHandbookGetCatalog(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminHandbookGetCatalogParams,
  options?: { [key: string]: any },
) {
  return request<API.AdminHandbookCatalogVo[]>('/admin/handbook/get_catalog', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取内容 GET /admin/handbook/get_content */
export async function getAdminHandbookGetContent(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminHandbookGetContentParams,
  options?: { [key: string]: any },
) {
  return request<string>('/admin/handbook/get_content', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 编辑目录 POST /admin/handbook/update_catalog */
export async function postAdminHandbookUpdateCatalog(
  body: API.AdminHandbookUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/admin/handbook/update_catalog', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 添加/更新内容 POST /admin/handbook/update_content */
export async function postAdminHandbookUpdateContent(
  body: API.AdminHandbookContentRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/admin/handbook/update_content', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取目录 GET /handbook/get_catalog */
export async function getHandbookGetCatalog(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHandbookGetCatalogParams,
  options?: { [key: string]: any },
) {
  return request<API.HandbookCatalogVo[]>('/handbook/get_catalog', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取内容 GET /handbook/get_content */
export async function getHandbookGetContent(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHandbookGetContentParams,
  options?: { [key: string]: any },
) {
  return request<string>('/handbook/get_content', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
