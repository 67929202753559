import {
  legacyLogicalPropertiesTransformer,
  StyleProvider,
} from '@ant-design/cssinjs';
import { ProConfigProvider } from '@ant-design/pro-components';
import { ConfigProvider as RlConfigProvider } from '@dyrl/web';
import { Provider } from '@umijs/max';
import { ConfigProvider as AntConfigProvider } from 'antd';
import React from 'react';

type ConfigProviderProps = {
  children: React.ReactNode;
};
const ConfigProvider = (props: ConfigProviderProps) => {
  return (
    <Provider models={{}}>
      <ProConfigProvider valueTypeMap={RlConfigProvider.valueTypeMap}>
        <AntConfigProvider
          theme={{
            cssVar: true,
            token: {
              fontFamily: `SourceHanSansCN, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Arial, Noto Sans, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", Segoe UI Symbol, "Noto Color Emoji"`,
            },
            components: {
              Table: {
                headerBg: 'rgb(245, 248, 251)',
                headerBorderRadius: 10,
              },
              Divider: {
                colorSplit: 'rgba(0, 0, 0, 0.15)',
                fontSize: 16,
                orientationMargin: 0,
              },
            },
          }}
        >
          <StyleProvider
            hashPriority={'high'}
            transformers={[legacyLogicalPropertiesTransformer]}
          >
            {props.children}
          </StyleProvider>
        </AntConfigProvider>
      </ProConfigProvider>
    </Provider>
  );
};

export default ConfigProvider;
