// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** authorizeLogin GET /login/authorize/login */
export async function getLoginAuthorizeLogin(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getLoginAuthorizeLoginParams,
  options?: { [key: string]: any },
) {
  return request<API.LoginResponse>('/login/authorize/login', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 公众号登陆 GET /login/userInfo */
export async function getLoginUserInfo(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getLoginUserInfoParams,
  options?: { [key: string]: any },
) {
  return request<API.LoginResponse>('/login/userInfo', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
