// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 获取基础数据 GET /admin/data_over/base_data */
export async function getAdminDataOverBaseData(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminDataOverBaseDataParams,
  options?: { [key: string]: any },
) {
  return request<API.AdminBaseDataOverviewVo>('/admin/data_over/base_data', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取活跃用户图表数据 GET /admin/data_over/chart_data/active_user */
export async function getAdminDataOverChartDataActiveUser(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminDataOverChartDataActiveUserParams,
  options?: { [key: string]: any },
) {
  return request<API.AdminChartDataOverviewVo[]>('/admin/data_over/chart_data/active_user', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取公文发文数图表数据 GET /admin/data_over/chart_data/official_push */
export async function getAdminDataOverChartDataOfficialPush(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminDataOverChartDataOfficialPushParams,
  options?: { [key: string]: any },
) {
  return request<API.AdminChartDataOverviewVo[]>('/admin/data_over/chart_data/official_push', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取公文收文数图表数据 GET /admin/data_over/chart_data/official_sign */
export async function getAdminDataOverChartDataOfficialSign(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminDataOverChartDataOfficialSignParams,
  options?: { [key: string]: any },
) {
  return request<API.AdminChartDataOverviewVo[]>('/admin/data_over/chart_data/official_sign', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 统计问卷数量 GET /form_report/count/coutList */
export async function getFormReportCountCoutList(options?: { [key: string]: any }) {
  return request<API.FormReportCountQuestVo[]>('/form_report/count/coutList', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 智能上报 GET /form_report/count/getSurveyStatistics */
export async function getFormReportCountGetSurveyStatistics(options?: { [key: string]: any }) {
  return request<API.FormReportSurveyStatisticsVo[]>('/form_report/count/getSurveyStatistics', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 获取问卷填报信息 GET /form_report/count/list */
export async function getFormReportCountList(options?: { [key: string]: any }) {
  return request<API.FormReportCountInfoVo[]>('/form_report/count/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 触达数据 GET /school_completion/getCompletionRate */
export async function getSchoolCompletionGetCompletionRate(options?: { [key: string]: any }) {
  return request<API.SchoolCompletionRateVo>('/school_completion/getCompletionRate', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 触达数据完成率 GET /school/dashboard/getSchoolCompletionRate */
export async function getSchoolDashboardGetSchoolCompletionRate(options?: { [key: string]: any }) {
  return request<API.SchoolCompletionRateVo>('/school/dashboard/getSchoolCompletionRate', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 数据概览 GET /school/dashboard/getSchoolStatistic */
export async function getSchoolDashboardGetSchoolStatistic(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolDashboardGetSchoolStatisticParams,
  body: {},
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<API.SchoolStatisticVo>('/school/dashboard/getSchoolStatistic', {
    method: 'GET',
    params: {
      ...params,
    },
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 师资情况 GET /school/dashboard/getSchoolTeacherAgeStatistic */
export async function getSchoolDashboardGetSchoolTeacherAgeStatistic(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolDashboardGetSchoolTeacherAgeStatisticParams,
  options?: { [key: string]: any },
) {
  return request<API.SchoolTeacherAgeStatisticVo>(
    '/school/dashboard/getSchoolTeacherAgeStatistic',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}

/** 教师职称 「已废弃」请用【教师职称人数统计】
「已废弃」 GET /school/dashboard/getSchoolTeacherPositionStatistic */
export async function getSchoolDashboardGetSchoolTeacherPositionStatistic(options?: {
  [key: string]: any;
}) {
  return request<API.MapC2ABLongC2BB>('/school/dashboard/getSchoolTeacherPositionStatistic', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 教师职称人数统计 GET /school/dashboard/teacher/count/by_edu_job */
export async function getSchoolDashboardTeacherCountByEduJob(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolDashboardTeacherCountByEduJobParams,
  options?: { [key: string]: any },
) {
  return request<API.SchoolTeacherPositionStatisticVo[]>(
    '/school/dashboard/teacher/count/by_edu_job',
    {
      method: 'GET',
      params: {
        ...params,
      },
      ...(options || {}),
    },
  );
}
