import { useDeepCompareEffect } from 'ahooks';
import { ConfigProvider, Input, Tabs } from 'antd';
import { compact } from 'lodash-es';
import { useState } from 'react';
import MemberList from './MemberList';
import MemberSearch from './MemberSearch';
import MemberTree from './MemberTree';
import TagTree from './TagTree';
import { TreeDataType } from './index';
import style from './index.less';

type SelectorProps = {
  orgType?: number;
  value?: TreeDataType[];
  onChange?: (checkedNodes: TreeDataType[]) => void;
};

const Selector = (props: SelectorProps) => {
  const [keyword, setKeyword] = useState<string>();
  const [checkedNodes, setCheckedNodes] = useState<TreeDataType[]>(
    props.value || [],
  );
  useDeepCompareEffect(() => {
    props.onChange?.(checkedNodes);
  }, [checkedNodes]);
  return (
    <ConfigProvider
      theme={{
        components: {
          Tree: {
            fontSize: 16,
            marginXXS: 11,
            titleHeight: 40,
            controlInteractiveSize: 18,
          },
        },
      }}
    >
      <div className={style.Selector}>
        <Input.Search
          allowClear
          placeholder={'搜索人员'}
          onSearch={(keyword) => setKeyword(keyword)}
        />
        <div className={'flex gap-x-[20px] h-[50vh]'}>
          <div
            className={
              'flex-1 border-[1px] border-solid border-[#d9d9d9] rounded-[10px] flex flex-col'
            }
          >
            {!keyword ? (
              <Tabs
                items={compact([
                  props.orgType === 0 && {
                    key: '0',
                    label: '教育局',
                    children: (
                      <MemberTree
                        type={'0'}
                        checkedNodes={checkedNodes}
                        setCheckedNodes={setCheckedNodes}
                      />
                    ),
                  },
                  {
                    key: '1',
                    label: '学校',
                    children: (
                      <MemberTree
                        type={'1'}
                        checkedNodes={checkedNodes}
                        setCheckedNodes={setCheckedNodes}
                      />
                    ),
                  },
                  {
                    key: '3',
                    label: '标签',
                    children: (
                      <TagTree
                        checkedNodes={checkedNodes}
                        setCheckedNodes={setCheckedNodes}
                      />
                    ),
                  },
                ])}
              />
            ) : (
              <MemberSearch
                keyword={keyword}
                checkedNodes={checkedNodes}
                setCheckedNodes={setCheckedNodes}
              />
            )}
          </div>
          <div
            className={
              'flex-1 border-[1px] border-solid border-[#d9d9d9] rounded-[10px] flex flex-col'
            }
          >
            <MemberList
              checkedNodes={checkedNodes}
              setCheckedNodes={setCheckedNodes}
            />
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default Selector;
