// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 新增会议安排 POST /meeting_plan/add */
export async function postMeetingPlanAdd(
  body: API.MeetingPlanAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.MeetingPlanVo>('/meeting_plan/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 可预约会议室 GET /meeting_plan/canPlan */
export async function getMeetingPlanCanPlan(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingPlanCanPlanParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABMeetingRoomVoC2BB>('/meeting_plan/canPlan', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除 POST /meeting_plan/deleteMyPlan */
export async function postMeetingPlanDeleteMyPlan(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/meeting_plan/deleteMyPlan', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /meeting_plan/edit/${param0} */
export async function postMeetingPlanEditId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postMeetingPlanEditIdParams,
  body: API.MeetingPlanAddRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.MeetingPlanVo>(`/meeting_plan/edit/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /meeting_plan/getPlan/${param0} */
export async function getMeetingPlanGetPlanId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingPlanGetPlanIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.MeetingPlanVo>(`/meeting_plan/getPlan/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 会议安排列表 GET /meeting_plan/list */
export async function getMeetingPlanList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingPlanListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABMeetingPlanVoC2BB>('/meeting_plan/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 会议记录/我的预定导出 GET /meeting_plan/meeting_export */
export async function getMeetingPlanMeetingExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingPlanMeetingExportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/meeting_plan/meeting_export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取会议记录/我的预定会议可导出列 GET /meeting_plan/meeting_export/columns */
export async function getMeetingPlanMeetingExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/meeting_plan/meeting_export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 会议签到、签退 POST /meeting_plan/meetingSign */
export async function postMeetingPlanMeetingSign(
  body: API.MeetingSignRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/meeting_plan/meetingSign', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 我的参会导出 GET /meeting_plan/my_join_export */
export async function getMeetingPlanMyJoinExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingPlanMyJoinExportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/meeting_plan/my_join_export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取我的参会可导出列 GET /meeting_plan/my_join_export/columns */
export async function getMeetingPlanMyJoinExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/meeting_plan/my_join_export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 我的参会 GET /meeting_plan/myJoin */
export async function getMeetingPlanMyJoin(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingPlanMyJoinParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABMeetingPlanVoC2BB>('/meeting_plan/myJoin', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 我的预定 GET /meeting_plan/myMeetingPlan */
export async function getMeetingPlanMyMeetingPlan(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingPlanMyMeetingPlanParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABMeetingPlanVoC2BB>('/meeting_plan/myMeetingPlan', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 会议签到记录导出 GET /meeting_plan/plan_sign_export/${param0} */
export async function getMeetingPlanPlanSignExportPlanId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingPlanPlanSignExportPlanIdParams,
  options?: { [key: string]: any },
) {
  const { planId: param0, ...queryParams } = params;
  return request<number[]>(`/meeting_plan/plan_sign_export/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}

/** 获取会议签到记录可导出列 GET /meeting_plan/plan_sign_export/columns */
export async function getMeetingPlanPlanSignExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/meeting_plan/plan_sign_export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 会议签到记录查询 GET /meeting_plan/planSignList/${param0} */
export async function getMeetingPlanPlanSignListPlanId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingPlanPlanSignListPlanIdParams,
  options?: { [key: string]: any },
) {
  const { planId: param0, ...queryParams } = params;
  return request<API.SPageC2ABMeetingPlanSignVoC2BB>(`/meeting_plan/planSignList/${param0}`, {
    method: 'GET',
    params: {
      ...queryParams,
    },
    ...(options || {}),
  });
}
