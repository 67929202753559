// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 家长绑定学生 POST /school/student/binding_student */
export async function postSchoolStudentBindingStudent(
  body: API.SchoolStudentBindingRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/student/binding_student', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 学生班级变更 POST /school/student/class_change */
export async function postSchoolStudentClassChange(
  body: API.StudentClassChangeRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/student/class_change', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 学生删除 POST /school/student/delete */
export async function postSchoolStudentOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/student/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改信息（基础管理） POST /school/student/edit_base */
export async function postSchoolStudentEditBase(
  body: API.SchoolStudentBaseEditRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/school/student/edit_base', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改信息（家校） POST /school/student/edit/${param0} */
export async function postSchoolStudentEditStudentId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postSchoolStudentEditStudentIdParams,
  body: API.SchoolStudentEditRequest,
  options?: { [key: string]: any },
) {
  const { student_id: param0, ...queryParams } = params;
  return request<Record<string, any>>(`/school/student/edit/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /school/student/get */
export async function getSchoolStudentGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolStudentGetParams,
  options?: { [key: string]: any },
) {
  return request<API.SchoolStudentVo>('/school/student/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 根据识别码查询学生信息 GET /school/student/get_by_code/${param0} */
export async function getSchoolStudentGetByCodeCode(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolStudentGetByCodeCodeParams,
  options?: { [key: string]: any },
) {
  const { code: param0, ...queryParams } = params;
  return request<API.SchoolStudentClassInfoVo>(`/school/student/get_by_code/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 列表 GET /school/student/list */
export async function getSchoolStudentList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolStudentListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolStudentVoC2BB>('/school/student/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 家长解绑学生 POST /school/student/unbinding_student */
export async function postSchoolStudentUnbindingStudent(
  body: API.SchoolStudentUnBindingRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/school/student/unbinding_student', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
