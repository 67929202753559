import { ButtonProps as InternalButtonProps } from '@dyrl/web';
import Button from './Button';
import createTask from './createTask';

export type RequestType = (() => Promise<any>) | any;

export type TaskButtonProps = InternalButtonProps & {
  request: RequestType;
};

export default { Button, createTask };
