import { produce } from 'immer';
import { isEmpty } from 'lodash-es';
import { useMemo } from 'react';
import { TreeDataType } from '../index';
import { memberDeep, toTreeData } from '../utils';

type Store = {
  loadedKeys: string[];
  data?: TreeDataType[];
};

export const useTreeData = ({ data, loadedKeys }: Store) => {
  return useMemo(() => {
    const treeData = toTreeData(data);
    return produce(treeData, (draft) => {
      const loop = (data?: TreeDataType[]) => {
        data?.forEach((item) => {
          if (
            !item.isMember &&
            loadedKeys.includes(item.key) &&
            isEmpty(memberDeep(item.children || [], 'key'))
          ) {
            item.disabled = true;
            item.disableCheckbox = false;
          }
          if (!isEmpty(item.children)) loop(item.children);
        });
      };
      loop(draft);
    });
  }, [data]);
};
