// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 新增 POST /app_update/add */
export async function postAppUpdateAdd(
  body: API.AppUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/app_update/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /app_update/delete */
export async function postAppUpdateOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/app_update/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /app_update/edit */
export async function postAppUpdateEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postAppUpdateEditParams,
  body: API.AppUpdateRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/app_update/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取最新版本信息 GET /app_update/getNew */
export async function getAppUpdateGetNew(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAppUpdateGetNewParams,
  options?: { [key: string]: any },
) {
  return request<API.AppUpdate>('/app_update/getNew', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 列表 GET /app_update/list */
export async function getAppUpdateList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAppUpdateListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABAppUpdateC2BB>('/app_update/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
