// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 详情 GET /school/grade/get */
export async function getSchoolGradeGet(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolGradeGetParams,
  options?: { [key: string]: any },
) {
  return request<API.SchoolGrade>('/school/grade/get', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 列表 GET /school/grade/list */
export async function getSchoolGradeList(options?: { [key: string]: any }) {
  return request<API.SPageC2ABSchoolGradeC2BB>('/school/grade/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 标准年级列表 GET /school/standard_grade/list */
export async function getSchoolStandardGradeList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolStandardGradeListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABStandardGradeC2BB>('/school/standard_grade/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
