// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加 POST /admin/system/policy/add */
export async function postAdminSystemPolicyAdd(
  body: API.AdminSystemPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<API.SystemPolicy>('/admin/system/policy/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /admin/system/policy/delete */
export async function postAdminSystemPolicyOpenApiDelete(
  body: API.StrIdsRequest,
  options?: { [key: string]: any },
) {
  return request<string[]>('/admin/system/policy/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /admin/system/policy/edit */
export async function postAdminSystemPolicyEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postAdminSystemPolicyEditParams,
  body: API.AdminSystemPolicyRequest,
  options?: { [key: string]: any },
) {
  return request<API.SystemPolicy>('/admin/system/policy/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /admin/system/policy/list */
export async function getAdminSystemPolicyList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAdminSystemPolicyListParams,
  options?: { [key: string]: any },
) {
  return request<API.SystemPolicy[]>('/admin/system/policy/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 批量导入 POST /admin/system/policy/multi_import */
export async function postAdminSystemPolicyMultiImport(
  body: API.AdminSystemPolicyMultiImportRequest,
  options?: { [key: string]: any },
) {
  return request<API.SystemPolicy[]>('/admin/system/policy/multi_import', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /system_policy_org/list */
export async function getSystemPolicyOrgList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSystemPolicyOrgListParams,
  options?: { [key: string]: any },
) {
  return request<API.SystemPolicy[]>('/system_policy_org/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
