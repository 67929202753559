import Api from '@/services/api/';
import { groupBy, reduce } from 'lodash-es';
import { SetState } from 'zustand';

const toOptions = (value: any[]) => {
  return value
    ?.sort((a, b) => a.sort - b.sort)
    .map((item) => {
      return {
        label: item.name,
        value: item[`value_${item.value_type}`],
      };
    });
};
const toValueEnum = (value: any[]) => {
  return value
    ?.sort((a, b) => a.sort - b.sort)
    .reduce((result, item) => {
      const extra = JSON.parse(item.options);
      const value = item[`value_${item.value_type}`];
      result[value] = {
        ...extra,
        text: item.name,
      };
      return result;
    }, {});
};
const fetchDictionary = async (setState: SetState<any>) => {
  const response = await Api.shujuzidian.getAdminSystemDataDictList({
    type: '',
  });
  const groupData = groupBy(response, 'type');
  const dictionary = reduce(
    groupData,
    (result, value, key) => {
      result.set(key, {
        options: toOptions(value),
        valueEnum: toValueEnum(value),
      });
      return result;
    },
    new Map(),
  );
  setState({
    dictionary,
  });
  return dictionary;
};
export default fetchDictionary;
