import logo from '@/assets/logo.png';
import { ConfigProvider, Global, Redirect, User } from '@/components';
import { useInitialState } from '@/hooks';
import { Download } from '@/pages/Download/components';
import { Message } from '@/pages/Message/components';
import { RunTimeLayoutConfig, history } from '@umijs/max';
import { isEmpty } from 'lodash-es';
import React from 'react';

export { default as request } from './request';

export const layout: RunTimeLayoutConfig = () => {
  return {
    logo,
    menu: {
      locale: false,
      autoClose: false,
      theme: 'light',
    },
    siderWidth: 220,
    collapsed: false,
    collapsedButtonRender: false,
    disableMobile: true,
    title: process.env.TITLE,
    layout: 'mix',
    contentWidth: 'Fluid',
    fixedHeader: false,
    fixSiderbar: true,
    splitMenus: true,
    avatarProps: {
      render: () => <User />,
    },
    token: {
      bgLayout: '#f5f5f5',
      header: {
        heightLayoutHeader: 86,
      },
      sider: {},
    },
    onPageChange: () => {
      console.log('onPageChange');
    },
    actionsRender: () => [<Message key="msg" />, <Download key={'download'} />],
  };
};

export function rootContainer(container: React.ReactElement) {
  return (
    <Global>
      <ConfigProvider>{container}</ConfigProvider>
    </Global>
  );
}

export function getInitialState() {
  const { location } = history;
  if (location.pathname.includes('/meet')) return;
  // 如果不是登录页面，执行
  if (
    !['/login', '/error', '/videoconference/environment'].includes(
      location.pathname,
    )
  ) {
    const { fetchInitialState } = useInitialState.getState();
    return fetchInitialState();
  }
}

type RouteType = {
  name?: string;
  path: string;
  access?: string;
  routes?: RouteType[];
  element: React.ReactNode;
};
export const patchClientRoutes = async ({
  routes,
}: {
  routes: RouteType[];
}) => {
  const { access, token } = useInitialState.getState();
  const handleRedirect = (node: any) => {
    if (isEmpty(node.routes)) return;
    const routesFilter = node.routes.filter((item: any) => access[item.access]);
    if (isEmpty(routesFilter)) return;
    node.routes.push({
      path: node.path,
      element: <Redirect to={routesFilter[0].path} />,
    });
    node.routes.forEach((item: any) => {
      handleRedirect(item);
    });
  };
  if (!!token) {
    routes.forEach((item: any) => {
      handleRedirect(item);
    });
  }
};
export const render = async (oldRender: () => void) => {
  const { fetchInitialState, token } = useInitialState.getState();
  if (!!token) {
    await fetchInitialState();
  }
  oldRender();
};
