// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 新增 POST /meeting_room/add */
export async function postMeetingRoomAdd(
  body: API.MeetingRoomAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.MeetingRoomVo>('/meeting_room/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 楼宇（房间会议室）查询 GET /meeting_room/build_room_list */
export async function getMeetingRoomBuildRoomList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingRoomBuildRoomListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolBuildingVoC2BB>('/meeting_room/build_room_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除 POST /meeting_room/delete */
export async function postMeetingRoomOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/meeting_room/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /meeting_room/edit/${param0} */
export async function postMeetingRoomEditId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postMeetingRoomEditIdParams,
  body: API.MeetingRoomEditRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.MeetingRoomVo>(`/meeting_room/edit/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /meeting_room/get/${param0} */
export async function getMeetingRoomGetId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingRoomGetIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.MeetingRoomVo>(`/meeting_room/get/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 列表 GET /meeting_room/list */
export async function getMeetingRoomList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getMeetingRoomListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABMeetingRoomVoC2BB>('/meeting_room/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
