// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 创建 POST /third_app/create */
export async function postThirdAppCreate(
  body: API.ThirdAppCreateRequest,
  options?: { [key: string]: any },
) {
  return request<API.ThirdApp>('/third_app/create', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /third_app/delete */
export async function postThirdAppOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/third_app/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /third_app/edit */
export async function postThirdAppEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postThirdAppEditParams,
  body: API.ThirdAppCreateRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/third_app/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /third_app/list */
export async function getThirdAppList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getThirdAppListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABThirdAppVoC2BB>('/third_app/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 刷新secret POST /third_app/refresh_secret */
export async function postThirdAppRefreshSecret(
  body: API.LongIdRequest,
  options?: { [key: string]: any },
) {
  return request<API.ThirdAppRefreshSecretVo>('/third_app/refresh_secret', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
