// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加 POST /official_document/category/add */
export async function postOfficialDocumentCategoryAdd(
  body: API.OfficialDocumentCategoryCategory,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentCategory>('/official_document/category/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /official_document/category/delete */
export async function postOfficialDocumentCategoryOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/official_document/category/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /official_document/category/edit */
export async function postOfficialDocumentCategoryEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postOfficialDocumentCategoryEditParams,
  body: API.OfficialDocumentCategoryCategory,
  options?: { [key: string]: any },
) {
  return request<API.OfficialDocumentCategory>('/official_document/category/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 导出 POST /official_document/category/export */
export async function postOfficialDocumentCategoryOpenApiExport(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/official_document/category/export', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /official_document/category/list */
export async function getOfficialDocumentCategoryList(options?: { [key: string]: any }) {
  return request<API.OfficialDocumentCategory[]>('/official_document/category/list', {
    method: 'GET',
    ...(options || {}),
  });
}
