// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 新增 POST /fastMenu/add */
export async function postFastMenuAdd(
  body: API.FastMenuAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.FastMenu>('/fastMenu/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 添加自定义快捷应用 POST /fastMenu/addFastMenuUserList */
export async function postFastMenuAddFastMenuUserList(
  body: API.FastMenuUserAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.FastMenuUser>('/fastMenu/addFastMenuUserList', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /fastMenu/delete */
export async function postFastMenuOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/fastMenu/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /fastMenu/edit */
export async function postFastMenuEdit(
  body: API.FastMenuEditRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/fastMenu/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 快捷菜单列表 GET /fastMenu/fastMenuList */
export async function getFastMenuFastMenuList(options?: { [key: string]: any }) {
  return request<API.FastMenu[]>('/fastMenu/fastMenuList', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 用户快捷菜单列表 GET /fastMenu/fastMenuUserList */
export async function getFastMenuFastMenuUserList(options?: { [key: string]: any }) {
  return request<API.FastMenuUser[]>('/fastMenu/fastMenuUserList', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 详情 GET /fastMenu/get/${param0} */
export async function getFastMenuGetId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFastMenuGetIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.FastMenu>(`/fastMenu/get/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 应用列表分页 GET /fastMenu/list */
export async function getFastMenuList(options?: { [key: string]: any }) {
  return request<API.SPageC2ABFastMenuC2BB>('/fastMenu/list', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 移除自定义快捷应用 POST /fastMenu/moveFastMenuUserList */
export async function postFastMenuMoveFastMenuUserList(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/fastMenu/moveFastMenuUserList', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
