// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 新增 POST /agreement/add */
export async function postAgreementAdd(
  body: API.SystemAgreementAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.SystemAgreementDetailVo>('/agreement/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /agreement/delete */
export async function postAgreementOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/agreement/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 详情 GET /agreement/detail */
export async function getAgreementDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAgreementDetailParams,
  options?: { [key: string]: any },
) {
  return request<API.SystemAgreementDetailVo>('/agreement/detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 详情 GET /agreement/detail/${param0} */
export async function getAgreementDetailId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAgreementDetailIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.SystemAgreementDetailVo>(`/agreement/detail/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 修改 POST /agreement/edit */
export async function postAgreementEdit(
  body: API.SystemAgreementEditRequest,
  options?: { [key: string]: any },
) {
  return request<API.SystemAgreementDetailVo>('/agreement/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /agreement/list */
export async function getAgreementList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getAgreementListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSystemAgreementVoC2BB>('/agreement/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
