import Api from '@/services/api';
import { Avatar } from '@dyrl/web';
import { Access } from '@umijs/max';
import { Tag } from 'antd';
import { cloneDeep, first, unionBy } from 'lodash-es';
import { TreeDataType } from './index';

export function departmentDeep<T extends keyof TreeDataType>(
  treeNode: TreeDataType[],
  key: T,
): TreeDataType[T][];
export function departmentDeep(treeNode: TreeDataType[]): TreeDataType[];
export function departmentDeep<T extends keyof TreeDataType>(
  treeNode: TreeDataType[],
  key?: T,
): TreeDataType[T][] | TreeDataType[] {
  const loop = (data: TreeDataType[], keys = []) => {
    data?.forEach((item) => {
      if (!item.isMember) {
        // @ts-ignore
        keys.push(item[key] || item);
      }
      if (item.children) loop(item.children, keys);
    });
    return keys;
  };
  return loop(treeNode);
}

export function memberDeep<T extends keyof TreeDataType>(
  treeNode: TreeDataType[],
  key: T,
): TreeDataType[T][];
export function memberDeep(treeNode: TreeDataType[]): TreeDataType[];
export function memberDeep<T extends keyof TreeDataType>(
  treeNode: TreeDataType[],
  key?: T,
): TreeDataType[T][] | TreeDataType[] {
  const loop = (data: TreeDataType[], keys = []) => {
    data?.forEach((item) => {
      if (item.isMember) {
        // @ts-ignore
        keys.push(item[key] || item);
      }
      if (item.children) loop(item.children, keys);
    });
    return keys;
  };
  return loop(treeNode);
}

export const departmentLoaded = (
  treeNode?: TreeDataType,
  loadedKeys?: React.Key[],
) => {
  const departmentKeys = unionBy(
    [treeNode?.key],
    departmentDeep(treeNode?.children || [], 'key'),
  );
  return departmentKeys.every((item) => loadedKeys?.includes(item!));
};

export const requestMember = async (
  params: API.getDepartmentMemberListByDepartmentParams,
  departmentKeys?: React.Key[],
): Promise<any[]> => {
  const data =
    await Api.bumenchengyuanguanli.getDepartmentMemberListByDepartment(params);
  if (params.load_children === 1 && departmentKeys) {
    return data.reduce((result: any[], item) => {
      item.departments?.forEach((department) => {
        if (!departmentKeys?.includes(department.department_code!)) return;
        const parentId = department.department_id;
        result.push({
          parentId,
          isLeaf: true,
          id: item.id!,
          isMember: true,
          checkable: true,
          title: item.nickname,
          orgName: item.org_name,
          key: `${parentId}-${item.id}`,
          avatarUrl: item.avatar_url || undefined,
        });
      });
      return result;
    }, []);
  }

  return data.map((item) => {
    const parentId = params.department_id;
    return {
      parentId,
      isLeaf: true,
      id: item.id!,
      isMember: true,
      checkable: true,
      title: item.nickname,
      orgName: item.org_name,
      key: `${parentId}-${item.id}`,
      avatarUrl: item.avatar_url || undefined,
    };
  });
};

export const requestTagMember = async (params: any) => {
  const data =
    await Api.bumenchengyuanguanli.getDepartmentMemberListByDepartment(params);
  return data.reduce((previousValue: TreeDataType[], currentValue) => {
    const isLeaf = true;
    const isMember = true;
    const checkable = true;
    const id = currentValue.id;
    const title = currentValue.nickname;
    const orgName = currentValue.org_name;
    const avatarUrl = currentValue.avatar_url || undefined;
    const parentId: number | undefined = first(params.tag_ids);
    const key = `${parentId}-${currentValue.id}`;
    const value = currentValue.departments?.map((item) => {
      const parentId = item.department_id;
      return {
        id,
        title,
        isLeaf,
        orgName,
        parentId,
        isMember,
        checkable,
        avatarUrl,
        key: `${parentId}-${currentValue.id}`,
      };
    });

    previousValue.push({
      id,
      value,
      title,
      isLeaf,
      orgName,
      isMember,
      parentId,
      checkable,
      avatarUrl,
      key,
    });
    return previousValue;
  }, []);
};

export const titleRender = (
  node: TreeDataType,
  options?: { showOrgName: boolean },
) => {
  if (node.isMember === true) {
    return (
      <div className={'flex items-center gap-x-[5px]'}>
        <Avatar size={30} src={node.avatarUrl}>
          {node.title}
        </Avatar>
        {node.title}
        <Access accessible={options?.showOrgName || false}>
          <Tag>{node.orgName}</Tag>
        </Access>
      </div>
    );
  }
  return <span>{node.title}</span>;
};

export const toTreeData = (
  value?: TreeDataType[],
): TreeDataType[] | undefined => {
  if (!value) return;
  const data = cloneDeep(value);
  const dataMap = new Map(
    data.reduce((previousValue: [number, TreeDataType][], currentValue) => {
      if (!currentValue.isMember) {
        previousValue.push([currentValue.id!, currentValue]);
      }
      return previousValue;
    }, []),
  );
  const treeData: TreeDataType[] = [];
  const loop = (data: TreeDataType[]) => {
    data.forEach((item) => {
      const parentNode = dataMap.get(item.parentId!);
      if (!parentNode) {
        treeData.push(item);
        return;
      }
      if (!Array.isArray(parentNode.children)) {
        parentNode.children = [item];
        return;
      }

      parentNode.children?.push(item);
    });
  };
  loop(data);
  return treeData;
};
