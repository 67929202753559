import { Result } from 'ahooks/lib/useRequest/src/types';
import { TreeProps } from 'antd';
import { filter, isEmpty, map, toString, unionBy } from 'lodash-es';
import { Dispatch, SetStateAction } from 'react';
import { TreeDataType } from '../index';
import { departmentDeep, requestMember } from '../utils';

type Store = {
  type?: string;
  checkedNodes: TreeDataType[];
  setLoadedKeys: Dispatch<SetStateAction<string[]>>;
  setCheckedNodes: Dispatch<SetStateAction<TreeDataType[]>>;
  mutate: Result<TreeDataType[], any>['mutate'];
};

export const useLoadData = ({
  type,
  mutate,
  checkedNodes,
  setLoadedKeys,
  setCheckedNodes,
}: Store) => {
  const onLoadData: TreeProps<TreeDataType>['loadData'] = async (treeNode) => {
    // 加载当前节点下的人员
    const params: API.getDepartmentMemberListByDepartmentParams = {};
    if (type) {
      params.type = [type];
      params.load_children = 1;
      params.department_id = treeNode.id;
    } else {
      params.tag_ids = [toString(treeNode.id)];
    }
    const departmentKeys = departmentDeep([treeNode], 'key');
    const loadData = await requestMember(params, departmentKeys);
    mutate((oldData) => unionBy(oldData, loadData, 'key'));
    // 将当前部门节点设置为加载完成
    setLoadedKeys((loadedKeys) => unionBy(loadedKeys, [treeNode.key!]));
    const memberIds = map(checkedNodes, 'id');
    const checkedMembers = filter(loadData, (item) =>
      memberIds.includes(item.id),
    );
    if (!isEmpty(checkedMembers))
      setCheckedNodes((checkedNodes) =>
        unionBy(checkedNodes, checkedMembers, 'key'),
      );
  };
  return onLoadData;
};
