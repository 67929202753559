// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 未命名接口 POST / */
export async function post(
  body: {
    method: string;
    params: Record<string, any>;
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 访问授权 GET /auth/api/v1/basic/token */
export async function getAuthApiV1BasicToken(
  body: {
    method: string;
    params: Record<string, any>;
  },
  options?: { [key: string]: any },
) {
  return request<Record<string, any>>('/auth/api/v1/basic/token', {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}
