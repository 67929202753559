import Api from '@/services/api';
import { Avatar } from '@dyrl/web';
import { useRequest } from 'ahooks';
import { Checkbox, Empty, Skeleton, Tag } from 'antd';
import { isEmpty } from 'lodash-es';
import { CheckedNodesType, SetCheckedNodesType } from './Selector';

type SearchResultProps = {
  keyword: string;
  orgType?: ('0' | '1')[];
  checkedNodes: CheckedNodesType[];
  setCheckedNodes: SetCheckedNodesType;
};
const SearchResult = (props: SearchResultProps) => {
  const { keyword, setCheckedNodes, checkedNodes, orgType } = props;
  const { data, loading } = useRequest(
    () =>
      Api.bumenchengyuanguanli.getDepartmentMemberList({
        current: '1',
        pageSize: '100',
        nickname: keyword,
        type: orgType,
        my: window.orgType === 0 ? [1, 2] : undefined,
      } as any),
    {
      ready: !!keyword,
      refreshDeps: [keyword],
    },
  );
  const checked = ({ id }: { id: string }) => {
    return checkedNodes.some((item) => item.id === id);
  };
  if (loading) return <Skeleton paragraph={{ rows: 7 }} />;
  if (isEmpty(data?.data))
    return (
      <div className={'flex flex-1 flex-col justify-center'}>
        <Empty />
      </div>
    );
  return (
    <div className={'flex-1 overflow-y-auto'}>
      {data?.data.map((item: any) => {
        return (
          <div key={item.id} className={'flex py-[5px] px-[15px]'}>
            <Checkbox
              checked={checked(item)}
              onChange={(e) => {
                setCheckedNodes(
                  [
                    {
                      id: item.id,
                      label: item.name,
                      value: item.id,
                      _original: item,
                    },
                  ],
                  e.target.checked,
                );
              }}
            >
              <div className={'flex gap-x-[5px] items-center'}>
                <Avatar size={30} src={item.avatar_url}>
                  {item.nickname}
                </Avatar>
                {item.nickname}
                <div>
                  <Tag color={item.org_id === 1 ? 'magenta' : 'green'}>
                    {item.org_name}
                  </Tag>
                </div>
              </div>
            </Checkbox>
          </div>
        );
      })}
    </div>
  );
};

export default SearchResult;
