// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 新增督导室 POST /hk_supervision/add */
export async function postHkSupervisionAdd(
  body: API.HkSuperVisionRoomAddDto,
  options?: { [key: string]: any },
) {
  return request<API.HkSupervisionRoomVo>('/hk_supervision/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 根据分类id查询监控点 GET /hk_supervision/camera_list */
export async function getHkSupervisionCameraList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkSupervisionCameraListParams,
  options?: { [key: string]: any },
) {
  return request<API.HkCameraVo[]>('/hk_supervision/camera_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 分类列表（督导室） GET /hk_supervision/category_list */
export async function getHkSupervisionCategoryList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkSupervisionCategoryListParams,
  options?: { [key: string]: any },
) {
  return request<API.HkCameraCategory[]>('/hk_supervision/category_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 删除督导室 POST /hk_supervision/delete */
export async function postHkSupervisionOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<boolean>('/hk_supervision/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 督导室详情 GET /hk_supervision/detail/${param0} */
export async function getHkSupervisionDetailId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkSupervisionDetailIdParams,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.HkSupervisionRoomVo>(`/hk_supervision/detail/${param0}`, {
    method: 'GET',
    params: { ...queryParams },
    ...(options || {}),
  });
}

/** 编辑督导室 POST /hk_supervision/edit */
export async function postHkSupervisionEdit(
  body: API.HkSuperVisionRoomAddDto,
  options?: { [key: string]: any },
) {
  return request<API.HkSupervisionRoomVo>('/hk_supervision/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 督导室列表 GET /hk_supervision/list */
export async function getHkSupervisionList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkSupervisionListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHkSupervisionRoomVoC2BB>('/hk_supervision/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 管理-督导室列表 GET /hk_supervision/manage_list */
export async function getHkSupervisionManageList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getHkSupervisionManageListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABHkSupervisionRoomVoC2BB>('/hk_supervision/manage_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
