import { Button, Modal } from '@dyrl/web';
import Selector from './Selector';
import { TreeDataType } from './index';

type openProps = {
  title?: React.ReactNode;
  value?: TreeDataType[];
  // 0:教育局、1:学校
  orgType?: number;
  onOk?: (treeData: TreeDataType[]) => void;
};
const open = (props: openProps = {}) => {
  const {
    title: propsTitle = '选择人员',
    orgType: propsOrgType = window.orgType,
  } = props;
  const checkedNodesRef: { current: TreeDataType[] } = {
    current: [],
  };
  const handler = Modal.open({
    width: 950,
    title: propsTitle,
    children: (
      <>
        <Modal.Body>
          <Selector
            value={props.value}
            orgType={propsOrgType}
            onChange={(checkedNodes) => {
              checkedNodesRef.current = checkedNodes;
            }}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => handler.close()}>关闭</Button>
          <Button
            type={'primary'}
            onClick={() => {
              props.onOk?.(checkedNodesRef.current);
              handler.close();
            }}
          >
            确定
          </Button>
        </Modal.Footer>
      </>
    ),
  });
};
export default open;
