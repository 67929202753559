// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加 POST /school/semester/add */
export async function postSchoolSemesterAdd(
  body: API.SchoolSemesterAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolSemester>('/school/semester/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除 POST /school/semester/delete */
export async function postSchoolSemesterOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/school/semester/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 修改 POST /school/semester/edit */
export async function postSchoolSemesterEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postSchoolSemesterEditParams,
  body: API.SchoolSemesterAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolSemester>('/school/semester/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 列表 GET /school/semester/list */
export async function getSchoolSemesterList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolSemesterListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolSemesterC2BB>('/school/semester/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
