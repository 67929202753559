import { Result } from 'ahooks/lib/useRequest/src/types';
import { TreeProps } from 'antd';
import { filter, isEmpty, map, unionBy } from 'lodash-es';
import { Dispatch, SetStateAction } from 'react';
import { TreeDataType } from '../index';
import { requestTagMember } from '../utils';

type Store = {
  checkedNodes: TreeDataType[];
  setLoadedKeys: Dispatch<SetStateAction<string[]>>;
  setCheckedNodes: Dispatch<SetStateAction<TreeDataType[]>>;
  mutate: Result<TreeDataType[], any>['mutate'];
};

export const useLoadData = ({
  mutate,
  checkedNodes,
  setLoadedKeys,
  setCheckedNodes,
}: Store) => {
  const onLoadData: TreeProps<TreeDataType>['loadData'] = async (treeNode) => {
    const loadData = await requestTagMember({ tag_ids: [treeNode.id] });
    mutate((oldData) => unionBy(oldData, loadData, 'key'));
    // 将当前部门节点设置为加载完成
    setLoadedKeys((loadedKeys) => unionBy(loadedKeys, [treeNode.key!]));
    const memberIds = map(checkedNodes, 'id');
    const checkedMembers = filter(loadData, (item) =>
      memberIds.includes(item.id),
    );
    if (!isEmpty(checkedMembers))
      setCheckedNodes((checkedNodes) =>
        unionBy(checkedNodes, checkedMembers, 'key'),
      );
  };
  return onLoadData;
};
