// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加教师 POST /school/teacher/add */
export async function postSchoolTeacherAdd(
  body: API.SchoolTeacherAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.SchoolTeacher>('/school/teacher/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除教师信息 POST /school/teacher/delete */
export async function postSchoolTeacherOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/school/teacher/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 教师详情 GET /school/teacher/detail */
export async function getSchoolTeacherDetail(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolTeacherDetailParams,
  options?: { [key: string]: any },
) {
  return request<API.SchoolTeacherVo>('/school/teacher/detail', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 修改教师信息 POST /school/teacher/edit/${param0} */
export async function postSchoolTeacherEditId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postSchoolTeacherEditIdParams,
  body: API.SchoolTeacherAddRequest,
  options?: { [key: string]: any },
) {
  const { id: param0, ...queryParams } = params;
  return request<API.SchoolTeacher>(`/school/teacher/edit/${param0}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: { ...queryParams },
    data: body,
    ...(options || {}),
  });
}

/** 基础-教师列表导出 GET /school/teacher/export */
export async function getSchoolTeacherOpenApiExport(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolTeacher_openAPI_exportParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/school/teacher/export', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取可导出列 GET /school/teacher/export/columns */
export async function getSchoolTeacherOpenApiExportColumns(options?: { [key: string]: any }) {
  return request<API.Vo[]>('/school/teacher/export/columns', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 基础-教师导入 POST /school/teacher/import */
export async function postSchoolTeacherOpenApiImport(
  body: {},
  file?: File,
  options?: { [key: string]: any },
) {
  const formData = new FormData();

  if (file) {
    formData.append('file', file);
  }

  Object.keys(body).forEach((ele) => {
    const item = (body as any)[ele];

    if (item !== undefined && item !== null) {
      if (typeof item === 'object' && !(item instanceof File)) {
        if (item instanceof Array) {
          item.forEach((f) => formData.append(ele, f || ''));
        } else {
          formData.append(ele, JSON.stringify(item));
        }
      } else {
        formData.append(ele, item);
      }
    }
  });

  return request<Record<string, any>>('/school/teacher/import', {
    method: 'POST',
    data: formData,
    requestType: 'form',
    ...(options || {}),
  });
}

/** 基础-教师导入模板下载 GET /school/teacher/import_template */
export async function getSchoolTeacherImportTemplate(options?: { [key: string]: any }) {
  return request<Record<string, any>>('/school/teacher/import_template', {
    method: 'GET',
    ...(options || {}),
  });
}

/** 教师列表 GET /school/teacher/list */
export async function getSchoolTeacherList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getSchoolTeacherListParams,
  options?: { [key: string]: any },
) {
  return request<API.SPageC2ABSchoolTeacherVoC2BB>('/school/teacher/list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
