// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 获取问卷答案统计 GET /form_report/count/answer/select_count */
export async function getFormReportCountAnswerSelectCount(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getFormReportCountAnswerSelectCountParams,
  options?: { [key: string]: any },
) {
  return request<API.FormReportAnswerCountVo[]>('/form_report/count/answer/select_count', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 统计数据 GET /work_process/counter/data */
export async function getWorkProcessCounterData(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getWorkProcessCounterDataParams,
  options?: { [key: string]: any },
) {
  return request<API.WorkProcessCounterVo>('/work_process/counter/data', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
