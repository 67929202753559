// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 添加标签 POST /department_member/tag/add */
export async function postDepartmentMemberTagAdd(
  body: API.DepartmentMemberAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.DepartmentMemberTag>('/department_member/tag/add', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 删除标签 POST /department_member/tag/delete */
export async function postDepartmentMemberTagOpenApiDelete(
  body: API.LongIdsRequest,
  options?: { [key: string]: any },
) {
  return request<number>('/department_member/tag/delete', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    data: body,
    ...(options || {}),
  });
}

/** 编辑标签 POST /department_member/tag/edit */
export async function postDepartmentMemberTagEdit(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.postDepartmentMemberTagEditParams,
  body: API.DepartmentMemberAddRequest,
  options?: { [key: string]: any },
) {
  return request<API.DepartmentMemberTag>('/department_member/tag/edit', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    params: {
      ...params,
    },
    data: body,
    ...(options || {}),
  });
}

/** 获取标签的成员id GET /department_member/tag/member_ids/by_tag_id */
export async function getDepartmentMemberTagMemberIdsByTagId(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDepartmentMemberTagMemberIdsByTagIdParams,
  options?: { [key: string]: any },
) {
  return request<number[]>('/department_member/tag/member_ids/by_tag_id', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取标签列表 GET /department_member/tag/page_list */
export async function getDepartmentMemberTagPageList(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getDepartmentMemberTagPageListParams,
  options?: { [key: string]: any },
) {
  return request<API.DepartmentMemberTag[]>('/department_member/tag/page_list', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
