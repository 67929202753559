// @ts-ignore
/* eslint-disable */
import { request } from '@umijs/max';

/** 删除缓存 GET /cache/delete */
export async function getCacheOpenApiDelete(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getCache_openAPI_deleteParams,
  options?: { [key: string]: any },
) {
  return request<string>('/cache/delete', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取缓存key GET /cache/getKeys */
export async function getCacheGetKeys(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getCacheGetKeysParams,
  options?: { [key: string]: any },
) {
  return request<string[]>('/cache/getKeys', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}

/** 获取缓存 GET /cache/getValueByKey */
export async function getCacheGetValueByKey(
  // 叠加生成的Param类型 (非body参数swagger默认没有生成对象)
  params: API.getCacheGetValueByKeyParams,
  options?: { [key: string]: any },
) {
  return request<API.Object>('/cache/getValueByKey', {
    method: 'GET',
    params: {
      ...params,
    },
    ...(options || {}),
  });
}
